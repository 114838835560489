import { Nala } from '@cimpress-technology/data-portal-core/lib/clients';
import { NalaFilter } from '@cimpress-technology/data-portal-core/lib/clients/nala';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import React, { useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/authContext';
import './DataProductCostReport.scss';


export const DataProductCostReport: React.FC<{ dataProductId: string }> = ({ dataProductId }) => {

    return <div>
        <h3>Cost Report</h3>
        <small>The cost report associated with the Data Product.</small>
        <hr /><br />
        <DataProductCostReportDashboard dataProductId={dataProductId} />
    </div>;
};

const DataProductCostReportDashboard: React.FC<{ dataProductId: string }> = ({ dataProductId }) => {
    const { accessToken } = React.useContext(AuthContext);
    const [embedLink, setEmbedLink] = useState(undefined as string | undefined);
    
    useEffect(() => {
        setEmbedLink(undefined);
        const costReportConfigurationId = 'bf20d26d-d595-4630-9606-65569aaa2ef7';
        const filters: NalaFilter[] = [{
            filter: 'Data Product ID',
            filterValue: dataProductId
        }];
        Nala.generateEmbedLink(accessToken, costReportConfigurationId, filters, {}).then(link => {
            setEmbedLink(link);
        });
    }, [accessToken, dataProductId]);

    if (!embedLink) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Spinner />
                <span>Loading cost report...</span>
            </div>
        );
    }

    return <div className="data-product-cost-report">
        <iframe src={embedLink} />
    </div>;
};
