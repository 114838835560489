import React from 'react';
import { Card, colors } from '@cimpress/react-components';
import IconAnalyticsPieAlt from '@cimpress-technology/react-streamline-icons/lib/IconAnalyticsPieAlt';
import { DomainDataProducts } from './dataProducts/DomainDataProducts';
import { Link } from 'react-router-dom';

export const Dashboard: React.FC = () => {
    return <div className='row' style={{ marginRight: 0 }}>
        <div className='col-md-offset-1 col-md-10'>
            <div className='row'>
                <div style={{ textAlign: 'left' }} className='col-md-6' >

                    <h2 style={{ color: colors.persimmon.base }}>Welcome to Data Portal</h2>
                    <p style={{ color: colors.shale }}>
                        Data Portal provides a platform for creating, publishing and accessing data products for Cimpress teams and businesses.
                        <br />
                        Data Portal was developed by Data Domain to enhance its data platform offering for ingestion, storage and consumption of data.
                        <br /><br />
                        Need Help? Just slack
                        <a href="https://cimpress.slack.com/app_redirect?channel=data-domain-support" target="_blank" rel="noopener noreferrer"> #data-domain-support </a>
                        or email <a href="mailto:CTDataDomain@cimpress.com">CTDataDomain@cimpress.com</a>
                    </p>
                </div>

                <div style={{ textAlign: 'left', color: colors.shale }} className='col-md-3 col-md-offset-3' >
                    <Card style={{ maxWidth: '200pt', float: 'right' }}>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <IconAnalyticsPieAlt size='2x' weight='light' />
                            </div>

                            <small style={{ marginLeft: 16 }}>
                                Interested in our Data tools?<br />
                                Visit our <Link to={`/dataservices`}> services and tools</Link>
                            </small>
                        </div>
                    </Card>
                </div>
            </div>
            <br />
            <DomainDataProducts />

        </div >
    </div >;
};
