import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/storeHooks";
import { OperationStatus } from "../interfaces/common";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { Clients } from "@cimpress-technology/data-portal-core";
import {
    ErrorFirstMember,
    FirstMemberCache,
    ValidFirstMember,
    setBatchFirstMemberCache,
    cleanFirstMemberCache,
    isValidFirstMember,
} from "@cimpress-technology/data-portal-core/lib/features/firstMemberCache/firstMemberCacheSlice";

type GenericState = {
    reset: () => void;
    firstMemberInfo: FirstMemberCache;
    needCoamDataForTheseCanonicalIds: string[];
};

type IdleState = {
    status: "idle";
};

type LoadingState = {
    status: "loading";
};

type SuccessState = {
    status: "success";
};

type ErroState = {
    status: "error";
};

type ReturnType = (IdleState | LoadingState | SuccessState | ErroState) &
    GenericState;

type Props = {
    accessToken?: string;
    dataProductIds: string[];
};

const useFirstProductTeamMember = (props: Props): ReturnType => {
    const [status, setStatus] = useState<OperationStatus>("idle");
    const dispatch = useAppDispatch();
    const { firstMemberCache } = useAppSelector(
        (state) => state.firstMemberCache
    );
    const reset = () => {
        dispatch(cleanFirstMemberCache());
        setStatus("idle");
    };

    const needCoamDataForTheseCanonicalIds = useMemo(() => {
        return Object.values(firstMemberCache || [])
            .map((member) =>
                isValidFirstMember(member) ? member.data.canonicalId : undefined
            )
            .reduce((acc: string[], current: string | undefined) => {
                if (current) {
                    if (!acc.includes(current)) acc.push(current);
                }
                return acc;
            }, []);
    }, [firstMemberCache]);

    const needFirstMemberForTheseDPsIds = useMemo(() => {
        const needFirstMembers = Array.from(
            new Set(
                props.dataProductIds.filter(
                    (dpId) =>
                        !Object.keys(firstMemberCache || {}).includes(dpId)
                )
            )
        );
        return needFirstMembers;
    }, [props.dataProductIds, firstMemberCache]);

    useEffect(() => {
        const getFirstProductTeamMember = async () => {
            const accessToken = props.accessToken;
            if (!accessToken) {
                setStatus("error");
                return;
            }
            if (needFirstMemberForTheseDPsIds.length === 0) {
                setStatus("success");
                return;
            }
            setStatus("loading");
            dispatch(
                setBatchFirstMemberCache(
                    needFirstMemberForTheseDPsIds.map((id) => ({
                        status: "loading",
                        dataProductId: id,
                        data: null,
                    }))
                )
            );
            const results: (ValidFirstMember | ErrorFirstMember)[] =
                await Promise.all(
                    needFirstMemberForTheseDPsIds.map(async (dataProductId) => {
                        try {
                            const team =
                                await Clients.DataPortalAPI.fetchMembers(
                                    accessToken,
                                    dataProductId
                                );
                            if (team._embedded.item.length === 0)
                                throw new Error("Empty member list.");
                            const firstProductOwner = (
                                team._embedded.item || []
                            ).find((o) =>
                                o.labels
                                    .map((l) => l.toLowerCase())
                                    .includes("product manager")
                            );
                            const firstMember = (
                                team._embedded.item || []
                            ).sort((a, b) =>
                                a.canonicalId.localeCompare(b.canonicalId)
                            )[0];
                            return {
                                status: "succeeded",
                                dataProductId: dataProductId,
                                data: firstProductOwner || firstMember,
                            };
                        } catch (err) {
                            return {
                                status: "failed",
                                dataProductId: dataProductId,
                                errorMessage:
                                    parseUnknownErrorTypeToErrorMessage(err),
                            };
                        }
                    })
                );
            dispatch(setBatchFirstMemberCache(results));
            setStatus("success");
        };
        getFirstProductTeamMember();
    }, [props.accessToken, needFirstMemberForTheseDPsIds, dispatch]);

    switch (status) {
        case "idle":
            return {
                status: "idle",
                reset,
                firstMemberInfo: firstMemberCache,
                needCoamDataForTheseCanonicalIds,
            };
        case "loading":
            return {
                status: "loading",
                reset,
                firstMemberInfo: firstMemberCache,
                needCoamDataForTheseCanonicalIds,
            };
        case "success":
            return {
                status: "success",
                reset,
                firstMemberInfo: firstMemberCache,
                needCoamDataForTheseCanonicalIds,
            };
        case "error":
            return {
                status: "error",
                reset,
                firstMemberInfo: firstMemberCache,
                needCoamDataForTheseCanonicalIds,
            };
    }
};

export default useFirstProductTeamMember;
