import { useEffect, useState } from "react";
import { OperationStatus } from "../interfaces/common";
import { getDomains } from "@cimpress-technology/data-portal-core/lib/clients/dataPortalApi";
import { Domain } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";

type Props = {
    account?: string;
    accessToken?: string;
};

type IdleResult = {
    status: "idle";
    domains: null;
    error: null;
};

type LoadingResult = {
    status: "loading";
    domains: null;
    error: null;
};

type SuccessResult = {
    status: "success";
    domains: Domain[];
    error: null;
};

type ErrorResult = {
    status: "error";
    domains: Domain[];
    error: Error;
};

type Result = IdleResult | LoadingResult | SuccessResult | ErrorResult;

const useDomains = (props: Props): Result => {
    const [status, setStatus] = useState<OperationStatus>("idle");
    const [domains, setDomains] = useState<Domain[] | null>(null);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (!props.accessToken || !props.account) {
            return;
        }
        setStatus("loading");
        getDomains(props.accessToken, props.account)
            .then((domains) => {
                setDomains(domains);
                setStatus("success");
            })
            .catch((error) => {
                setError(error);
                setDomains([]);
                setStatus("error");
            });
    }, [props.accessToken, props.account]);

    switch (status) {
        default:
        case "idle":
            return {
                status: "idle",
                domains: null,
                error: null,
            };
        case "loading":
            return {
                status: "loading",
                domains: null,
                error: null,
            };
        case "success":
            return {
                status: "success",
                domains: domains || [],
                error: null,
            };
        case "error":
            return {
                status: "error",
                domains: [],
                error: error || new Error("Unknown error"),
            };
    }
};

export default useDomains;
