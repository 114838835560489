/** Some utilities */
export const buildSlackURL = (channelName: string, account: string | null): string => {
    const accountKey = (account || 'cimpress').toLowerCase();
    const accountToSlack = new Map<string, string>([
        ["cimpress", "cimpress"],
        ["cimpress technology", "cimpress"],
        ["vistaprint", "vistaprint"]]);
    return `https://${(accountToSlack.get(accountKey) || 'cimpress').toLowerCase()}.slack.com/app_redirect?channel=${channelName.trim().replace("#", "")}`;
};

const accountFromCluster = (cluster: string): string => {
    return cluster.split('.').length > 0 ? cluster.split('.')[0] : "error";
};

export const buildDataDiscoveryURL = (cluster: string, database: string, schema: string, name: string): string => {
    return `https://data-discovery.cimpress.io/#/?selectedTreeItem=${accountFromCluster(cluster)}.eu-west-1.${database}.${schema}.${name}`;
};

export const buildSnowflakeURL = (cluster: string, database: string, schema: string, isView: boolean, name: string): string => {
    return `https://app.snowflake.com/eu-west-1/${accountFromCluster(cluster)}/data/databases/${(database).toUpperCase()}/schemas/${(schema).toUpperCase()}/${isView ? 'view' : 'table'}/${(name).toUpperCase()}/data-preview`;
};