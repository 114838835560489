import React, { useState } from "react";
import { Button, Modal, colors } from "@cimpress/react-components";
import IconBin from "@cimpress-technology/react-streamline-icons/lib/IconBin";
import { useAppDispatch } from "../../../../store/storeHooks";
import { removePdwDataset } from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/common";
import { DatasetIdFormatType, translateDatasetId } from "../../../../utils/dataset/datasetId";

type Props = { datasetId: string; accessToken: string; dataProductId: string };

const DeletePdwDatasetButton = (props: Props) => {
    const dispatch = useAppDispatch();
    const [showConfirmationModal, setShowConfirmationModal] =
        useState<boolean>(false);

    return (
        <>
            <Modal
                closeButton={true}
                title="CONFIRMATION"
                closeOnOutsideClick={true}
                show={showConfirmationModal}
                onRequestHide={() => setShowConfirmationModal(false)}
                status="danger"
            >
                <div>
                    <p>
                        Are you sure you want to delete <b>{props.datasetId}</b>{" "}
                        from the Data Product ?
                    </p>
                </div>
                <br />
                <div className="pull-right">
                    <Button onClick={() => setShowConfirmationModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                            dispatch(
                                removePdwDataset({
                                    accessToken: props.accessToken,
                                    dataProductId: props.dataProductId,
                                    resourceId: translateDatasetId(props.datasetId, DatasetIdFormatType.PdwDatasetId),
                                })
                            );
                            setShowConfirmationModal(false);
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </Modal>
            <div
                style={{
                    cursor: "pointer",
                    display: "flex",
                    alignSelf: "center",
                }}
                onClick={() => setShowConfirmationModal(true)}
            >
                <IconBin
                    style={{
                        color: colors.danger.base,
                        height: "17px",
                    }}
                    weight="fill"
                />
            </div>
        </>
    );
};

export default DeletePdwDatasetButton;
