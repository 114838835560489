import axios from "axios";
import { DataProduct } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { ReactAppEnvironment } from "../common";

export interface ProductTeamMember {
    canonicalId: string,
    isAdmin: boolean,
    labels: string[],
    updatedBy: string,
    updatedAt: number,
    createdAt: number,
    createdBy: string
    order?: number,
}

export interface ProductTeamResponse {
    count: number,
    _embedded: { item: ProductTeamMember[] }
}

export interface DocumentationURL {
    title?: string,
    url: string
}

export interface ExtendedDataProduct extends DataProduct {
    documentation?: DocumentationURL[],
    support?: { slack?: string, email?: string }
}

const getBaseUrl = () => {
    const baseUrl = process.env.REACT_APP_DP_API_URL || ( process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'api.data.cimpress.io' : 'stg-api.data.cimpress.io' );
    return `https://${baseUrl}`;
};

export const getDataProduct = async (accessToken: string, dataProductId: string): Promise<ExtendedDataProduct> => {
    const res = await axios.get<ExtendedDataProduct>(`${getBaseUrl()}/v0/dataproducts/${dataProductId}`,
        { headers: { Authorization: `Bearer ${accessToken}` } });
    return res.data;
};

export type feedbackPayload = {
    isAnonymous: boolean,
    domain: string,
    uniquenessRating: number,
    usefulnessRating: number,
    additionalFeedback?: string,
}
export const postFeedback = async (accessToken: string, dataProductId: string, feedback: feedbackPayload): Promise<void> => {
    await axios.post(`${getBaseUrl()}/v0/dataproducts/${dataProductId}/feedback`,
        feedback,
        { headers: { Authorization: `Bearer ${accessToken}`}});
    return;
};
