import React from "react";

const WifiBars = (props: {
    controlValue: number;
    controlFunction: (value: number) => void;
}) => {
    return (
        <div className="wifi-bars">
            <div
                className="wifi-bars__bar"
                onClick={() => props.controlFunction(1)}
            >
                <div
                    className={
                        props.controlValue >= 1 ? "wifi-bars__bar-selected" : ""
                    }
                ></div>
            </div>
            <div
                className="wifi-bars__bar"
                onClick={() => props.controlFunction(2)}
            >
                <div
                    className={
                        props.controlValue >= 2 ? "wifi-bars__bar-selected" : ""
                    }
                ></div>
            </div>
            <div
                className="wifi-bars__bar"
                onClick={() => props.controlFunction(3)}
            >
                <div
                    className={
                        props.controlValue >= 3 ? "wifi-bars__bar-selected" : ""
                    }
                ></div>
            </div>
            <div
                className="wifi-bars__bar"
                onClick={() => props.controlFunction(4)}
            >
                <div
                    className={
                        props.controlValue >= 4 ? "wifi-bars__bar-selected" : ""
                    }
                ></div>
            </div>
            <div
                className="wifi-bars__bar"
                onClick={() => props.controlFunction(5)}
            >
                <div
                    className={
                        props.controlValue >= 5 ? "wifi-bars__bar-selected" : ""
                    }
                ></div>
            </div>
        </div>
    );
};

export default WifiBars;
