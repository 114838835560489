
import { useState, useEffect } from 'react';
import { ReactAppEnvironment } from '../common';
import { useAppDispatch, } from "../store/storeHooks";
import { DataPortalAPI } from '@cimpress-technology/data-portal-core/lib/interfaces';
import { UserDetails } from '@cimpress-technology/data-portal-core/lib/common/interfaces';
import { ExtendedDataProduct } from '../clients/dataPortalApi';
import { DataProductCoamRolesState } from '@cimpress-technology/data-portal-core/lib/features/dataProductCoam/dataProductCoamRolesSlice';
import { WritableDraft } from 'immer/dist/types/types-external';

export interface DatabricksManagementUIProps {
    referenceId: string;
    coamGroupId: string;
    dataProductName: string;
    hasDataProductAccess: boolean;//=hasAdminAccess
    accessToken: string;
    apiUrl: string;
    onUnitUpdated: (units: string[]) => Promise<void>;
}

type databricksResource = {
    type: DataPortalAPI.DataResourceType;
    name: string;
}
const getDatabricksApiUrl = () => {
    const baseUrl = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'prd.api.databricks-management.cimpress.io' : 'dev.api.databricks-management.cimpress.io';
    return `https://${baseUrl}`;
};

export const useDatabricks = (userDetails: UserDetails, dataProduct: ExtendedDataProduct | null, dataProductCoamRolesState:  WritableDraft<DataProductCoamRolesState>): {
    databricksModuleProps: Partial<DatabricksManagementUIProps> | undefined;
    databricksUnits: databricksResource[]
} => {

    const dispatch = useAppDispatch();

    const [databricksUnits, setDatabricksUnits] = useState<databricksResource[]>([]);
    const [databricksModuleProps, setDatabricksModuleProps] = useState<Partial<DatabricksManagementUIProps>>();

    useEffect(() => {
        async function getDatabricksUnitsSummary() {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            if(!dataProduct || (window as any)?.dataportalresources?.databricks == undefined)
               return [];
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const data = (await (window as any).dataportalresources.databricks.summary(getDatabricksApiUrl(), userDetails.accessToken, dataProduct.adminCoamGroupId)).map(r => ({ name: r, type: DataPortalAPI.DataResourceType.Databricks }));
            setDatabricksUnits(data);
        }

        if(databricksUnits.length == 0 && userDetails.accessToken && dataProduct?.adminCoamGroupId) {
            getDatabricksUnitsSummary();
        }

    }, [dataProduct, userDetails.accessToken, dataProduct?.adminCoamGroupId, databricksUnits.length, (window as any)?.dataportalresources?.databricks]); // eslint-disable-line

    useEffect(() => {
       if(dataProduct == null)
           return;

        const onUnitUpdated = async (units: string[]) : Promise<void> =>  {
            const data = units.map(r => ({ name: r, type: DataPortalAPI.DataResourceType.Databricks }));
            setDatabricksUnits(data);
            return Promise.resolve();
        };

        const referenceId = dataProduct.dataProductId;
        const coamGroupId = dataProduct.adminCoamGroupId || '';
        const dataProductName = dataProduct.dataProductName;
        const accessToken = userDetails.accessToken || '';
        const apiUrl = getDatabricksApiUrl();
        const databricksProps = {referenceId, dataProductName, coamGroupId, accessToken, apiUrl, onUnitUpdated};
        setDatabricksModuleProps(databricksProps);
    }
    , [userDetails.accessToken, dataProduct, dataProduct?.adminCoamGroupId, dataProductCoamRolesState.coamRoles?.admin, dispatch]);

    return { databricksModuleProps, databricksUnits };
};
