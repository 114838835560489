import { Button, colors, Tooltip } from "@cimpress/react-components";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import {
    IconAlertTriangle,
    IconAdd,
    IconCheck,
} from "@cimpress-technology/react-streamline-icons/lib";
import React from "react";
import {
    addPdwDataset,
} from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/common";
import { AuthContext } from "../../../../context/authContext";
import { useAppDispatch, useAppSelector } from "../../../../store/storeHooks";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { DatasetIdFormatType, translateDatasetId } from "../../../../utils/dataset/datasetId";
import { fetchResourcesPdwRoles } from "@cimpress-technology/data-portal-core/lib/features/resources/common";

type Props = {
    dataProductId: string;
    datasetId: string;
    /** Holds the Data Product Id where the dataset is already in use (published).  */
    usedInThisDataProductId: string | null;
    datasetDomainId: string | null
    dataProductDomainId: string
};

export const AddPdwDatasetButton = (props: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { datasetsStatus, datasetsErrors } = useAppSelector(
        (state) => state.resourcesPdwDatasets
    );

    const { accessToken } = React.useContext(AuthContext);

    if (accessToken == null) return <div>Access Token required.</div>;

    const translatedDatasetId = translateDatasetId(props.datasetId, DatasetIdFormatType.PdwDatasetId);

    const isAddDatasetDisabled = () => {
        //Disabling the Add Button if the dataset is used in other data product 
        //Disabling the Add Button if the dataset is null (To be removed once Async Data Discovery Metadata Updater is ready)
        if(props.usedInThisDataProductId || props.datasetDomainId==null) return true;

        //Disabling the Add button if dataset belonghs to other domain
        if(props.datasetDomainId!=null && props.dataProductDomainId!=props.datasetDomainId) return true;
        return false;
    };

    const getDisabledReasonTooltip = () => {
        if (props.usedInThisDataProductId)
            return <span>
                The data product is used in published data
                product{" "}
                <a
                    href={`/dataProducts/${props.usedInThisDataProductId}?tab=Resources&subtab=resourcesPdwDataset`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {props.usedInThisDataProductId}
                </a>
                . You cannot add this as output port in your
                data Product.
            </span>;

        if (props.datasetDomainId==null)
            return <span>
                This dataset is not assigned to any domain.
                You cannot add this as output port in your
                data Product.
            </span>;

        if (props.datasetDomainId != null && props.dataProductDomainId != props.datasetDomainId)
            return <span>
                This dataset is assigned to another domain.
                You cannot add this as output port in your
                data Product.
            </span>;
    };

    const AddButton = <Button
        variant="default"
        style={{ height: "100%", width: "100%" }}
        disabled={isAddDatasetDisabled()}
        onClick={() =>
            dispatch(
                addPdwDataset({
                    accessToken,
                    dataProductId: props.dataProductId,
                    resourceId: translatedDatasetId
                })
            ).then((res) => {
                if (res.meta.requestStatus === "fulfilled")
                    dispatch(fetchResourcesPdwRoles({ accessToken, dataProductId: props.dataProductId }));
            })
        }
    >
        <IconAdd weight="fill" />
    </Button>;

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                <>
                    {!['loading', 'succeded'].includes(datasetsStatus[translatedDatasetId]?.add) && (
                        isAddDatasetDisabled() ?
                            (
                                <Tooltip
                                    direction="left"
                                    style={{ height: "100%", width: "100%" }}
                                    contents={ getDisabledReasonTooltip()}
                                >
                                    {AddButton}
                                </Tooltip>
                            ): <>{AddButton}</>
                )}
                </>

                {datasetsStatus[translatedDatasetId]?.add ===
                    "failed" && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Tooltip
                            contents={
                                <div>
                                    {parseUnknownErrorTypeToErrorMessage(
                                        datasetsErrors[
                                            translatedDatasetId
                                        ]
                                    )}
                                </div>
                            }
                        >
                            <IconAlertTriangle
                                weight="fill"
                                style={{ color: colors.warning.base }}
                            />
                        </Tooltip>
                    </div>
                )}
                {datasetsStatus[translatedDatasetId]?.add ===
                    "loading" && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <Spinner size="medium" />
                    </div>
                )}
                {datasetsStatus[translatedDatasetId]?.add ===
                    "succeeded" && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <IconCheck
                            weight="fill"
                            style={{ color: colors.success.base }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
