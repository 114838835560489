import React, { useRef } from "react";
import { Modal } from "@cimpress/react-components";
import { Button } from "@cimpress/react-components";
import HtmlEditor from "../utils/TextEditor";
import { Editor as TinyMCE } from "tinymce";
import { renderError } from "../../Render";
import { UpdateCustomFieldProps } from "./UpdateCustomFieldBase";
import { useAppSelector } from "../../../../store/storeHooks";
import { Spinner } from "@cimpress/react-components/lib/shapes";

const UpdateCustomFieldValueModal = (props: UpdateCustomFieldProps) => {
    const editorRef = useRef<TinyMCE | null>(null);
    const { fieldStatus, fieldError } = useAppSelector(
        (state) => state.customFields
    );
    const onCreate = () => {
        if (editorRef.current) {
            props.fnOnSave(editorRef.current.getContent());
        }
    };

    return (
        <Modal
            status="info"
            show={true}
            title={props.displayName}
            onRequestHide={props.fnOnClose}
            closeButton={true}
            closeOnOutsideClick={true}
            size="lg"
            footer={
                <div>
                    <Button
                        variant="outline-secondary"
                        disabled={fieldStatus[props.customFieldId] === "loading"}
                        onClick={props.fnOnClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        disabled={fieldStatus[props.customFieldId] === "loading"}
                        onClick={onCreate}
                    >
                        {fieldStatus[props.customFieldId] === "loading" ? (
                            <Spinner size="small" />
                        ) : (
                            "Update"
                        )}
                    </Button>
                </div>
            }
        >
            <HtmlEditor
                editorRef={editorRef}
                content={props.value}
                disabled={fieldStatus[props.customFieldId] === "loading"}
            />
            {fieldError[props.customFieldId] &&
                renderError(
                    fieldError[props.customFieldId]?.message,
                    new Error(fieldError[props.customFieldId]?.message)
                )}
        </Modal>
    );
};

export default UpdateCustomFieldValueModal;
