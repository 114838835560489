
import { useState, useEffect } from 'react';
import { ReactAppEnvironment } from '../common';
import { useAppDispatch, } from "../store/storeHooks";
import { DataPortalAPI } from '@cimpress-technology/data-portal-core/lib/interfaces';
import { UserDetails } from '@cimpress-technology/data-portal-core/lib/common/interfaces';
import { ExtendedDataProduct } from '../clients/dataPortalApi';
import { DataProductCoamRolesState } from '@cimpress-technology/data-portal-core/lib/features/dataProductCoam/dataProductCoamRolesSlice';
import { WritableDraft } from 'immer/dist/types/types-external';

export interface DbtManagementUIProps {
    environment: string;
    referenceId: string;
    coamGroupId: string;
    dataProductName: string;
    hasDataProductAccess: boolean;//=hasAdminAccess
    accessToken: string;
    apiUrl: string;
    userDetailsCanonicalId: string;
    onUnitUpdated: (units: string[]) => Promise<void>;
}

type dbtResource = {
    type: DataPortalAPI.DataResourceType;
    name: string;
}
const getDbtApiUrl = () => {
    // We are to point to the production API temporary to do not affect our users with our testing: dev.dbt.data.cimpress.io
    const baseUrl = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'prd.dbt.data.cimpress.io' : 'stg.dbt.data.cimpress.io';
    return `https://${baseUrl}`;
};

export const useDbt = (userDetails: UserDetails, dataProduct: ExtendedDataProduct | null, dataProductCoamRolesState: WritableDraft<DataProductCoamRolesState>): {
    dbtModuleProps: Partial<DbtManagementUIProps> | undefined;
    dbtUnits: dbtResource[]
} => {

    const dispatch = useAppDispatch();

    const [dbtUnits, setDbtUnits] = useState<dbtResource[]>([]);
    const [dbtModuleProps, setDbtModuleProps] = useState<Partial<DbtManagementUIProps>>();

    useEffect(() => {
        async function getDbtUnitsSummary() {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            if (!dataProduct || (window as any)?.dataportalresources?.dbt == undefined)
                return [];
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const data = (await (window as any).dataportalresources.dbt.summary(getDbtApiUrl(), userDetails.accessToken, dataProduct.dataProductId)).map(r => ({ name: r, type: DataPortalAPI.DataResourceType.DBT }));
            setDbtUnits(data);
        }

        if (dbtUnits.length == 0 && userDetails.accessToken && dataProduct?.adminCoamGroupId) {
            getDbtUnitsSummary();
        }

    }, [dataProduct, userDetails.accessToken, dataProduct?.adminCoamGroupId, dbtUnits.length, (window as any)?.dataportalresources?.dbt]); // eslint-disable-line

    useEffect(() => {
        if (dataProduct == null)
            return;

        const onUnitUpdated = async (units: string[]): Promise<void> => {
            const data = units.map(r => ({ name: r, type: DataPortalAPI.DataResourceType.DBT }));
            setDbtUnits(data);
            return Promise.resolve();
        };

        const referenceId = dataProduct.dataProductId;
        const coamGroupId = dataProduct.adminCoamGroupId || '';
        const dataProductName = dataProduct.dataProductName;
        const accessToken = userDetails.accessToken || '';
        const apiUrl = getDbtApiUrl();
        const userDetailsCanonicalId = userDetails.canonicalId;
        const environment = process.env.REACT_APP_ENVIRONMENT;
        const dbtProps = { environment, referenceId, coamGroupId, dataProductName, accessToken, apiUrl, userDetailsCanonicalId, onUnitUpdated };
        setDbtModuleProps(dbtProps);
    }
        , [userDetails.accessToken, dataProduct, dataProduct?.adminCoamGroupId, dataProductCoamRolesState.coamRoles?.admin, userDetails?.canonicalId, dispatch]);

    return { dbtModuleProps, dbtUnits };
};
