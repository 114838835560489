export interface Auth0ClientDetails {
    name: string;
    description: string;
    app_type: string;
    account: string;
    grant_types: string[];
    token_endpoint_auth_method: string;
    client_metadata: Record<string, unknown>;
    protected_client_metadata: Record<string, unknown>;
    oidc_conformant: boolean;
    callbacks: string[];
    allowed_logout_urls: string[];
    allowed_origins: string[];
    web_origins: string[];
    initiate_login_uri: string;
    mobile: {
        android: {
            app_package_name: string;
            sha256_cert_fingerprints: string[];
        };
        ios: {
            team_id: string;
            app_bundle_identifier: string;
        };
    };
    addons: { samlp: Record<string, unknown> };
    anonApiKey: string;
    refresh_token: Record<string, unknown>;
    client_id: string;
    client_secret: string;
}

export interface OAuthResponse {
    access_token: string;
    expires_in: string;
    token_type: string;
}

export interface Auth0ClientCredentials {
    id: string;
    secret: string;
}

export enum ChangeClientConfigOperation {
    Add = "add",
    Remove = "remove",
}

export enum ReactAppEnvironment { // Also in data-portal-core
    Production = "production",
    Staging = "staging",
}

export const getDefaultAvatar = (canonicalPrincipalId?: string) => {
    const DEFAULT_AVATAR =
        "http://www.gravatar.com/avatar/3b3be63a4c2a439b013787725dfce802?d=identicon";
    if (canonicalPrincipalId) {
        return `https://i1.wp.com/cdn.auth0.com/avatars/${canonicalPrincipalId
            .toLowerCase()
            .substring(0, 2)}.png?ssl=1`;
    }
    return DEFAULT_AVATAR;
};
