/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useCallback } from 'react';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { UserDetails } from '@cimpress-technology/data-portal-core/lib/common';
import { useSnowflakeWarehouse } from '../../hooks/useSnowflakeWarehouse';


interface Props {
    dataProduct: DataPortalAPI.DataProduct,
    userDetails: UserDetails,
}

export const PDWWarehouse: React.FC<Props> = (props) => {
    const pdwWarehouse = useRef<HTMLInputElement>(null);
    const renderFunction = (window as any)?.dataportalresources["compute-management-ui"]?.render;
    const { computeModuleProps } = useSnowflakeWarehouse(props.userDetails, props.dataProduct);
    const renderFunctionCallback = useCallback( async (domElement) => {
        if(renderFunction != undefined && computeModuleProps != undefined && computeModuleProps?.accessToken)
        {
            await renderFunction(domElement, { ...computeModuleProps });
        }
    }, [computeModuleProps, renderFunction]);
    useEffect(() => {
        if (pdwWarehouse?.current != null &&
            pdwWarehouse?.current?.childElementCount == 0) {
                renderFunctionCallback(pdwWarehouse.current);
        }
    }
        , [pdwWarehouse, renderFunctionCallback]);
  
        return (
            <div ref={pdwWarehouse}></div>
        );
};
