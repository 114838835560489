import { useState } from "react";
import { postFeedback, feedbackPayload } from "../clients/dataPortalApi";
import { OperationStatus } from "../interfaces/common";

type Props = {
    accessToken?: string;
    dataProductId: string;
    feedbackPayload: feedbackPayload;
}

type GenericState = {
    submitFeedback: () => Promise<void>;
    reset: () => void;
}
type IdleState = {
    status: 'idle';
    error: null;
}

type LoadingState = {
    status: 'loading';
    error: null;
}

type SuccessState = {
    status: 'success';
    error: null;
}

type ErrorState = {
    status: 'error';
    error: Error;
}

type ReturnType = (IdleState | LoadingState | SuccessState | ErrorState) & GenericState;
const useSubmitFeedback = (props: Props): ReturnType => {
    const [status, setStatus] = useState<OperationStatus>('idle');
    const [error, setError] = useState<Error | null>(null);
    const reset = () => {
        setStatus('idle');
        setError(null);
    };
    const submitFeedback = async () => {
        try {
            setStatus('loading');
            if (!props.accessToken) {
                throw new Error('No access token provided');
            }
            if (props.feedbackPayload.domain.length === 0) {
                throw new Error('Domain is required');
            }
            await postFeedback(props.accessToken, props.dataProductId, props.feedbackPayload);
            setStatus('success');
        } catch (err) {
            if (err instanceof Error) {
                setError(err);
            } else {
                setError(new Error('Unknown error'));
            }
            setStatus('error');
        }
    };

    switch (status) {
        default:
        case 'idle':
            return {
                status: 'idle',
                error: null,
                submitFeedback: submitFeedback,
                reset
            };
        case 'loading':
            return {
                status: 'loading',
                error: null,
                submitFeedback: submitFeedback,
                reset
            };
        case 'success':
            return {
                status: 'success',
                error: null,
                submitFeedback: submitFeedback,
                reset
            };
        case 'error':
            return {
                status: 'error',
                error: error || new Error('Unknown error'),
                submitFeedback: submitFeedback,
                reset
            };
        }
};

export default useSubmitFeedback;