import React from "react";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import selectStyles from "@cimpress/react-components/lib/Select/styles";
import { Interfaces, Clients } from "@cimpress-technology/data-portal-core";
import { SelectWrapper } from "@cimpress/react-components";
import { UserProfile } from "./UserProfile";
import { CanonicalPrincipalSearchResult } from "@cimpress-technology/data-portal-core/lib/interfaces/coam";
import { getDefaultAvatar } from "../../../../../../common";

export const UserLookup: React.FC<{
    accessToken: string;
    existingMembers: Set<string>;
    onUserSelected(user: CanonicalPrincipalSearchResult): void;
    inAccountId?: string;
}> = ({ accessToken, existingMembers, onUserSelected, inAccountId }) => {
    type UserRow = {
        user: Interfaces.Coam.CanonicalPrincipalSearchResult;
        isDisabled?: boolean;
    };
    const findPrincipals = async (searchText: string) => {
        const result = await Clients.Coam.findCoamPrincipals(
            accessToken,
            searchText
        );
        const availableUsers: UserRow[] = [];
        const alreadyAMember: UserRow[] = [];
        const differentBusiness: UserRow[] = [];
        const clients: UserRow[] = [];

        result.forEach((user) => {
            if (user.is_pending) {
                return;
            }

            if (existingMembers.has(user.canonical_principal.toLowerCase())) {
                alreadyAMember.push({ user, isDisabled: true });
            } else if (user.is_client) {
                clients.push({ user });
            } else if (inAccountId && user.account_id !== inAccountId) {
                differentBusiness.push({ user, isDisabled: true });
            } else {
                availableUsers.push({ user });
            }
        });

        return !clients.length &&
            !alreadyAMember.length &&
            !differentBusiness.length
            ? availableUsers
            : [
                  {
                      label: "Matching users",
                      options: availableUsers,
                  },
                  {
                      label: "Matching clients",
                      options: clients,
                  },
                  {
                      label: "Already a member",
                      options: alreadyAMember,
                  },
                  {
                      label: "User in different business",
                      options: differentBusiness,
                  },
              ];
    };

    return (
        <SelectWrapper
            styles={selectStyles}
            selectedSelect={AsyncSelect}
            cacheOptions={false}
            defaultOptions={false}
            isClearable={true}
            label={"Type the user email"}
            loadOptions={debounce(findPrincipals, 300)}
            formatOptionLabel={(option: UserRow) => (
                <UserProfile
                    coamUser={{
                        canonicalPrincipalId: option.user.canonical_principal,
                        isClient: option.user.is_client,
                        picture:
                            option.user.profiles.length > 0
                                ? option.user.profiles[0].picture
                                : getDefaultAvatar(option.user.canonical_principal),
                        fullName:
                            option.user.profiles.length > 0
                                ? `${option.user.profiles[0].given_name} ${option.user.profiles[0].family_name}`
                                : option.user.canonical_principal,
                    }}
                />
            )}
            onChange={(e: UserRow) =>
                e?.user ? onUserSelected(e.user) : null
            }
        />
    );
};
