import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import { DataProduct } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import {
    fetchUserOwnedPdwDatasets,
} from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/common";
import { AuthContext } from "../../../context/authContext";
import { Alert } from "@cimpress/react-components";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { MainTabMenu } from "./components/MainTabMenu";
import { UserOwnedDatasetsDrawer } from "./components/userOwnedDatasets/UserOwnedDatasetsDrawer";
import { translateDatasetId, DatasetIdFormatType } from "../../../utils/dataset/datasetId";
import { OutputPortHook } from "../../../hooks/useOutputPorts";
import { isLoadingPdwDataset } from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/interfaces";

type Props = {
    dataProduct: DataProduct;
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
    domain: DataPortalAPI.Domain;
    pdwDatasetsHook: OutputPortHook<DataPortalAPI.OutputPortType.PDWdataset>;
};

function ResourcesPdwDatasetsMain(props: Props) {
    const dispatch = useAppDispatch();
    const { dataProductId, pdwDatasetCollectionState } = useAppSelector(
        (state) => state.resourcesPdwDatasets
    );
    const { accessToken, profile } = React.useContext(AuthContext);
    const [isSameBusiness] = useState(
        props.domain.accountId ===
            profile?.["https://claims.cimpress.io/account"]
    );

    useEffect(() => {
        if (accessToken) {
            dispatch(
                fetchUserOwnedPdwDatasets({
                    accessToken,
                    dataProductId: props.dataProduct.dataProductId,
                    domainId: props.domain.domainId,
                })
            );
        }
    }, [
        props.dataProduct.dataProductId,
        props.domain.domainId,
        accessToken,
        dispatch,
        dataProductId,
    ]);

    return (
        <div>
            {pdwDatasetCollectionState.status === "failed" ? (
                <Alert
                    status="danger"
                    message={parseUnknownErrorTypeToErrorMessage(pdwDatasetCollectionState.error)}
                ></Alert>
            ) : null}
            {pdwDatasetCollectionState.status === "loading" ? (
                <Alert
                    status="info"
                    message={
                        <div style={{ display: "flex" }}>
                            Fetching Latest details...
                            <Spinner size="small" />
                        </div>
                    }
                ></Alert>
            ) : null}
            <div className="row">
                <div className="col-md-7">
                    <h3 style={{ margin: 0 }}>Internal PDW Datasets</h3>
                    <small>Internal PDW Datasets of the Data Product.</small>
                </div>
                <div className="col-md-5">
                    {props.hasDataProductReadAccess === true && (
                        <UserOwnedDatasetsDrawer
                            dataProduct={props.dataProduct}
                            domain={props.domain}
                            includedDatasetIds={
                            (pdwDatasetCollectionState.data || [])
                                .filter(dataset => !isLoadingPdwDataset(dataset))
                                .map(
                                (o) => translateDatasetId(`${o.resourceId}`, DatasetIdFormatType.DataDiscoveryId)
                            )}
                            hasDataProductAdminAccess={
                                props.hasDataProductAdminAccess
                            }
                            hasDataProductReadAccess={
                                props.hasDataProductReadAccess
                            }
                        />
                    )}
                </div>
            </div>
            <hr />
            <div>
                {accessToken != null && (pdwDatasetCollectionState.status === "succeeded" || pdwDatasetCollectionState.status === "idle") && (
                    <MainTabMenu
                        accessToken={accessToken}
                        isSameBusiness={isSameBusiness}
                        hasDataProductReadAccess={
                            props.hasDataProductReadAccess
                        }
                        hasDataProductAdminAccess={
                            props.hasDataProductAdminAccess
                        }
                        pdwDatasetsHook={props.pdwDatasetsHook}
                    />
                )}
            </div>
        </div>
    );
}

export default ResourcesPdwDatasetsMain;
