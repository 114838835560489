import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/storeHooks";
import { OperationStatus } from "../interfaces/common";
import {
    ValidUserInformation,
    ErrorUserInformation,
    setBatchUserInformationCache,
    cleanUserInformationCache,
    UserInformationCache,
} from "@cimpress-technology/data-portal-core/lib/features/coamUserInfoCache/coamUserInfoCacheSlice";
import { getCOAMUserData } from "../clients/coam";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";

type GenericState = {
    reset: () => void;
    usersCoamInfo: UserInformationCache;
};

type IdleState = {
    status: "idle";
};

type LoadingState = {
    status: "loading";
};

type SuccessState = {
    status: "success";
};

type ErroState = {
    status: "error";
};

type ReturnType = (IdleState | LoadingState | SuccessState | ErroState) &
    GenericState;

type Props = {
    accessToken?: string;
    needDataForTheseCanonicalIds: string[] | null;
};

const useUserCoamInfoCache = (props: Props): ReturnType => {
    const [status, setStatus] = useState<OperationStatus>("idle");
    const dispatch = useAppDispatch();
    const { userInformationCache } = useAppSelector(
        (state) => state.coamUserInfoCache
    );
    const reset = () => {
        dispatch(cleanUserInformationCache());
        setStatus("idle");
    };

    useEffect(() => {
        const getUsersDetails = async () => {
            const accessToken = props.accessToken;
            if (!accessToken) {
                setStatus("error");
                return;
            }
            if (props.needDataForTheseCanonicalIds == null) {
                setStatus("success");
                return;
            }
            const needUserInfo = Array.from(
                new Set(
                    props.needDataForTheseCanonicalIds.filter(
                        (canonicalId) =>
                            !Object.keys(userInformationCache || {}).includes(
                                canonicalId
                            )
                    )
                )
            );
            if (needUserInfo.length === 0) {
                setStatus("success");
                return;
            }
            setStatus("loading");
            dispatch(setBatchUserInformationCache(needUserInfo.map(o => ({status: "loading", canonicalPrincipalId: o, data: null }))));
            const results: (ValidUserInformation | ErrorUserInformation)[] =
                await Promise.all(
                    needUserInfo.map(async (canonicalPrincipalId) => {
                        try {
                            return {
                                status: "succeeded",
                                canonicalPrincipalId: canonicalPrincipalId,
                                data: await getCOAMUserData(
                                    canonicalPrincipalId,
                                    accessToken
                                ),
                            };
                        } catch (err) {
                            return {
                                status: "failed",
                                canonicalPrincipalId: canonicalPrincipalId,
                                errorMessage: `COAM: ${canonicalPrincipalId} ${parseUnknownErrorTypeToErrorMessage(
                                    err
                                )}`,
                            };
                        }
                    })
                );
            dispatch(setBatchUserInformationCache(results));
            setStatus("success");
        };
        getUsersDetails();
    }, [props.accessToken, dispatch, userInformationCache, props.needDataForTheseCanonicalIds]);

    switch (status) {
        default:
        case "idle":
            return {
                status: "idle",
                usersCoamInfo: userInformationCache,
                reset,
            };
        case "loading":
            return {
                status: "loading",
                usersCoamInfo: userInformationCache,
                reset,
            };
        case "success":
            return {
                status: "success",
                usersCoamInfo: userInformationCache,
                reset,
            };
        case "error":
            return {
                status: "error",
                usersCoamInfo: userInformationCache,
                reset,
            };
    }
};

export default useUserCoamInfoCache;
