import React from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { useDataProduct } from "../../../hooks/useDataProduct";
import Title from "./components/Title";
import Details from "./components/Details";
import ProductTeam from "./components/ProductTeam";
import Resources from "./components/Resources";
import CustomFields from "./components/CustomFields";
import Feedback from "./components/Feedback";
import IconNewsPaper from '@cimpress-technology/react-streamline-icons/lib/IconNewspaper';
import { Hooks } from "@cimpress-technology/data-portal-core";

const Summary = (): JSX.Element => {
    const { dataProductId } = useParams<{ dataProductId: string }>();
    const { accessToken, profile } = React.useContext(AuthContext);
    const { dataProduct, loadingDataProduct } = useDataProduct(accessToken, dataProductId);
    const [productTeamLoading, setProductTeamLoading] = React.useState<boolean>(false);

    const { domain, loadingDomain } = Hooks.useDomain(accessToken, dataProduct?.domainId);
    const { accountDetails, loadingAccount } = Hooks.useAccount(accessToken, domain?.accountId);

    const account = profile?.["https://claims.cimpress.io/account"];

    return (
        <div className="summary-container">
            <section className="summary__breadcrumbs">
                <a href="/discover">Data Portal</a> /{" "}
                {dataProduct?.dataProductName}
            </section>
            <Title
                dataProductId={dataProduct?.dataProductId}
                domainId={dataProduct?.domainId}
                dataProductName={dataProduct?.dataProductName}
                published={dataProduct?.published}
            />
            <section className="summary__layout">
                <div className="summary__layout-left">
                    <div className="dp-card summary__description">
                        <div className="summary__card__header">
                            <span className="summary__card__header__icon">
                                <IconNewsPaper />
                            </span>
                            <span className="summary__card__header__text">
                                Product Description
                            </span>
                        </div>
                        <div className="summary__description__body">
                            <div className="summary__description__content">
                                {dataProduct?.summary}
                            </div>
                        </div>
                    </div>
                    <div className="summary__layout-left__information">
                        <div className="summary__layout-left__information-left">
                            <Details
                                dataProductId={dataProduct?.dataProductId}
                                domain={{ loading: loadingDomain, name: domain?.name ?? null }}
                                account={{ loading: loadingAccount, name: accountDetails?.name ?? null }}
                                createdAt={dataProduct?.createdAt}
                                version={dataProduct?.version}
                                accessToken={accessToken}
                                outputPorts={dataProduct?.outputPorts}
                                additionalDocumentation={
                                    dataProduct?.documentation
                                }
                            />
                            <CustomFields
                                accessToken={accessToken}
                                dataProductId={dataProductId}
                            />
                        </div>
                        <div className="summary__layout-left__information-right">
                            <Resources
                                accessToken={accessToken}
                                dataProductId={dataProduct?.dataProductId}
                                outputPorts={dataProduct?.outputPorts}
                                account={{ loading: loadingAccount, name: accountDetails?.name ?? null }}
                            />
                        </div>
                    </div>
                </div>
                <div className="summary__layout-right">
                    <ProductTeam
                        accessToken={accessToken}
                        dataProduct={{
                            loading: loadingDataProduct,
                            dataProductId,
                            supportMail: dataProduct?.support?.email,
                            supportSlack: dataProduct?.support?.slack,
                        }}
                        account={{ loading: loadingAccount, name: accountDetails?.name ?? null}}
                        setProductTeamLoading={setProductTeamLoading}
                    />
                    <Feedback
                        dataProductId={dataProductId}
                        accessToken={accessToken}
                        account={account}
                        productTeamLoading={productTeamLoading}
                    />
                </div>
            </section>
        </div>
    );
};

export default Summary;
