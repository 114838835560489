import React, { useState } from "react";
import {
    NavTab,
    NavTabItem,
} from "@cimpress/react-components";
import {
    Interfaces,
} from "@cimpress-technology/data-portal-core";
import MetadataColumns from "../../shared/MetadataColumns";
import MetadataDescription from "../../shared/MetadataDescription";
import OpenInSnowflakeButton from "../../shared/OpenInSnowflakeButton";
import OpenInDataDiscoveryButton from "../../shared/OpenInDataDiscoveryButton";

type TabControlType = {
    description: boolean;
    columns: boolean;
};
const buildSingleActiveTab = (
    tabName: keyof TabControlType
): TabControlType => {
    const currentControl: TabControlType = {
        description: false,
        columns: false,
    };
    currentControl[tabName] = true;
    return currentControl;
};

type Props = {
    dataset:
        | Interfaces.DataDiscovery.DataDiscoveryDatasetMetadata
        | null;
    isSameBusiness: boolean;
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
}

export const MetadataInfo = (props: Props): JSX.Element => {
    const [tabControl, setTabControl] = useState<TabControlType>(
        buildSingleActiveTab("description")
    );

    if (props.dataset == null) return <></>;

    const {
        description,
        columns,
        cluster,
        isView,
        database,
        schema,
        name,
    } = props.dataset;
    const datasetAccount = cluster?.split(".")[0];

    const TabControl = (
        <NavTab vertical={false}>
            <NavTabItem active={tabControl.description}>
                <button
                    onClick={() => {
                        setTabControl(buildSingleActiveTab("description"));
                    }}
                    style={{ marginLeft: 0 }}
                >
                    Description
                </button>
            </NavTabItem>
            {columns ? (
                <NavTabItem active={tabControl.columns}>
                    <button
                        onClick={() => {
                            setTabControl(buildSingleActiveTab("columns"));
                        }}
                    >
                        Columns
                    </button>
                </NavTabItem>
            ) : null}
        </NavTab>
    );

    return (
        <div className="row">
            <div
                className="col-md-12 card-block"
                style={{ display: "flex", gap: 8 }}
            >
                <OpenInDataDiscoveryButton
                    isSameBusiness={props.isSameBusiness}
                    datasetAccount={datasetAccount}
                    database={database}
                    schema={schema}
                    name={name}
                />
                {props.hasDataProductReadAccess === true && (
                    <OpenInSnowflakeButton
                        datasetAccount={datasetAccount}
                        database={database}
                        schema={schema}
                        name={name}
                        isView={isView}
                    />
                )}
            </div>
            <div className="col-md-12" style={{ marginTop: "10px" }}>
                {TabControl}
            </div>
            <div className="col-md-12">
                <div
                    style={{
                        display: !tabControl.description ? "none" : undefined,
                    }}
                >
                    <MetadataDescription description={description || ""} />
                </div>
                <div
                    style={{
                        display: !tabControl.columns ? "none" : undefined,
                    }}
                >
                    <MetadataColumns columns={columns} />
                </div>

            </div>
        </div>
    );
};
