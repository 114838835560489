import { Button, colors, Modal, Tooltip } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { IconAlertTriangle, IconBin } from '@cimpress-technology/react-streamline-icons/lib';
import React, { useEffect, useState } from 'react';
import { AuthContext } from '../../../context/authContext';
import { InputPortComponent, InputPortType, InputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import axios from 'axios';
import { Common } from '@cimpress-technology/data-portal-core';
import { SliceState } from '@cimpress-technology/data-portal-core/lib/features/common';
import { useAppDispatch, useAppSelector } from '../../../store/storeHooks';
import { removeSingleInputPort } from '@cimpress-technology/data-portal-core/lib/features/inputPorts/common';


export const DeleteInputPortButton: React.FC<{
    dataProductId: string,
    inputPortType: InputPortTypePath,
    useInDataProduct: string,
    inputPortBody: InputPortComponent<InputPortType>
    isPublishedDataProduct: boolean,
    onProcessingStatusChange: (status: SliceState) => void,
    style?: React.CSSProperties;
    customDeleteFunction?: () => void,
    customProcessingStatus?: SliceState,
    customErrorMessage?: string,
}> = ({ dataProductId, inputPortType, inputPortBody, isPublishedDataProduct, onProcessingStatusChange, customDeleteFunction, customErrorMessage, style }) => {

    const dispatch = useAppDispatch();
    const { portDeleteStatus, portDeleteErrors, riverStreams } = useAppSelector(state => state.riverStreams);
    const [ errorDeleting, setErrorDeleting ] = useState(customErrorMessage ? customErrorMessage : '');
    const { accessToken } = React.useContext(AuthContext);
    const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);

    useEffect(() => {
        if (customErrorMessage) setErrorDeleting(customErrorMessage);        
    }, [customErrorMessage]);

    const deleteInputPortOnClick = async (inputPortType: InputPortTypePath, inputPortBody: InputPortComponent<InputPortType>, dataProductId: string) => {
        setErrorDeleting('');
        setShowConfirmationModal(false);
        onProcessingStatusChange("loading");
        try {
            if (accessToken) {
                dispatch(removeSingleInputPort({ accessToken: accessToken, dataProductId: dataProductId, inputPortType: inputPortType, inputPortComponent: inputPortBody }));
                onProcessingStatusChange("succeeded");
            } else {
                setErrorDeleting('Authorization token not found. Try again after refreshing the page');
            }
        } catch(error) {
            let errorMessage = 'There was an Error removing this input Port. Input port might have already been removed. Refresh and Try again or contact #data-domain-support';
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data.message || error.response?.statusText || error.message;
            }
            setErrorDeleting(errorMessage);
            onProcessingStatusChange("failed");
        }
    };

    return <div>
            <div style={{...style}}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
                {
                    portDeleteStatus[inputPortBody.resourceId] === "loading"
                        ? <Spinner size='medium' />
                        :   riverStreams?.some(streams => streams.resourceId === inputPortBody.resourceId)
                            ?   <a style={{ cursor: "pointer" }}  onClick={() => process.env.REACT_APP_ENVIRONMENT !== Common.ReactAppEnvironment.Production || !isPublishedDataProduct ? setShowConfirmationModal(true) : null}>
                                    
                                    <IconBin
                                        style={{color: process.env.REACT_APP_ENVIRONMENT !== Common.ReactAppEnvironment.Production || !isPublishedDataProduct ? colors.danger.base : colors.alloy, height: '17px'}}
                                        weight='fill'
                                    />
                                    
                                    { portDeleteStatus[inputPortBody.resourceId] === "failed"  ?
                                            <Tooltip show={!errorDeleting ? false : undefined} contents={portDeleteErrors[inputPortBody.resourceId] ? portDeleteErrors[inputPortBody.resourceId]?.message : errorDeleting}>
                                                <IconAlertTriangle weight="fill" style={{color: colors.warning.base}} />
                                            </Tooltip>
                                        : null
                                    }
                                </a>
                            : null
                }
                </div>
            </div>

            <Modal closeButton={true} title="CONFIRMATION" closeOnOutsideClick={true} show={showConfirmationModal} onRequestHide={() => setShowConfirmationModal(false)} status='danger'>
                <div>
                    
                    <p>Are you sure you want to delete <b>{inputPortBody.resourceId}</b> from the Data Product ?</p>
                </div>
                <br />
                <div className='pull-right'>
                    <Button onClick={() => setShowConfirmationModal(false)}>Cancel</Button>
                    <Button 
                        variant='primary' 
                        style={{marginLeft: '5px'}} 
                        onClick={() => {
                            process.env.REACT_APP_ENVIRONMENT !== Common.ReactAppEnvironment.Production || !isPublishedDataProduct 
                            ? customDeleteFunction ? (() => { setShowConfirmationModal(false); customDeleteFunction(); })() : deleteInputPortOnClick(inputPortType, inputPortBody, dataProductId) 
                            : null;
                            }
                        }
                    >
                        Confirm
                    </Button>
                </div>
            </Modal>
        </div>;
};
