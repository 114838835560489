export const ACCOUNT_ID_TO_NAME = {
    nonhZxAHRH4Z7PMWBKtwwC: "boxup",
    mUushi4JkEaX1E1bp3xSm7: "buildasign",
    g2Ez5VaoZWoqU22XqPjTLU: "cimpress",
    "7xnyM3eU1yTsjMie4VTRXm": "druck",
    aofiyWQt9jjC1YsFUdEoAs: "exagroup",
    "4W1CB1YZxupiBsB5XExuc4": "nationalpen",
    noS5wU4XH2WRmkaqKqTpfH: "printdeal",
    sUwJnco1V7RtqbL6W8DuQ8: "pixartprinting",
    ca7c3Pxtzhrgm7i4obvTTh: "tradeprint",
    azG5AKjW8jcdAPYh5X9H8K: "vistaprint",
    ozoDdrmewShEcbUDWX8J3V: "vistaprint",
    p9h7ZCQf4m5AEeWDGq8zFT: "wmd",
    FzKMKBXEKd14fjxbfDqpZ: "cimpresstest",
    "8QisTAJiYGQeKvXztSBqtM": "easyflyer",
    qwhj8J5SMLUVpVZbWyAriJ: "printi",
    vs6xeRDduS7HqfTvWn67Xy: "vida",
} as const;

export type ValidAccountId = keyof typeof ACCOUNT_ID_TO_NAME;

export enum DefaultTitles {
    DataAnalyst = "Data Analyst",
    DataEngineer = "Data Engineer",
    DataScientist = "Data Scientist",
    ProductManager = "Product Manager",
    SoftwareEngineer = "Software Engineer",
    UXDesigner = "UX Designer",
}

export const AccountProductTeamLabels = new Map<
    ValidAccountId,
    typeof DefaultTitles
>([
    ["nonhZxAHRH4Z7PMWBKtwwC", DefaultTitles],
    ["mUushi4JkEaX1E1bp3xSm7", DefaultTitles],
    ["g2Ez5VaoZWoqU22XqPjTLU", DefaultTitles],
    ["7xnyM3eU1yTsjMie4VTRXm", DefaultTitles],
    ["aofiyWQt9jjC1YsFUdEoAs", DefaultTitles],
    ["4W1CB1YZxupiBsB5XExuc4", DefaultTitles],
    ["noS5wU4XH2WRmkaqKqTpfH", DefaultTitles],
    ["sUwJnco1V7RtqbL6W8DuQ8", DefaultTitles],
    ["ca7c3Pxtzhrgm7i4obvTTh", DefaultTitles],
    ["azG5AKjW8jcdAPYh5X9H8K", DefaultTitles],
    ["ozoDdrmewShEcbUDWX8J3V", DefaultTitles],
    ["p9h7ZCQf4m5AEeWDGq8zFT", DefaultTitles],
    ["FzKMKBXEKd14fjxbfDqpZ", DefaultTitles],
    ["8QisTAJiYGQeKvXztSBqtM", DefaultTitles],
    ["qwhj8J5SMLUVpVZbWyAriJ", DefaultTitles],
    ["vs6xeRDduS7HqfTvWn67Xy", DefaultTitles],
]);

export const getTitlesAsSelectOptions = (accountId: ValidAccountId) => {
    const titles = AccountProductTeamLabels.get(accountId);
    if (!titles) return [];
    return (Object.keys(titles) as (keyof typeof titles)[]).map((t) => ({
        label: titles[t],
        value: titles[t],
    }));
};
