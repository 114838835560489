
import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import NotificationsHubAuthorizer from '@cimpress-technology/notifications-hub-authorizer';
import { Hooks } from "@cimpress-technology/data-portal-core";

const authorizer = new NotificationsHubAuthorizer({ env: 'prd', centralizedAuth: Hooks.authWrapper });

export interface Subscription {
    id: string;
    resourceType: string;
    actionName: string;
    subscriber: string;
    created: string;
}

export enum NHubResourceType {
    DataPortal = 'dataPortal'
}
export enum NHubSubscribeActions {
    NewDataProduct = 'newDataProduct'
}

export const isAuthorized = async (): Promise<boolean> => {
    const isAuthorized = await authorizer.didUserAuthorize();
    return isAuthorized;
};

export const authorize = (): void => {
    authorizer.ensureAuthorized({ returnUri: window.location.href });
};

export const getSubscriptions = async (accessToken: string, resourceType: string): Promise<Subscription[]> => {

    const res = await axios.get<{
        subscriptions: Subscription[]
    }>('https://subscriptions.commerce.cimpress.io/v1/subscriptions',
        {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: {
                resourceType
            }
        });

    return res.data.subscriptions;
};

export const subscribe = async (accessToken: string, resourceType: string, actionName: string): Promise<Subscription[]> => {

    const res = await axios.post<Subscription[]>('https://subscriptions.commerce.cimpress.io/v1/subscriptions',
        {
            alertInfo: { channel: "email" },
            actionName,
            resourceType
        },
        {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

    return res.data;
};

export const unsubscribe = async (accessToken: string, resourceType: string): Promise<Subscription[]> => {

    const res = await axios.delete<Subscription[]>('https://subscriptions.commerce.cimpress.io/v1/subscriptions',
        {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: {
                resourceType
            }
        });

    return res.data;
};
