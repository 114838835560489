import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { setupStore } from "@cimpress-technology/data-portal-core/lib/store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { FullStory } from '@cimpress-technology/react-reporting';
import { AuthContext, AuthProvider } from './context/authContext';
import { SegmentProvider } from './context/segment';
import { Loudspeaker, LoudspeakerEnvironment } from '@cimpress-technology/react-loudspeaker';
import { Common } from "@cimpress-technology/data-portal-core";
import { App } from './app';

if (Common.isProd) {
  Sentry.init({
    dsn: "https://895cb2892e63401aa96db652cab78509@o543223.ingest.sentry.io/5772329",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const FullStoryIntegration = () => {
  const { accessToken, profile } = React.useContext(AuthContext);
  return accessToken && profile && Common.isProd
    ? <FullStory
      fsOptions={{
        org: '97CT5',
        allowLocalhost: false,
        debug: false
      }}
      sessionInformation={{
        accessToken: accessToken,
        profile: {
          user_id: profile.user_id,
          email: profile.email,
          name: profile.name
        }
      }}
    />
    : null;
};

const LoudSpeaker = () => {
  const { accessToken } = React.useContext(AuthContext);
  return accessToken ? <Loudspeaker
    accessToken={accessToken}
    channelIds={['data-portal']}
    environment={LoudspeakerEnvironment.Production}
    title="Announcements"
  /> : null;
};

const store = setupStore();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <AuthProvider>
        <SegmentProvider>
          <FullStoryIntegration />
          <LoudSpeaker />
          <App />
        </SegmentProvider>
      </AuthProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
