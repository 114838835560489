import React, { MutableRefObject } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCE } from "tinymce";
import { Spinner } from "@cimpress/react-components/lib/shapes";

type Props = {
    editorRef: MutableRefObject<TinyMCE | null>;
    content: string;
    disabled: boolean;
};

const HtmlEditor = (props: Props) => {
    const [loading, setLoading] = React.useState(true);

    return (
        <>
            {loading && <div style={{ textAlign: "center" }}><Spinner size="medium"></Spinner></div>}
            <Editor
                tinymceScriptSrc={
                    process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
                }
                disabled={props.disabled}
                onInit={(evt, editor) => {
                    props.editorRef.current = editor;
                    setLoading(false);
                }}
                initialValue={props.content}
                init={{
                    height: 500,
                    promotion: false,
                    plugins: TMCE_PLUGINS,
                    toolbar: TMCE_TOOLBAR,
                    content_style: "",
                    menu: TMCE_MENU,
                }}
            />
        </>
    );
};

const TMCE_PLUGINS = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "preview",
    "help",
    "wordcount",
];

const TMCE_TOOLBAR =
    "undo redo | blocks | " +
    "bold italic forecolor " +
    " | bullist numlist | " +
    "removeformat | help";

const TMCE_MENU = {
    file: {
        title: "File",
        items: "newdocument restoredraft | preview | export print | deleteallconversations",
    },
    edit: {
        title: "Edit",
        items: "undo redo | cut copy paste pastetext | selectall | searchreplace",
    },
    view: {
        title: "View",
        items: "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments",
    },
    insert: {
        title: "Insert",
        items: "image link addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking | insertdatetime",
    },
    format: {
        title: "Format",
        items: "",
    },
    tools: {
        title: "Tools",
        items: "spellchecker spellcheckerlanguage | a11ycheck code wordcount",
    },
    table: {
        title: "Table",
        items: "inserttable | cell row column | advtablesort | tableprops deletetable",
    },
    help: { title: "Help", items: "help" },
};

export default HtmlEditor;
