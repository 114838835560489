import React from 'react';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { colors, Tooltip } from '@cimpress/react-components';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { useAppSelector } from '../../store/storeHooks';
import { Spinner } from '@cimpress/react-components/lib/shapes';

export const DataProductNode: React.FC<{
    items: {
        type: string, name: string
    }[]
}> = (props) => {

    const groups = {};
    props.items.forEach(item => {
        if (!groups[item.type]) {
            groups[item.type] = [];
        }
        groups[item.type].push(item.name);
    });



    const typeMap: Record<DataPortalAPI.InputPortType | DataPortalAPI.DataResourceType | DataPortalAPI.OutputPortType, string> = {
        [DataPortalAPI.InputPortType.RiverStream]: 'River Streams',
        [DataPortalAPI.InputPortType.PDWDataset]: 'Datasets', // Unnecessary because it is a repeated key, but added for consistency.

        [DataPortalAPI.DataResourceType.Conductor]: 'Airflow Environments',
        [DataPortalAPI.DataResourceType.DataSharing]: 'Data Sharing', // Not used
        [DataPortalAPI.DataResourceType.PDWRole]: 'PDW Roles',
        [DataPortalAPI.DataResourceType.Databricks]: 'Databricks', // Not used
        [DataPortalAPI.DataResourceType.DBT]: 'Dbt', // Not used
        [DataPortalAPI.DataResourceType.SnowflakeWarehouse]: 'Snowflake Warehouse',

        [DataPortalAPI.OutputPortType.PDWdataset]: 'Datasets',
        [DataPortalAPI.OutputPortType.LookerDashboards]: 'Looker HomePage',
    };

    return <>
        {Object
            .keys(groups)
            .map(objectType => {
                const cleanType = typeMap[objectType];

                return <div className={`stat stat-primary`} key={cleanType} style={{ marginBottom: 20 }}>
                    <h6>{cleanType}</h6>
                    <div className="stat-value" style={{ color: colors.shale, wordBreak: 'break-all' }}>
                        <ul>
                            {
                                groups[objectType].map(itemName => <li key={itemName} style={{ marginBottom: 5 }}>
                                    <span>{itemName}</span>
                                </li>)
                            }

                        </ul>
                    </div>
                </div>;

            })}
    </>;
};


export const DataProductComponentsSummary: React.FC<{
    inputPorts: {
        type: DataPortalAPI.InputPortType;
        name: string;
    }[];
    resources: {
        type: DataPortalAPI.DataResourceType;
        name: string;
    }[];
    outputPorts: {
        type: DataPortalAPI.OutputPortType;
        name: string;
    }[];
}> = ({ resources, outputPorts, inputPorts }) => {
    const lookerDashboards = useAppSelector((state) =>
        state.lookerDashboards.status === "loading"
            ? null
            : state.lookerDashboards.lookerDashboards)?.map(o => ({ name: o.resourceId, type: DataPortalAPI.OutputPortType.LookerDashboards }));
    const outputPortDatasetSlice = useAppSelector((state) => state.outputPorts);
    const outputPortDataset = outputPortDatasetSlice.outputPortCollectionState.status === "loading"
        ? null
        : outputPortDatasetSlice.outputPortCollectionState.data?.map(i => ({ name: i.resourceId, type: DataPortalAPI.OutputPortType.PDWdataset }));

    let finalOutputPorts = outputPorts;

    if (lookerDashboards) {
        finalOutputPorts = finalOutputPorts.filter(o => o.type !== DataPortalAPI.OutputPortType.LookerDashboards).concat(...lookerDashboards);
    }
    if (outputPortDataset) {
        finalOutputPorts = finalOutputPorts.filter(o => o.type !== DataPortalAPI.OutputPortType.PDWdataset).concat(...outputPortDataset);
    }

    const riverStreamDetails = useAppSelector((state) => state.riverStreams);
    const streams = riverStreamDetails.status === "loading"
        ? null // null because we don't want to empty the river stream array (below) when "streams" is loading.
        : riverStreamDetails.riverStreams?.map(i => ({ name: i.resourceId, type: DataPortalAPI.InputPortType.RiverStream }));
    const inputPortDatasetSlice = useAppSelector((state) => state.inputPortDataset);
    const inputPortDataset = inputPortDatasetSlice.status === "loading"
        ? null
        : inputPortDatasetSlice.inputPortDatasets?.map(i => ({ name: i.resourceId, type: DataPortalAPI.InputPortType.PDWDataset }));

    let finalInputPorts = inputPorts;
    if (streams) {
        finalInputPorts = finalInputPorts.filter(o => o.type !== DataPortalAPI.InputPortType.RiverStream).concat(...streams);
    }
    if (inputPortDataset) {
        finalInputPorts = finalInputPorts.filter(o => o.type !== DataPortalAPI.InputPortType.PDWDataset).concat(...inputPortDataset);
    }

    let finalResources = resources.filter(o => o.type !== DataPortalAPI.DataResourceType.Conductor);
    const conductorsState = useAppSelector((state) => state.minimalConductors);
    const conductorsDataProduct = conductorsState.status === "loading"
        ? null
        : conductorsState.conductors?.map(c => ({ name: c, type: DataPortalAPI.DataResourceType.Conductor }));
    if (conductorsDataProduct) finalResources = finalResources.concat(...conductorsDataProduct);

    return <div style={{ marginBottom: 120 }}>
        <div>
            <div className='row'>
                <div className='col-md-4'>
                    <small>Input ports&nbsp;
                        <Tooltip
                            tooltipInnerStyle={{ textAlign: 'left' }}
                            contents={<span>
                                Input ports feed Data Products with data from upstream operational systems (River streams, microservices, applications, etc.) or other data products.
                            </span>}
                        >
                            <IconInformationCircle weight='fill' color={colors.info.base} />
                        </Tooltip>
                    </small>
                    <hr />
                </div>

                <div className='col-md-4'>
                    <small>Resources&nbsp;
                        <Tooltip
                            tooltipInnerStyle={{ textAlign: 'left' }}
                            contents={
                                <span>
                                    Resources are infrastructural components, configurations and policies to build, deploy and run Data Product&apos;s code and generate output of the data product (e.g., internal datasets, access and metadata objects, compute resources).
                                </span>
                            }>
                            <IconInformationCircle weight='fill' color={colors.info.base} />
                        </Tooltip>
                    </small>
                    <hr />
                </div>

                <div className='col-md-4'>
                    <small>Output ports&nbsp;
                        <Tooltip
                            tooltipInnerStyle={{ textAlign: 'left' }}
                            contents={
                                <span>
                                    Output data ports provide data for consumption. These are the stable elements that will be exposed publicly once the Data Product is published.
                                </span>
                            }>
                            <IconInformationCircle weight='fill' color={colors.info.base} />
                        </Tooltip>
                    </small>
                    <hr />
                </div>
            </div>


            <div className='row'>
                <div className='col-md-4'>
                    {
                        finalInputPorts.length
                            ? <DataProductNode items={finalInputPorts} />
                            : riverStreamDetails.status === "loading"
                                ? <Spinner size='medium' />
                                : <span>No input ports.</span>
                    }
                </div>

                <div className='col-md-4'>
                    {
                        finalResources.length
                            ? <DataProductNode items={finalResources} />
                            : <span>No resources.</span>
                    }
                </div>

                <div className='col-md-4'>
                    {
                        finalOutputPorts.length
                            ? <DataProductNode items={finalOutputPorts} />
                            : <span>No output ports.</span>
                    }
                </div>
            </div>
        </div>
    </div >;
};
