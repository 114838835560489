import React from "react";
import UpdateCustomFieldValueModal from "./UpdateCustomFieldModal";
import { EditableConfiguration } from "./UpdateCustomFieldBase";

type Props = {
    customFieldId: string;
    displayName: string;
    value: string;
    fnOnSave: (value: string) => void;
    fnOnClose: () => void;
};
export const CustomFieldEditorFactory = (props: Props) => {

    switch (EditableConfiguration.get(props.displayName)?.type) {
        case "TextEditor":
        default:
            return (
                <UpdateCustomFieldValueModal
                    customFieldId={props.customFieldId}
                    displayName={props.displayName}
                    fnOnSave={props.fnOnSave}
                    fnOnClose={props.fnOnClose}
                    value={props.value}
                />
            );
    }
};
