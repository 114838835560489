import React from 'react';
import { deleteDataProduct } from "@cimpress-technology/data-portal-core/lib/clients/dataPortalApi";
import { Button, colors, Modal, Tooltip } from "@cimpress/react-components";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { renderError } from './Render';
import { IconBin } from '@cimpress-technology/react-streamline-icons/lib';
import { DataProduct, DeleteDataProductQueryParams } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { AuthContext } from '../../context/authContext';
import { useHistory } from 'react-router-dom';


export const DeleteDataProduct: React.FC<{
    dataProduct: DataProduct
}> = ({ dataProduct }) => {
    const { accessToken } = React.useContext(AuthContext);
    const history = useHistory();
    
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [error, setError] = useState(null as AxiosError | null);
    const [showModal, setShowModal] = useState(false);

    const isDisabled = dataProduct.published;

    if (!accessToken) {
        return null;
    }

    const deleteCurrentDataProduct = async () => {
        setIsUpdatingStatus(true);
        setError(null);
        try {
            const queryParams: DeleteDataProductQueryParams = {
                hardDelete: true
            };
            await deleteDataProduct(accessToken, dataProduct.dataProductId, queryParams);
            setShowModal(false);
            history.push('/dataproducts');
        } catch(error) {
            if (axios.isAxiosError(error)) {
                setError(error);
            }
            setIsUpdatingStatus(false);
        }
    };


    return <>
        <Tooltip contents={isDisabled ? 'Only un-published products can be deleted' : 'Delete Data Product'}>
            {
                isUpdatingStatus ? <Spinner size='small' /> :
                <IconBin
                    style={{ cursor: 'pointer', color: isDisabled ? colors.alloy : colors.danger.base, height: '17px'}}
                    onClick={() => { isDisabled ? null : setShowModal(true); setError(null);}}
                    weight='fill'
                />
            }
        </Tooltip>

        <Modal
            show={showModal}
            closeOnOutsideClick={true}
            status='danger'
            closeButton={true}
            onRequestHide={() => setShowModal(false)}
            footer={
                <>
                    <div className="pull-left">
                        <Button disabled={isUpdatingStatus} onClick={() => setShowModal(false)}>Cancel</Button>
                    </div>
                    <div className="pull-right">
                    
                            <Button
                                disabled={isUpdatingStatus}
                                variant='primary'
                                style={{marginLeft: '5px'}}
                                onClick={() => { deleteCurrentDataProduct(); }}
                            >
                                {
                                    isUpdatingStatus ? <Spinner size='small' /> : 'Confirm'
                                }
                            </Button>
                    </div>
                </>
            }
            title= {'Delete Data Product'}
        >
            <div style={{color: colors.cobalt.base}}>
                Are you sure you want to delete the Data Product <b>{dataProduct.dataProductName}</b>? This action can not be undone.
            </div>
            {
                error ? <div><br />{renderError(error.message, error)}</div> : null
            }
        </Modal>
    </>;
};