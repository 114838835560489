import React from 'react';

const Loading = (): JSX.Element => {
  return (
    <div className="loader">
        <span className="loader__element"></span>
        <span className="loader__element"></span>
        <span className="loader__element"></span>
    </div>
  );
};

export default Loading;