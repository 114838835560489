import React, { useCallback, useEffect, useState } from "react";
import { Button, NavTab, NavTabItem, Snackbar } from "@cimpress/react-components";
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { AuthContext } from "../../../context/authContext";
import { ReportAnIssueModule } from "../../issueTracker/IssueTrackerWrapper";
import { useHistory, useParams } from "react-router-dom";
import ConsumableDatasets from "../resourcesPdwDatasets/components/ConsumableDatasets";
import MetadataColumns from "../resourcesPdwDatasets/shared/MetadataColumns";
import MetadataDescription from "../resourcesPdwDatasets/shared/MetadataDescription";
import OpenInSnowflakeButton from "../resourcesPdwDatasets/shared/OpenInSnowflakeButton";
import OpenInDataDiscoveryButton from "../resourcesPdwDatasets/shared/OpenInDataDiscoveryButton";
import {
    clearDatasetError,
    clearDatasetStatus,
} from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/pdwDatasetsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { PdwDatasetGetCollectionItem, PdwDatasetGetItem } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalAPI/dataProductResources";
import { DatasetIdToMetadataState, isDataDiscoveryDatasetMetadata, isErrorMetadata, isLoadingMetadata } from "../../../hooks/useDataDiscoveryMetadata";
import ErrorDetails from "@cimpress-technology/data-portal-core/lib/components/ErrorDetails";
import { DatasetIdFormatType, getAccountFromCluster, translateDatasetId } from "../../../utils/dataset/datasetId";
import { Tabs } from "../DataProductDetailsNav";

type TabControlType = {
    description: boolean;
    columns: boolean;
    dataIssues: boolean;
    dataContracts: boolean;
    consumableDatasets: boolean;
};
const buildSingleActiveTab = (
    tabName: keyof TabControlType
): TabControlType => {
    const currentControl: TabControlType = {
        description: false,
        columns: false,
        dataIssues: false,
        dataContracts: false,
        consumableDatasets: false,
    };
    currentControl[tabName] = true;
    return currentControl;
};

type Props = {
    isSameBusiness: boolean;
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
    dataset: PdwDatasetGetItem | PdwDatasetGetCollectionItem;
    getMetadata: (datasetId: string) => Promise<void>;
    datasetIdToMetadataState: DatasetIdToMetadataState;
}
export const OutputPortTabItemContent = (props: Props) => {
    const dispatch = useAppDispatch();
    const { datasetsErrors, datasetsStatus } = useAppSelector(
        (state) => state.outputPorts
    );
    const history = useHistory();
    const [tabControl, setTabControl] = useState<TabControlType>(
        buildSingleActiveTab("description")
    );
    const { dataProductId } = useParams<{ dataProductId: string }>();
    const { accessToken } = React.useContext(AuthContext);
    const { getMetadata, dataset: resource, datasetIdToMetadataState: metadataDictionary } = props;
    const datasetMetadata = metadataDictionary[translateDatasetId(resource.resourceId, DatasetIdFormatType.DataDiscoveryId)];

    const handleLoadMetadata = useCallback((resourceId: string) => {
        getMetadata(resourceId);
    }, [getMetadata]);

    useEffect(() => {
        handleLoadMetadata(resource.resourceId);
    }, [resource, handleLoadMetadata]);

    if (datasetMetadata == null) return null;
    if (isLoadingMetadata(datasetMetadata)) return <div><h3>Loading Metadata</h3> <Spinner size="medium" /></div>;
    const TabControl = (
        <NavTab vertical={false}>
            <NavTabItem active={tabControl.description}>
                <button
                    onClick={() => {
                        setTabControl(buildSingleActiveTab("description"));
                    }}
                    style={{ marginLeft: 0 }}
                >
                    Description
                </button>
            </NavTabItem>
            <NavTabItem active={tabControl.columns}>
                <button
                    onClick={() => {
                        setTabControl(buildSingleActiveTab("columns"));
                    }}
                >
                    Columns
                </button>
            </NavTabItem>
            <NavTabItem active={tabControl.dataIssues}>
                <button
                    onClick={() => {
                        setTabControl(buildSingleActiveTab("dataIssues"));
                    }}
                >
                    Data Issues
                </button>
            </NavTabItem>
            <NavTabItem active={tabControl.consumableDatasets}>
                <button
                    onClick={() => {
                        setTabControl(
                            buildSingleActiveTab("consumableDatasets")
                        );
                    }}
                >
                    Consumable Datasets (Data Contracts)
                </button>
            </NavTabItem>
        </NavTab>
    );

    const CreateDataSharesButton =
            <Button
                size='sm'
                onClick={() => history.push(`/dataproducts/${dataProductId}?tab=${Tabs.Resources}&subtab=${DataPortalAPI.DataResourceType.DataSharing}`)}
            >
                Create Data Share
            </Button>;

    return (
        <div className="row">
            <Snackbar
                show={Object.values(datasetsStatus[props.dataset.resourceId] || {}).some(status => status === 'failed') === true}
                status="danger"
                delay={10000}
                onHideSnackbar={() => {
                    dispatch(clearDatasetError({ resourceId: props.dataset.resourceId }));
                    dispatch(clearDatasetStatus({ resourceId: props.dataset.resourceId }));
                }}
            >
                {datasetsErrors[props.dataset.resourceId] != null ? (
                    <p>
                        {parseUnknownErrorTypeToErrorMessage(
                            datasetsErrors[props.dataset.resourceId]
                        )}
                    </p>
                ) : (
                    <p>Unknown Error</p>
                )}
            </Snackbar>
            <div className="col-md-12">
                <b>{props.dataset.resourceId}</b>
            </div>
            <div
                className="col-md-12 card-block"
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <div className="" style={{ display: "inline-block" }}>
                    <div style={{ display: "flex", gap: 8 }}>
                        {isDataDiscoveryDatasetMetadata(datasetMetadata) && (
                            <OpenInDataDiscoveryButton
                                isSameBusiness={props.isSameBusiness}
                                datasetAccount={getAccountFromCluster(datasetMetadata.cluster)}
                                database={datasetMetadata.database}
                                schema={datasetMetadata.schema}
                                name={datasetMetadata.name}
                            />
                        )}
                        {props.hasDataProductReadAccess === true && isDataDiscoveryDatasetMetadata(datasetMetadata) && (
                            <OpenInSnowflakeButton
                                datasetAccount={getAccountFromCluster(datasetMetadata.cluster)}
                                database={datasetMetadata.database}
                                schema={datasetMetadata.schema}
                                name={datasetMetadata.name}
                                isView={datasetMetadata.isView}
                            />
                        )}

                        { props.hasDataProductAdminAccess && isDataDiscoveryDatasetMetadata(datasetMetadata) ? CreateDataSharesButton : null }

                    </div>
                </div>
            </div>

            <div className="col-md-12" style={{ marginTop: "10px" }}>
                {TabControl}
            </div>
            <div className="col-md-12">
                <div
                    style={{
                        display: !tabControl.description ? "none" : undefined,
                    }}
                >
                    <MetadataDescription description={(isDataDiscoveryDatasetMetadata(datasetMetadata) ? datasetMetadata.description : '') || ""} />
                </div>
                <div
                    style={{
                        display: !tabControl.columns ? "none" : undefined,
                    }}
                >
                    <MetadataColumns columns={(isDataDiscoveryDatasetMetadata(datasetMetadata) ? datasetMetadata.columns : []) || []} />
                </div>
                <span
                    style={{
                        display: !tabControl.dataIssues ? "none" : undefined,
                    }}
                >
                    {isDataDiscoveryDatasetMetadata(datasetMetadata) && (
                        <ReportAnIssueModule
                            dataProductId={dataProductId}
                            dataset={datasetMetadata}
                            history={history}
                        />
                    )}
                </span>
                {accessToken && tabControl.consumableDatasets === true && isDataDiscoveryDatasetMetadata(datasetMetadata) && (
                    <ConsumableDatasets
                        accessToken={accessToken}
                        metadata={datasetMetadata}
                        dataset={props.dataset}
                        dataProductId={dataProductId}
                        source="outputPorts"
                    />
                )}
            </div>
            {isErrorMetadata(datasetMetadata) && <div className="col-md-12"><ErrorDetails error={datasetMetadata.error} retryFunction={() => getMetadata(resource.resourceId)} /></div>}
        </div>
    );
};
