import React from 'react';
import { DataPortalAPI, Interfaces } from "@cimpress-technology/data-portal-core";
import { DatasetsDetails } from '../../shared/datasets/DatasetsDetails';
import {LookerHomePageDetails} from '../../shared/lookerHomePage/LookerHomePageDetails';


export const OutputPortsDetailsSection: React.FC<{
    hasDataProductReadAccess: boolean,
    hasDataProductAdminAccess: boolean,
    adminCoamGroupHook: Interfaces.DataPortalAPI.CoamGroupHook,
    readCoamGroupHook: Interfaces.DataPortalAPI.CoamGroupHook
    selectedSubTab: DataPortalAPI.OutputPortType
    dataProduct: DataPortalAPI.DataProduct,
    domain: DataPortalAPI.Domain
 }> = ({ dataProduct, hasDataProductReadAccess, hasDataProductAdminAccess, domain, selectedSubTab, adminCoamGroupHook, readCoamGroupHook }) => {

    return <div>
        { selectedSubTab === DataPortalAPI.OutputPortType.PDWdataset ?
            <DatasetsDetails  adminCoamGroupHook={adminCoamGroupHook} readCoamGroupHook={readCoamGroupHook} hasDataProductAdminAccess={hasDataProductAdminAccess} hasDataProductReadAccess={hasDataProductReadAccess} dataProduct={dataProduct} domain={domain} />: null
        }
        { selectedSubTab === DataPortalAPI.OutputPortType.LookerDashboards ?
            <LookerHomePageDetails allowEditSwitch={hasDataProductReadAccess} dataProductId={dataProduct.dataProductId} isPublished={dataProduct.published} />: null
        }
    </div>;
};