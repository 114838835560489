import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { getRiverStreams } from "@cimpress-technology/data-portal-core/lib/features/inputPorts/common";
import { cleanRiverStreams, cleanOperationStatusAndErrors } from "@cimpress-technology/data-portal-core/lib/features/inputPorts/riverStreamsSlice";

// Callbacks memoize a function. Then, when we use this function inside `useEffect`, it won't be triggered on each re-render because the memoized function will persist.
// It is also a good way to abstract functionality

type callbackType = () => void;
type memoizedFn = (token: string | undefined, dpId: string) => callbackType;
export const useMemoizedLoaderForRiverStreams: memoizedFn = (accessToken, dataProductId) => {
    const dispatch = useAppDispatch();
    const riverStreamsState = useAppSelector(state => state.riverStreams);
    const fetchRiverStreams = useCallback(() => {
        if (accessToken && dataProductId && (riverStreamsState.riverStreams === null || riverStreamsState.dataProductId === null || riverStreamsState.dataProductId !== dataProductId)) {
            // Only fetch when the River Streams value is null (not previously rendered) OR when entering a into a Data Product for the first time OR when we have changed Data Product            
            dispatch(getRiverStreams({ accessToken: accessToken, dataProductId: dataProductId }));
        } 
    }, [dispatch, accessToken, dataProductId, riverStreamsState.dataProductId, riverStreamsState.riverStreams]);
    return fetchRiverStreams;
};

type memoizedClean = (currentDpId: string) => callbackType;
export const useMemoizedCleanErrorsForRiverStreams: memoizedClean = (dataProductId) => {
    const dispatch = useAppDispatch();
    const riverStreamsState = useAppSelector(state => state.riverStreams);
    const clean = useCallback(() => {
        if (riverStreamsState.dataProductId !== dataProductId)            
            dispatch(cleanOperationStatusAndErrors()); // Clean Errors and Status for River Streams when we enter a different Data Product
    }, [dispatch, riverStreamsState.dataProductId, dataProductId]);
    return clean;
};

// The user might switch quickly between components, while an async is still running. 
// This function makes use that the result from the async is cleaned in the component where we use this function.
export const useMemoizedKeepItCleanForRiverStreams = ():callbackType => {
    const dispatch = useAppDispatch();
    const riverStreamsState = useAppSelector(state => state.riverStreams);
    const clean = useCallback(() => {
        if (riverStreamsState.riverStreams !== null) dispatch(cleanRiverStreams());
    }, [dispatch, riverStreamsState.riverStreams]);
    return clean;
};