import React, { useEffect, useState } from 'react';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { Clients, Hooks } from '@cimpress-technology/data-portal-core';
import { AuthContext } from '../../../../context/authContext';

type Props = { dataProductId?: string, domainId?: string, dataProductName?: string, published?: boolean };

const Title = (props: Props): JSX.Element => {

    const loading = !props.dataProductName || !props.dataProductId || !props.domainId;
    const { accessToken, profile } = React.useContext(AuthContext);
    const userCanonicalId = profile?.["https://claims.cimpress.io/canonical_id"];
    const { permissions } = Hooks.useCoamPermissions(accessToken, userCanonicalId);
    const [ hasEditPermission, setHasEditPermission ] = useState<boolean>(false);

    useEffect(() => {
        if (accessToken && props.dataProductId && props.domainId && permissions) {
            const hasGovern = Clients.Coam.hasPermission(permissions, Clients.Coam.ResourceType.DataPortalDomain, props.domainId, Clients.Coam.Permission.DataPortalDomainGovern);
            const hasEdit = Clients.Coam.hasPermission(permissions, Clients.Coam.ResourceType.DataPortalProduct, props.dataProductId, Clients.Coam.Permission.DataPortalProductEdit);
            setHasEditPermission(hasGovern || hasEdit);
        }
    }, [props.dataProductId, props.domainId, accessToken, permissions]);


    if (loading) {
        return (
            <section className="summary__title">
                <Spinner size='small' />
            </section>
        );
    }

    return (
        <section className='summary__title'>
            <div className='summary__title__text'>{props.dataProductName}</div>
            <div className='summary__title__stars'>
                <div className="dp-stars">
                    {/* Temporary delete */}
                    {/* <span className="dp-stars__star"><IconRatingStar weight={"fill"} color={colors.warning.base} /></span>
                    <span className="dp-stars__star"><IconRatingStar weight={"fill"} color={colors.warning.base} /></span>
                    <span className="dp-stars__star"><IconRatingStar weight={"fill"} color={colors.warning.base} /></span>
                    <span className="dp-stars__star"><IconRatingStar weight={"fill"} color={colors.warning.base} /></span>
                    <span className="dp-stars__star"><IconRatingStar /></span> */}
                </div>
            </div>
            {hasEditPermission &&
                <a className="dp-btn-outline summary__title__edit" href={`/dataproducts/${props.dataProductId}`}>
                     <i className="fa fa-pencil"></i>
                </a>
            }
        </section>
    );
};

export default Title;