import React, { useEffect, useMemo } from "react";
import { AuthContext } from "../../../context/authContext";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import {
    DataProductListItem,
} from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { fetchDataProducts } from "@cimpress-technology/data-portal-core/lib/features/dataProducts/dataProductsSlice";
import Discovery from "./Discovery";
import pattern_1 from "../../../assets/pattern_1.png";
import pattern_2 from "../../../assets/pattern_2.png";
import pattern_4 from "../../../assets/pattern_4.png";

const RECENTS_STORAGE = "recents";
const BG_PATTERN_STORAGE = "currentPatern";
const BG_OPTIONS = [pattern_1, pattern_2, pattern_4];

const DiscoveryLanding = (): JSX.Element => {
    const { accessToken, profile } = React.useContext(AuthContext);
    const dispatch = useAppDispatch();
    const { dataProductList } = useAppSelector((state) => state.dataProducts);
    const userName = profile?.given_name;
    const userAccountId = profile?.["https://claims.cimpress.io/account"];

    const recentlyViewed: DataProductListItem[] | null = useMemo(() => {
        if (dataProductList && dataProductList.length > 0) {
            const recents = localStorage.getItem(RECENTS_STORAGE)?.split(",");
            if (recents) {
                const orderedResults: DataProductListItem[] = [];
                Array.from(new Set(recents))
                    .splice(0, 6)
                    .forEach((dpId) => {
                        const found = dataProductList.find(
                            (item) => item.dataProductId === dpId
                        );
                        if (found) orderedResults.push(found);
                    });
                return orderedResults;
            }
        }
        return null;
    }, [dataProductList]);

    useEffect(() => {
        const fetchPromise = accessToken
            ? dispatch(
                  fetchDataProducts({
                      accessToken: accessToken,
                      onlyOwned: false,
                  })
              )
            : undefined;
        return () => {
            fetchPromise && fetchPromise.abort();
        };
    }, [accessToken, dispatch]);

    return (
        <div className="discovery-container">
            <Discovery
                accessToken={accessToken}
                userName={userName}
                userAccountId={userAccountId}
                recentlyViewed={recentlyViewed}
                openThisPath="/discover/"
                storeVisitIn={RECENTS_STORAGE}
                TopLeftAddon={null}
                backgroundSettings={{
                    storageName: BG_PATTERN_STORAGE,
                    options: BG_OPTIONS,
                }}
                showPublishedMark={false}
            />
        </div>
    );
};

export default DiscoveryLanding;
