import { EmbeddedError } from "@cimpress-technology/data-portal-core/lib/interfaces/common";
import { DataDiscoveryDatasetMetadata } from "@cimpress-technology/data-portal-core/lib/interfaces/dataDiscovery";
import { LookerDashboardsMetadataEmbed } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";


export const pdwDatasetEmbedIsNotError = (input: DataDiscoveryDatasetMetadata | EmbeddedError): input is DataDiscoveryDatasetMetadata => {
    return ((input as EmbeddedError).error === undefined && (input as DataDiscoveryDatasetMetadata).id !== undefined);
};

export const lookerEmbedIsNotError = (input: LookerDashboardsMetadataEmbed | EmbeddedError): input is LookerDashboardsMetadataEmbed => {
    return ((input as EmbeddedError).error === undefined && (input as LookerDashboardsMetadataEmbed).title !== undefined);
};
