import React, { useEffect } from "react";
import IconPencilAlt from "@cimpress-technology/react-streamline-icons/lib/IconPencilAlt";
import { IconBin } from '@cimpress-technology/react-streamline-icons/lib';
import {
    Button,
    InlineEdit,
    Modal,
    Snackbar,
    colors,
} from "@cimpress/react-components";
import { OperationStatus } from "../../interfaces/common";
import axios, { AxiosError } from "axios";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { Spinner } from "@cimpress/react-components/lib/shapes";

type AllowedFns = (value: { url: string, title: string }) => Promise<void>;
type Props = {
    updateFunction: AllowedFns;
    hasPermission: boolean;
    url: {
        url: string;
        title: string;
    };
    allowDelete: boolean;
    deleteFunction?: () => Promise<void>;
};

const URLEditor = (props: Props) => {
    const [status, setStatus] = React.useState<OperationStatus>('idle');
    const [error, setError] = React.useState<Error | AxiosError | null>(null);
    const [showModal, setShowModal] = React.useState(false);
    const [url, setUrl] = React.useState(props.url);

    const validateUrlisUriFormat = (url: string) => {
        try {
            new URL(url);
            return null;
        } catch (e) {
            return 'Invalid URL';
        }
    };

    useEffect(() => {
        setUrl(props.url);
    }, [props.url, error]);

    const handleError = (e: unknown) => {
        if (axios.isAxiosError(e)) {
            setError(e.response?.data || e);
        } else {
            setError(new Error(`Failed to delete the URL: ${parseUnknownErrorTypeToErrorMessage(e)}`));
        }
        setStatus('error');
    };

    const handleUpdate = async () => {
        if (status === 'loading') {
            return;
        }
        setStatus('loading');
        try {
            setError(null);
            await props.updateFunction(url);
            setStatus('success');
        } catch (e) {
            handleError(e);
        }
    };

    const handleDelete = async () => {
        if (status === 'loading') {
            return;
        }
        if (!props.deleteFunction) {
            return;
        }
        setStatus('loading');
        try {
            setError(null);
            await props.deleteFunction();
            setStatus('success');
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <>
            <Snackbar
                show={error != null}
                status='danger'
                delay={10000}
                onHideSnackbar={() => { setError(null); setStatus('idle'); }}
            >
                {error && <p>{error.message}</p>}
            </Snackbar>
            <Snackbar
                show={status === 'success'}
                status='success'
                delay={5000}
                onHideSnackbar={() => setStatus('idle')}
            >
                <p>URL updated successfully.</p>
            </Snackbar>
            <Modal
                show={showModal}
                status="success"
                title="Edit URL"
                closeButton={true}
                closeOnOutsideClick={true}
                onRequestHide={() => setShowModal(false)}
                footer={
                    <div>
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                setUrl(props.url);
                                setShowModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                handleUpdate();
                                setShowModal(false);
                            }}
                        >
                            Save
                        </Button>
                    </div>
                }
            >
                <InlineEdit
                    name="url-href"
                    label="URL"
                    placeholder="https://example.com"
                    value={url.url}
                    onChange={(e) => setUrl({ ...url, url: e.target.value })}
                    validateInput={validateUrlisUriFormat}
                    required
                    />
                <br></br>
                <InlineEdit
                    name="url-text"
                    label="Title"
                    placeholder="Example"
                    value={url.title}
                    onChange={(e) => setUrl({ ...url, title: e.target.value })}
                    required
                    />

            </Modal>
            <div>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <a href={props.url.url} style={{ maxWidth: '90%', overflowWrap: 'break-word' }} target="_blank" rel="noreferrer">
                        {props.url.title}
                    </a>
                    {props.hasPermission && (
                        <IconPencilAlt
                            weight="fill"
                            style={{ cursor: "pointer", height: 18 }}
                            color={status === 'loading' ? colors.slate : colors.info.base}
                            onClick={() => setShowModal(true)}
                        />
                    )}

                    {props.allowDelete && props.hasPermission && (
                        <IconBin
                        style={{ cursor: 'pointer', color: status === 'loading' ? colors.slate : colors.danger.base, height: '17px'}}
                        onClick={() => { handleDelete(); }}
                        weight='fill'
                        />
                    )}
                    {status === 'loading' && <Spinner size='small' />}
                </div>
            </div>
        </>
    );
};

export default URLEditor;
