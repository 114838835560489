/** A single result item */
import React from "react";
import { colors } from "@cimpress/react-components";
import IconFileCode from "@cimpress-technology/react-streamline-icons/lib/IconFileCode";
import IconAnalyticsGraph from "@cimpress-technology/react-streamline-icons/lib/IconAnalyticsGraph";
import {
    OutputPort,
    OutputPortType,
} from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { Spinner } from "@cimpress/react-components/lib/shapes";

type ValidAuthorInfo = {
    name: string;
    label: string;
    picture: string;
};

type ErrorAuthorInfo = {
    error: string;
};

const isValidAuthorInfo = (
    o: ValidAuthorInfo | ErrorAuthorInfo | null | undefined
): o is ValidAuthorInfo => {
    if (o == null) return false;
    return (
        (o as ValidAuthorInfo).name != null &&
        (o as ValidAuthorInfo).label != null
    );
};

const isErrorAuthorInfo = (
    o: ValidAuthorInfo | ErrorAuthorInfo | null | undefined
): o is ErrorAuthorInfo => {
    if (o == null) return false;
    return (o as ErrorAuthorInfo).error != null;
};

const isAuthorInfoLoading = (
    o: ValidAuthorInfo | ErrorAuthorInfo | null | undefined
): boolean => {
    if (o == null) return true;
    return false;
};

const buildPublishedLabel = (published?: boolean) => {
    if (published == null) return null;
    return published ? (
        <span className="dp-label-published">PUBLISHED</span>
    ) : (
        <span className="dp-label-unpublished">UNPUBLISHED</span>
    );
};

type Props = {
    dataProductId: string;
    domainId: string;
    name: string;
    description: string;
    owner?: string;
    lastModified?: number;
    published?: boolean;
    authorInfo?: ValidAuthorInfo | ErrorAuthorInfo | null;
    outputPorts: OutputPort<OutputPortType>[];
    openThisPath: string;
    storeVisitIn: string;
    onClickTracking?(dataProductId: string, dataProductName: string): void;
};

/**
 *
 * @param props
 * @returns
 */
export const DataProductCard = (props: Props): JSX.Element => {
    const portTypes = new Set<OutputPortType>(
        props.outputPorts.map((o) => o.portType)
    );
    const isThereLooker = portTypes.has(OutputPortType.LookerDashboards);
    const isTherePDWDataset = portTypes.has(OutputPortType.PDWdataset);
    const storageHandler = (target: string) => {
        if (!target) return;
        const recents = localStorage.getItem(target)?.split(",");
        if (recents) {
            localStorage.setItem(
                target,
                [props.dataProductId]
                    .concat(
                        recents.filter((dpId) => dpId !== props.dataProductId)
                    )
                    .splice(0, 6)
                    .join(",")
            );
        } else {
            localStorage.setItem(target, `${props.dataProductId}`);
        }
    };
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        storageHandler(props.storeVisitIn);

        if (props.onClickTracking){
            props.onClickTracking(props.dataProductId, props.name);
        }

        window.open(
            `${props.openThisPath}${props.dataProductId}`,
            "_blank",
            "noreferrer"
        );
    };

    const mouseDownHandler = (e: React.MouseEvent<HTMLElement>) => {
        if (e.button == 1) {
            storageHandler(props.storeVisitIn);
        }
    };
    return (
        <div className="dp-card-discovery">
            <a
                target="_blank"
                rel="noreferrer"
                href={`${props.openThisPath}${props.dataProductId}`}
                onClick={handleClick}
                onMouseDown={mouseDownHandler}
            >
                <div className="dp-card-discovery__header">
                    <span>{props.name}</span>
                    {buildPublishedLabel(props.published)}
                </div>
                <div className="dp-card-discovery__body">
                    <p style={{ color: colors.shale }}>{props.description}</p>
                </div>

                <div className="dp-card-discovery__footer">
                    <div className="dp-card-discovery__footer__author">
                        <div>
                            {isAuthorInfoLoading(props.authorInfo) && (
                                <Spinner size="small" />
                            )}
                            {isValidAuthorInfo(props.authorInfo) && (
                                <>
                                    <img
                                        src={
                                            props.authorInfo.picture
                                        }
                                        alt=""
                                    />
                                    <span>
                                        <span>{props.authorInfo.name}</span>
                                        <span>{props.authorInfo.label}</span>
                                    </span>
                                </>
                            )}
                            {isErrorAuthorInfo(props.authorInfo) && (
                                <small className="text-muted">
                                    {props.authorInfo.error}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="dp-card-discovery__footer__info">
                        <div className="dp-stars">
                            {/* Temporary Delete */}
                            {/* <span className="dp-stars__star"><IconRatingStar weight={"fill"} color={colors.warning.base} /></span>
                            <span className="dp-stars__star"><IconRatingStar weight={"fill"} color={colors.warning.base} /></span>
                            <span className="dp-stars__star"><IconRatingStar weight={"fill"} color={colors.warning.base} /></span>
                            <span className="dp-stars__star"><IconRatingStar weight={"fill"} color={colors.warning.base} /></span>
                            <span className="dp-stars__star"><IconRatingStar /></span> */}
                        </div>
                        <div className="dp-card-discovery__footer__ports">
                            {isThereLooker && (
                                <span className="icon-looker" title="Dashboard">
                                    <IconAnalyticsGraph />
                                </span>
                            )}
                            {isTherePDWDataset && (
                                <span className="icon-dataset" title="Dataset">
                                    <IconFileCode />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};
