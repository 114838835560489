import React, { useState } from 'react';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { DatasetsDetailsNavigation } from './DatasetsDetailsNavigation';
import { AuthContext } from '../../../context/authContext';
import { Alert } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { useAppSelector } from '../../../store/storeHooks';
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";


export const DatasetsDetailsContent: React.FC<{
    dataProduct: DataPortalAPI.DataProduct,
    hasDataProductReadAccess: boolean,
    hasDataProductAdminAccess: boolean,
    domain: DataPortalAPI.Domain,
}> = ({ domain, hasDataProductReadAccess, hasDataProductAdminAccess}) => {

    const { outputPortCollectionState } = useAppSelector(
        (state) => state.outputPorts
    );
    const { accessToken, profile } = React.useContext(AuthContext);
    const [isSameBusiness] = useState(domain.accountId === profile?.['https://claims.cimpress.io/account']);

    return <div>
            {outputPortCollectionState.status === "failed" ? (
                <Alert
                    status="danger"
                    message={parseUnknownErrorTypeToErrorMessage(outputPortCollectionState.error)}
                ></Alert>
            ) : null}
            {outputPortCollectionState.status === "loading" ? (
                <Alert
                    status="info"
                    message={
                        <div style={{ display: "flex" }}>
                            Fetching Latest details...
                            <Spinner size="small" />
                        </div>
                    }
                ></Alert>
            ) : null}
            {accessToken != null && (outputPortCollectionState.status === "succeeded" || outputPortCollectionState.status === "idle") && (
                <DatasetsDetailsNavigation
                    accessToken={accessToken}
                    isSameBusiness={isSameBusiness}
                    hasDataProductReadAccess={hasDataProductReadAccess}
                    hasDataProductAdminAccess={hasDataProductAdminAccess}
                />
            )}

            </div>;
};
