import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { getInputPortsDatasets } from "@cimpress-technology/data-portal-core/lib/features/inputPorts/common";
import { cleanInputPortDatasets } from "@cimpress-technology/data-portal-core/lib/features/inputPorts/inputPortDatasetSlice";

// Callbacks memoize a function. Then, when we use this function inside `useEffect`, it won't be triggered on each re-render because the memoized function will persist.
// It is also a good way to abstract functionality

type callbackType = () => void;
type memoizedFn = (token: string | undefined, dpId: string) => callbackType;
export const useMemoizedLoaderForInputPortDatasets: memoizedFn = (accessToken, dataProductId) => {
    const dispatch = useAppDispatch();
    const inputPortDatasetState = useAppSelector(state => state.inputPortDataset);
    const fetchInputPortDatasets = useCallback(() => {
        if (accessToken && dataProductId && (inputPortDatasetState.inputPortDatasets === null || inputPortDatasetState.dataProductId === null || inputPortDatasetState.dataProductId !== dataProductId)) {
            // Only fetch when the River Streams value is null (not previously rendered) OR when entering a into a Data Product for the first time OR when we have changed Data Product            
            dispatch(getInputPortsDatasets({ accessToken: accessToken, dataProductId: dataProductId }));
        }
    }, [dispatch, accessToken, dataProductId, inputPortDatasetState.dataProductId, inputPortDatasetState.inputPortDatasets]);
    return fetchInputPortDatasets;
};

type memoizedClean = (currentDpId: string) => callbackType;
export const useMemoizedCleanErrorsForInputPortDatasets: memoizedClean = (dataProductId) => {
    const dispatch = useAppDispatch();
    const inputPortDatasetState = useAppSelector(state => state.inputPortDataset);
    const clean = useCallback(() => {
        if (inputPortDatasetState.dataProductId !== dataProductId)
            dispatch(cleanInputPortDatasets()); // Clean Errors and Status for River Streams when we enter a different Data Product
    }, [dispatch, inputPortDatasetState.dataProductId, dataProductId]);
    return clean;
};

// The user might switch quickly between components, while an async is still running. 
// This function makes use that the result from the async is cleaned in the component where we use this function.
export const useMemoizedKeepItCleanForInputPortDatasets = (): callbackType => {
    const dispatch = useAppDispatch();
    const inputPortDatasetState = useAppSelector(state => state.inputPortDataset);
    const clean = useCallback(() => {
        if (inputPortDatasetState.inputPortDatasets !== null) dispatch(cleanInputPortDatasets());
    }, [dispatch, inputPortDatasetState.inputPortDatasets]);
    return clean;
};