import React from 'react';
import Button from '@cimpress/react-components/lib/Button';
import { transferDatasetOwnership } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalApi';
import { useAppSelector } from '../../../../store/storeHooks';
import { PDWRoleType } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import axios, { AxiosError } from 'axios';
import { Modal, Snackbar } from '@cimpress/react-components';
import { parseUnknownErrorTypeToErrorMessage } from '@cimpress-technology/data-portal-core/lib/features/utils';
import { OperationStatus } from '../../../../interfaces/common';

type Props = {
    accessToken: string,
    dataProductId: string,
    resourceId: string,
}

const TransferOwnershipButton = (props: Props) => {
    const [status, setStatus] = React.useState<OperationStatus>('idle');
    const [error, setError] = React.useState<Error | AxiosError | null>(null);
    const [showModal, setShowModal] = React.useState(false);
    const adminPdwRole = (useAppSelector((state) => state.resourcePdwRoles).pdwRoles || []).find(p => p.properties.pdwRoleType === PDWRoleType.Admin);
    const transferOwnership = async () => {
        setStatus('loading');
        try {
            setError(null);
            if (!adminPdwRole) {
                throw new Error('Admin role not found');
            }
            await transferDatasetOwnership(props.accessToken, props.dataProductId, props.resourceId, adminPdwRole.resourceId);
            setStatus('success');
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setError(e.response?.data || e);
            } else {
                setError(new Error(`Failed to transfer ownership: ${parseUnknownErrorTypeToErrorMessage(e)}`));
            }
            setStatus('error');
        }
    };
    return (
        <>
            <Snackbar
                show={error != null}
                status='danger'
                delay={10000}
                onHideSnackbar={() => { setError(null); setStatus('idle'); }}
            >
                {error && <p>{error.message}</p>}
            </Snackbar>
            <Snackbar
                show={status === 'success'}
                status='success'
                delay={5000}
                onHideSnackbar={() => setStatus('idle')}
            >
                <p>Ownership transferred successfully.</p>
            </Snackbar>
            <Modal
                title="Transfer Ownership"
                show={showModal}
                onRequestHide={() => setShowModal(false)}
                closeOnOutsideClick={true}
                footer={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button size="sm" disabled={status === 'loading'} onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                        <Button size="sm" variant='primary' disabled={status === 'loading'} onClick={async () => {
                            await transferOwnership();
                            setShowModal(false);
                        }}>
                            Yes, transfer.
                        </Button>
                    </div>
                }
            >
                <p>You are about to transfer the ownership of the <i>PDW Object</i> <b>{props.resourceId}</b> to the admin role <b>{adminPdwRole?.resourceId}</b></p>
                <p>We assume you are aware of the consequences of this change.</p>
                <p>Are you sure you want to proceed?</p>
                <p className='text-muted'><small>Note: You might revert these changes through the Data Portal API or PDW Access API.</small></p>
                <p><small>Note: Metadata changes might take up to one day to be reflected in Data Discovery.</small></p>
            </Modal>
            <Button
                size='sm'
                onClick={() => setShowModal(true)}
                variant='primary'
            >
                Transfer Ownership
            </Button>
        </>
    );
};

export default TransferOwnershipButton;