import React from 'react';
import { Spinner, SpinnerProps } from '@cimpress/react-components/lib/shapes';
import { ErrorDetails } from './ErrorDetails';

export const renderLoading = (message: string | undefined, center?: boolean, size?: SpinnerProps["size"]): React.ReactElement => {
    return <div className={center ? 'centerText' : ''}>
        <Spinner size={size} />
        <span>{message}...</span>
    </div>;
};

export const renderError = (message: string | undefined, error?: Error | null, reload?: (() => void)): React.ReactElement => {
    return <div>
        <ErrorDetails title={message} error={error || null} retryFunction={reload} />
    </div>;
};