import React, { useEffect, useState } from 'react';
import IconBookOpenBookmark from '@cimpress-technology/react-streamline-icons/lib/IconBookOpenBookmark';
import IconFileCode from '@cimpress-technology/react-streamline-icons/lib/IconFileCode';
import IconAnalyticsGraph from '@cimpress-technology/react-streamline-icons/lib/IconAnalyticsGraph';
import IconArrowDownAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowDownAlt';
import { OutputPort, OutputPortComponentEmbedded, OutputPortType, OutputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { Issue, IssueStatus } from "@cimpress-technology/data-issue-tracker-package/lib/interfaces/dataIssueTrackerApi";
import { getDatasetIssues } from "@cimpress-technology/data-issue-tracker-package/lib/clients/dataIssueTrackerApi";
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { Clients } from '@cimpress-technology/data-portal-core';
import { colors, Drawer, Table } from '@cimpress/react-components';
import { buildDataDiscoveryURL, buildSlackURL, buildSnowflakeURL } from '../utils';
import { lookerEmbedIsNotError, pdwDatasetEmbedIsNotError } from '../../../../utils/embed';

const LookerIcon = <span className='icon-looker-medium'><IconAnalyticsGraph /></span>;
const DatasetIcon = <span className='icon-dataset-medium'><IconFileCode /></span>;
const outputPortTypeMap = new Map<OutputPortType, string>([[OutputPortType.LookerDashboards, 'Interactive Dashboard'], [OutputPortType.PDWdataset, 'Dataset']]);
const outputPortIconMap = new Map<OutputPortType, JSX.Element>([[OutputPortType.LookerDashboards, LookerIcon], [OutputPortType.PDWdataset, DatasetIcon]]);

const buildSlackChannelList = (slackChannels: string[] | undefined, userAccountId: string | null) => {
    if (!slackChannels) return <span className='text-muted'>--</span>;
    return (<>{slackChannels.map(channel => <a key={channel} href={buildSlackURL(channel, userAccountId)} target="_blank" rel="noreferrer">{channel}</a>)}</>);
};

const buildOwnersList = (owners: string[] | undefined) => {
    if (!owners) return <span className='text-muted'>--</span>;
    return (<>{owners.map(owner => <a key={owner} href={`mailto:${owner}`}>{owner}</a>)}</>);
};

type Props = {
    account: {
        loading: boolean,
        name: string | null,
    };
    accessToken?: string;
    dataProductId?: string;
    outputPorts?: OutputPort<OutputPortType>[];
};

const Resources = (props: Props): JSX.Element => {
    const [ lookerDashboards, setLookerDashboards ] = useState<OutputPortComponentEmbedded<OutputPortType.LookerDashboards>[]>();
    const [ PDWDatasets, setPDWDatasets ] = useState<OutputPortComponentEmbedded<OutputPortType.PDWdataset>[]>();
    const [ selectedPDWDataset, setSelectedPDWDataset ] = useState<OutputPortComponentEmbedded<OutputPortType.PDWdataset>>();
    const [ issues, setIssues ] = useState<Issue[]>();
    const loading = !props.dataProductId || !props.accessToken || !lookerDashboards || !PDWDatasets;
    const ports = props.outputPorts || [];


    useEffect(() => {
        const getPDWDataset = async () => {
            if (props.accessToken && props.dataProductId && !PDWDatasets) {
                const pdwData = await Clients.DataPortalAPI.getOutputPorts(props.accessToken, props.dataProductId, OutputPortTypePath.PdwDatasets);
                setPDWDatasets(pdwData as OutputPortComponentEmbedded<OutputPortType.PDWdataset>[] || []);
            }
        };
        getPDWDataset();
    }, [props.accessToken, props.dataProductId, PDWDatasets]);

    useEffect(() => {
        const getLookerDashboards = async () => {
            if (props.accessToken && props.dataProductId && !lookerDashboards) {
                const lookerData = await Clients.DataPortalAPI.getOutputPorts(props.accessToken, props.dataProductId, OutputPortTypePath.LookerDashboards);
                setLookerDashboards(lookerData as OutputPortComponentEmbedded<OutputPortType.LookerDashboards>[] || []);
            }
        };
        getLookerDashboards();
    }, [props.accessToken, props.dataProductId, lookerDashboards]);

    useEffect(() => {
        const getIssues = async () => {
            if (!selectedPDWDataset) {
                setIssues([]);
                return;
            }
            if (props.accessToken && props.dataProductId && selectedPDWDataset) {
                try {
                    const issues = await getDatasetIssues(props.accessToken, props.dataProductId, selectedPDWDataset.resourceId);
                    setIssues(issues.filter(o => o.status == IssueStatus.Created || o.status == 0));
                } catch (error) {
                    setIssues([]);
                }
            }
        };
        getIssues();
    }, [selectedPDWDataset, props.accessToken, props.dataProductId]);

    const openIssuesColumnns = [
        { Header: 'Title', accessor: "title", sortable: true },
        { Header: 'Last Updated', accessor: "updatedAt", sortable: true }
    ];

    return (
        <div className="dp-card summary__product-resources">
            <div className="summary__card__header">
                <span className='summary__card__header__icon'><IconBookOpenBookmark /></span>
                <span className='summary__card__header__text'>Product Resources</span>
            </div>
            <div className="summary__card__body">
                <div className="summary__resources__content">
                    {loading && <Spinner size='small' />}
                    {!loading && lookerDashboards?.map(d =>
                         <div key={d.resourceId}>
                            <div>{outputPortIconMap.get(OutputPortType.LookerDashboards)}</div>
                            <div>
                                <span>
                                    <a href={d.resourceId} target="_blank" rel="noreferrer">
                                        {lookerEmbedIsNotError(d._embedded) ? d._embedded.title: "Error!"}
                                    </a>
                                </span>
                                <span>{outputPortTypeMap.get(OutputPortType.LookerDashboards)}</span>
                            </div>
                        </div>
                    )}
                    {!loading && PDWDatasets?.map(r =>
                         <div key={r.resourceId}>
                            <div>{outputPortIconMap.get(OutputPortType.PDWdataset)}</div>
                            <div>
                                <span onClick={() => setSelectedPDWDataset(r)}>{pdwDatasetEmbedIsNotError(r._embedded) ? r._embedded.name.toUpperCase() : "Error!"}</span>
                                <span>{outputPortTypeMap.get(OutputPortType.PDWdataset)}</span>
                            </div>
                        </div>
                    )}
                    {!loading && ports.length === 0 && <div><small className='text-muted'>There are no resources</small></div>}
                </div>
            </div>
            <Drawer
                show={!!selectedPDWDataset}
                size={0.35}
                onRequestHide={() => setSelectedPDWDataset(undefined)}
                closeOnClickOutside={true}
            >
                {selectedPDWDataset ?
                    (<div className="pdwdataset__wrapper">
                        <div className='pdwdataset__section-header'>
                            <div className='pdwdataset__title'>{pdwDatasetEmbedIsNotError(selectedPDWDataset?._embedded) ? selectedPDWDataset?._embedded.name : "Error loading Metadata!"}</div>
                            <a
                                className='dp-btn-outline'
                                href={pdwDatasetEmbedIsNotError(selectedPDWDataset?._embedded) ? buildSnowflakeURL(
                                        selectedPDWDataset._embedded.cluster,
                                        selectedPDWDataset._embedded.database,
                                        selectedPDWDataset._embedded.schema,
                                        selectedPDWDataset._embedded.isView,
                                        selectedPDWDataset._embedded.name
                                    ) : "#"}
                                target="_blank"
                                rel='noreferrer'>
                                Open in Snowflake
                            </a>
                        </div>
                        <div className='pdwdataset__overview'>
                            <div className='pdwdataset__section-header'>
                                <div className='pdwdataset__subtitle'><IconArrowDownAlt weight="fill" color={colors.linkHover}/><span>Overview</span></div>
                                <a className='dp-btn' hidden={true} onClick={() => {alert("Not implemented... yet.");}}>Add to my Product</a>
                            </div>
                            <ul>
                                <li>
                                    <span>Slack Channel</span>
                                    <span className='pdwdataset__slack-channels'>
                                        {buildSlackChannelList(pdwDatasetEmbedIsNotError(selectedPDWDataset?._embedded) ? selectedPDWDataset?._embedded.slackChannels : [], props.account.name)}
                                    </span>
                                </li>
                                <li>
                                    <span>Promise of Freshness</span>
                                    {pdwDatasetEmbedIsNotError(selectedPDWDataset?._embedded) && selectedPDWDataset?._embedded.pof?.quantity ?
                                        <span>{selectedPDWDataset?._embedded.pof?.quantity} {selectedPDWDataset?._embedded.pof?.type}</span>
                                        : <span className='text-muted'>{"-- --"}</span>}</li>
                                <li>
                                    <span>Owners</span>
                                    <span className='pdwdataset__owners-list'>{buildOwnersList(pdwDatasetEmbedIsNotError(selectedPDWDataset?._embedded) ? selectedPDWDataset?._embedded.owners : [])}</span>
                                </li>
                            </ul>
                            <div>
                                <span>Full Description</span>
                                <p>{pdwDatasetEmbedIsNotError(selectedPDWDataset?._embedded) ? selectedPDWDataset?._embedded.description: ""}</p>
                            </div>
                        </div>

                        <div className='pdwdataset__columns'>
                            <div className='pdwdataset__section-header'>
                                <div className='pdwdataset__subtitle'><IconArrowDownAlt weight="fill" color={colors.linkHover}/><span>Columns</span></div>
                                <a
                                    className='dp-btn'
                                    href={pdwDatasetEmbedIsNotError(selectedPDWDataset?._embedded) ? buildDataDiscoveryURL(
                                        selectedPDWDataset._embedded.cluster,
                                        selectedPDWDataset._embedded.database,
                                        selectedPDWDataset._embedded.schema,
                                        selectedPDWDataset._embedded.name
                                    ): "#"}
                                    target="_blank"
                                    rel='noreferrer'>
                                    Open in Data Discovery
                                </a>
                            </div>
                            <ul>
                                {pdwDatasetEmbedIsNotError(selectedPDWDataset?._embedded) && selectedPDWDataset?._embedded.columns.map(col =>
                                    <li key={col.name}>
                                        <span>{col.name.toUpperCase()}: {col.columnType.toUpperCase()}</span>
                                        {col.description ? <span>{col.description.trim()}</span> : <span className='text-muted'>--</span>}
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className='pdwdataset__data-issues'>
                            <div className='pdwdataset__section-header'>
                                <div className='pdwdataset__subtitle'><IconArrowDownAlt weight="fill" color={colors.linkHover}/><span>Ongoing Data Issues ({(issues || []).length})</span></div>
                                <a className='dp-btn' hidden={true} onClick={() => {alert("Not implemented... yet.");}}>Report Issue</a>
                            </div>
                            <Table data={issues || []} columns={openIssuesColumnns} />
                        </div>
                    </div>)
                : null}
            </Drawer>
        </div>
    );
};

export default Resources;