const pendingScripts = new Set<string>();

export const appendScript = async (scriptUrlToAppend: string, accessToken: string, id: string) => {
    // Check if the script with the given id already exists
    if (document.getElementById(id)) {
        console.log(`Script with id ${id} already exists.`);
        return;
    }
    // Check if a promise for this script is pending
    if (pendingScripts.has(id)) {
        const maxRetry = 10;
        let retries = 0;
        while (pendingScripts.has(id) && retries++ < maxRetry) {
            console.log(`Script with id [${id}] is already being appended. Wating for it to finish... [${retries}/${maxRetry}]`);
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        if (pendingScripts.has(id) && retries >= maxRetry)
            console.error(`Script with id [${id}] is already being appended. But max retries of [${maxRetry}] have been reached. We are giving up.`);
        return;
    }

    // Add the script id to the pending set
    pendingScripts.add(id);

    try {
        const header = new Headers();
        header.append('Authorization', `Bearer ${accessToken}`);

        const response = await fetch(scriptUrlToAppend, {
            method: 'GET',
            headers: header
        });

        const script = document.createElement("script");
        script.type = "module";
        script.id = id;
        script.setAttribute("bundle", scriptUrlToAppend);
        script.innerHTML = String(await response.text());
        document.body.appendChild(script);
    } finally {
        // Remove the script id from the pending set
        pendingScripts.delete(id);
    }
};