import React, { useEffect, useState } from 'react';
import { Button, shapes, Tooltip, Modal } from '@cimpress/react-components';
import { getSubscriptions, NHubResourceType, NHubSubscribeActions, subscribe, unsubscribe, isAuthorized, authorize } from '../../clients/notificationsHub';

export interface SubscribeButtonProps {
    accessToken: string;
}

const loadSubscriptions = async (accessToken, setLoading, setIsSubscribed) => {
    setLoading(true);
    try {
        const subs = await getSubscriptions(accessToken, NHubResourceType.DataPortal);
        setIsSubscribed(subs.length);
    } catch (error) {
        console.error(error);
    }
    setLoading(false);
};


export const SubscribeButton: React.FC<SubscribeButtonProps> = (({ accessToken }) => {

    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);

    useEffect(() => {
        loadSubscriptions(accessToken, setLoading, setIsSubscribed);
    }, [accessToken]);

    const updateSubscription = async () => {
        setIsProcessing(true);
        try {

            const isNHubAuthorized = await isAuthorized();

            if (!isNHubAuthorized) {
                setIsModalOpen(true);
            } else {
                if (!isSubscribed) {
                    await subscribe(accessToken, NHubResourceType.DataPortal, NHubSubscribeActions.NewDataProduct);
                    setIsSubscribed(true);
                } else {
                    await unsubscribe(accessToken, NHubResourceType.DataPortal);
                    setIsSubscribed(false);
                }
            }

        } catch (err) {
            // Either something went wrong, or the user clicked "Cancel" on the pop-up modal
            console.log(err);
        }
        setIsProcessing(false);
    };


    return <Tooltip contents='If subscribed, you will get a notification by email every time a Data Product is published.'>
        <Button
            size='sm'
            disabled={isProcessing || loading}
            onClick={updateSubscription}>{
                isProcessing
                    ? <span style={{ display: 'flex' }}><shapes.Spinner size='small' /> &nbsp;{!isSubscribed ? 'Subscribing' : 'Unsubscribing'}</span>
                    : !isSubscribed ? 'Subscribe' : 'Unsubscribe'
            }</Button>
        <Modal
            show={isModalOpen}
            status='info'
            title={'Email Notifications'}
            footer={<div>
                <Button variant='primary' onClick={authorize}>Authorize</Button>
                <Button onClick={() => setIsModalOpen(false)}>Close</Button>
            </div>
            }
        >
            <span>Before subscribing to Data Products, you must take a few seconds to authorize your account. This will only happen once.<br /><br />Click the Authorize button below, once returned to Data Portal, click on subscribe again.</span>

        </Modal>
    </Tooltip>;

});