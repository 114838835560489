import React, { useEffect, useMemo, useState } from 'react';
import { Card, colors, NavTab, NavTabItem } from '@cimpress/react-components';
import { Interfaces, Hooks, Clients } from "@cimpress-technology/data-portal-core";
import { AuthContext } from '../../../../context/authContext';
import { DataProducts } from '../../../shared/DataProducts';
import { DataProductDomainHeader } from './DataProductDomainHeader';
import { DataProductDomainDetails } from './DataProductDomainDetails';
import { SubscribeButton } from '../../../shared/SubscribeButton';
import { renderLoading, renderError } from '../../../shared/Render';
import { CreateNewDataProductButton } from '../../dataProductManagement/createDataProduct/CreateNewDataProductButton';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import { fetchDataProducts } from "@cimpress-technology/data-portal-core/lib/features/dataProducts/dataProductsSlice";

interface AccountDetailsExtended extends Clients.Accounts.AccountDetails
{
    error?: string
}
interface TenantDomainsProps {
    accessToken: string;
    accountId: string;
    accountDomains: {
        domain: Interfaces.DataPortalAPI.Domain,
        dataProductsCount: number;
    }[];
    selectedDomain: string;
    onSelectedDomain(domainId: string)
}

interface TenantTitleTabProps {
    accountId: string;
    accountDetails: AccountDetailsExtended;
    accountDomains: {
        domain: Interfaces.DataPortalAPI.Domain,
        dataProductsCount: number;
    }[];
    selectedAccount: string;
    onSelectedAccount(accountId: string): void;
}


type AccountCollection = {[biz:string]: AccountDetailsExtended };

const TenantTitleTab: React.FC<TenantTitleTabProps> = ({ accountId, accountDetails, accountDomains, selectedAccount, onSelectedAccount }) => {

    const selectedStyle = selectedAccount === accountId ? {
        borderColor: colors.sky.darkest,
        backgroundColor: colors.horizon
    } : {};

    return <div onClick={() => onSelectedAccount(accountId)}>
        <Card style={{
            ...selectedStyle, ...{
                cursor: 'pointer'
            }
        }}>

            <h6 style={{ color: colors.shale, margin: 0 }}>
                {accountDetails.error ? accountDetails.error : (accountDetails?.name || accountId) }
                <sup style={{ color: colors.granite.base }}> ({accountDomains.reduce((s, c) => s + c.dataProductsCount, 0)})</sup>
            </h6>

        </Card>
    </div >;
};


export const TenantDomains: React.FC<TenantDomainsProps> = ({ accountDomains, selectedDomain, onSelectedDomain }) => {
    return <NavTab vertical={true}>
        {accountDomains.map(accountDomain => {
            const { domain, dataProductsCount } = accountDomain;
            return <NavTabItem active={selectedDomain === domain.domainId} key={domain.domainId}>
                <button onClick={() => {
                    onSelectedDomain(domain.domainId);
                }}>
                    {domain.name} <span style={{ color: colors.shale }}> <sup style={{ color: colors.shale }}>{dataProductsCount ? `(${dataProductsCount})` : ''}</sup></span>

                </button>
            </NavTabItem>;
        })}
    </NavTab>;
};

const calculateTenants = (
    domains: Interfaces.DataPortalAPI.Domain[] | null,
    dataProductsList: Interfaces.DataPortalAPI.DataProductListItem[] | null) => {
    const dataProductsCount = {};
    const dataProducts = dataProductsList?.filter(d => d.published === true) || [];
    dataProducts.forEach(d => dataProductsCount[d.domainId] = (dataProductsCount[d.domainId] || 0) + 1);
    const tenants: { [accountId:string]: { domain: Interfaces.DataPortalAPI.Domain, dataProductsCount: number }[]} = {};
    if (!domains || domains.length === 0) return tenants;
    (domains || []).forEach(d => {
        if (!tenants[d.accountId]) {
            tenants[d.accountId] = [];
        }
        tenants[d.accountId].push({
            domain: d,
            dataProductsCount: dataProductsCount[d.domainId] || 0
        });
    });
    return tenants;
};

const calculateOrderedAccounts = (accounts: AccountCollection | undefined, userAccountId: string | undefined) => {
    if (!accounts || !userAccountId) return [];
    const currentAccounts = Object.keys(accounts);
    const cimpressAccountId = "g2Ez5VaoZWoqU22XqPjTLU";
    const startSequence: string[] = [];
    if (userAccountId && currentAccounts.includes(userAccountId)) startSequence.push(userAccountId);
    if (userAccountId && currentAccounts.includes(userAccountId) && userAccountId !== cimpressAccountId) startSequence.push(cimpressAccountId);
    return startSequence.concat(currentAccounts.filter(biz => !startSequence.includes(biz)).sort((a,b) => {
        if (accounts[a].name < accounts[b].name) return -1;
        if (accounts[a].name > accounts[b].name) return 1;
        return 0;
    }));
};


export const DomainDataProducts: React.FC = () => {

    const { accessToken, profile } = React.useContext(AuthContext);
    const { domains, domainsError, loadingDomains, loadDomains, setDomains } = Hooks.useDomains(accessToken);
    const { permissions, loadingPermissions, permissionsError, loadPermissions } = Hooks.useCoamPermissions(accessToken, profile?.["https://claims.cimpress.io/canonical_id"]);
    const [selectedDomain, setSelectedDomain] = useState('');
    const [selectedAccount, setSelectedAccount] = useState('');
    const [accountsInfo, setAccountsInfo] = useState<AccountCollection>();
    const userAccountId = profile?.['https://claims.cimpress.io/account'];

    const dispatch = useAppDispatch();
    const { dataProductList, error: dataProductsError } = useAppSelector((state) => state.dataProducts);

    useEffect(() => {
        const fetchPromise = accessToken ? dispatch(fetchDataProducts({ accessToken: accessToken, onlyOwned: false })) : undefined;
        return () => { fetchPromise && fetchPromise.abort(); };
    }, [accessToken, dispatch]);

    const tenants = useMemo(() => calculateTenants(domains, dataProductList), [domains, dataProductList]);

    useEffect(() => {
        const getAccountsDetails = async () => {
            const accountIds = Object.keys(tenants);
            if (tenants && accountIds.length > 0 && !accountsInfo && accessToken !== undefined) {
                const results = await Promise.all(accountIds.map(async accountId =>
                    await Clients.Accounts.getAccount(accessToken, accountId).catch(() => (
                        {
                            accountId: accountId,
                            name: "No available",
                            status: 'Active' as const,
                            description: 'Error',
                            cimpressOwned: false,
                            error: "Error loading account information: " + accountId
                        })))
                    );
                const accountDetails: AccountCollection = {};
                results.forEach(accDetail => accountDetails[accDetail.accountId] = accDetail);
                setAccountsInfo(accountDetails);
            }
        };
        getAccountsDetails();
    }, [tenants, accessToken, accountsInfo]);

    const orderedAccounts = useMemo(() => calculateOrderedAccounts(accountsInfo, userAccountId), [accountsInfo, userAccountId]);

    useEffect(() => {
        if (orderedAccounts.length > 0) {
            setSelectedAccount(orderedAccounts[0]);
            if (tenants[orderedAccounts[0]] && tenants[orderedAccounts[0]].length > 0)
                setSelectedDomain(tenants[orderedAccounts[0]][0].domain.domainId);
        }
    }, [orderedAccounts, tenants]);

    if (!accessToken) {
        return null;
    }

    const onEditDomainDescription = (domainId: string, newDescription: string) => {
        const index = domains?.findIndex(d => d.domainId === domainId);
        if (index && domains) {
            domains[index].description = newDescription;
            setDomains(domains);
        }
    };

    const selectedDomainData = domains?.find(d => d.domainId === selectedDomain);
    const selectedDomainHasPermission = selectedDomain && permissions
        ? Clients.Coam.hasPermission(permissions, Clients.Coam.ResourceType.DataPortalDomain, selectedDomain, Clients.Coam.Permission.DataPortalDomainGovern)
        : false;

    const shouldShowResults = (!!tenants && Object.keys(tenants).length > 0 && selectedAccount.length > 0 && !!domains && domains?.length > 0);

    return <div>
        <div>
            <div style={{ float: 'right' }}>
                <SubscribeButton accessToken={accessToken} />
            </div>
            <h2>Data Products</h2>
            <small>Explore the stable data from the Mass Customization Platform.</small>
        </div>

        <br />

        {
            loadingDomains || loadingPermissions
                ? renderLoading('Loading domains', true)
                : domainsError || permissionsError
                    ? renderError('Error loading domains.', domainsError || permissionsError, domainsError ? loadDomains : loadPermissions)
                    : shouldShowResults
                        ? <div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {accountsInfo && orderedAccounts && orderedAccounts
                                    .map(accountId => <div key={accountId} style={{ marginRight: 16, marginBottom: 6 }}>
                                        <TenantTitleTab
                                            accountId={accountId}
                                            accountDetails={accountsInfo[accountId]}
                                            accountDomains={tenants[accountId]}
                                            selectedAccount={selectedAccount}
                                            onSelectedAccount={(accountId) => {
                                                setSelectedAccount(accountId);
                                                if (tenants[accountId].length > 0) setSelectedDomain(tenants[accountId][0].domain.domainId);
                                            }}
                                        />
                                    </div>)}
                            </div>
                            <hr />
                            <div className='row'>

                                <div className='col-md-2'>
                                    <TenantDomains
                                        accessToken={accessToken}
                                        accountId={selectedAccount}
                                        accountDomains={tenants[selectedAccount]}
                                        selectedDomain={selectedDomain}
                                        onSelectedDomain={setSelectedDomain}
                                    />
                                </div>
                                <div className='col-md-10'>
                                    {selectedDomainData
                                        ? <div className='row'>
                                            <br />
                                            <div className='col-md-9'>
                                                <DataProductDomainHeader domain={selectedDomainData} onEdit={onEditDomainDescription} hasPermission={selectedDomainHasPermission} />
                                                <br />
                                                {
                                                    selectedAccount === userAccountId ?
                                                        <div>
                                                           <CreateNewDataProductButton size='sm' variant='primary' />
                                                        </div> : <></>
                                                }
                                                {dataProductsError
                                                    ? renderError("Error Loading DataProducts", dataProductsError as Error, () => { dispatch(fetchDataProducts({ accessToken: accessToken, onlyOwned: false })); })
                                                    : <DataProducts domainId={selectedDomain} dataProductsPerRow={3} />}
                                            </div>
                                            <div className='col-md-3'>
                                                <DataProductDomainDetails domain={selectedDomainData} />
                                                <br /><br />
                                            </div>
                                        </div>
                                        : null}

                                </div>
                            </div>
                        </div>
                        : <span>No domains available, please contact <a href='mailto:PandaSquad@cimpress.com'>PandaSquad@cimpress.com.</a></span>
        }
    </div>;
};
