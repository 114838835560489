import React from "react";
import { DataProductListItem } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { UserInformationCache } from "@cimpress-technology/data-portal-core/lib/features/coamUserInfoCache/coamUserInfoCacheSlice";
import {
    FirstMemberCache,
} from "@cimpress-technology/data-portal-core/lib/features/firstMemberCache/firstMemberCacheSlice";
import { DataProductCard } from "./DataProductCard";
import { buildAuthorInfo } from "./utils/utils";
import useSegment from "../../../../hooks/useSegment";


type Props = {
    recentlyViewed: DataProductListItem[] | null;
    firstMemberInfo: FirstMemberCache;
    usersCoamInfo: UserInformationCache;
    openThisPath: string;
    storeVisitIn: string;
    showPublishedMark: boolean;
};
const RecentlyViewed = (props: Props) => {
    const { trackEvent } = useSegment();

    return (
        <section className="discovery__results">
            <h2 className="dp-subtitle">RECENTLY VIEWED</h2>
            <div className="discovery__results__items">
                {(props.recentlyViewed || []).map((o) => {
                    return (
                        <DataProductCard
                            key={o.dataProductId}
                            dataProductId={o.dataProductId}
                            name={o.dataProductName}
                            description={o.summary}
                            domainId={o.domainId}
                            published={props.showPublishedMark === true ? o.published : undefined}
                            authorInfo={buildAuthorInfo(props.firstMemberInfo[o.dataProductId], props.usersCoamInfo)}
                            outputPorts={o.outputPorts}
                            openThisPath={props.openThisPath}
                            storeVisitIn={props.storeVisitIn}
                            onClickTracking={(dataProductId: string, dataProductName: string) => {
                                trackEvent("Discovery page: click on recently viewed Data Product", {
                                    dataProductId: dataProductId,
                                    dataProductName: dataProductName,
                                });
                            }}
                        />
                    );
                })}
            </div>
        </section>
    );
};

export default RecentlyViewed;
