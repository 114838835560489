import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { fetchUserOwnedPdwDatasets } from "@cimpress-technology/data-portal-core/lib/features/outputPorts/common";

// Callbacks memoize a function. Then, when we use this function inside `useEffect`, it won't be triggered on each re-render because the memoized function will persist.
// It is also a good way to abstract functionality

type callbackType = () => void;
export const useMemoizedLoaderForPdwDatasets = (accessToken: string | undefined, dataProductId: string, domainId: string | undefined): callbackType  => {
    const dispatch = useAppDispatch();
    const pdwDatasetsState = useAppSelector(state => state.pdwDatasets);
    const fetchPdwDatasets = useCallback(() => {
        if (accessToken && dataProductId && domainId && (pdwDatasetsState.pdwDatasets === null || pdwDatasetsState.dataProductId === null || pdwDatasetsState.dataProductId !== dataProductId)) {
            // Only fetch when the dashboards value is null (not previously rendered) OR when entering a into a Data Product for the first time OR when we have changed Data Product
            dispatch(fetchUserOwnedPdwDatasets({ accessToken: accessToken, dataProductId: dataProductId, domainId: domainId }));
        }
    }, [dispatch, accessToken, dataProductId, pdwDatasetsState.dataProductId, pdwDatasetsState.pdwDatasets, domainId]);
    return fetchPdwDatasets;
};

