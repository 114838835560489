import React, { useState } from "react";
import useDomains from "../../../../hooks/useDomains";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import IconMultipleUsers from "@cimpress-technology/react-streamline-icons/lib/IconMultipleUsers";
import IconInformationCircle from "@cimpress-technology/react-streamline-icons/lib/IconInformationCircle";
import {
    Select,
    Tooltip,
    TextField,
    Snackbar,
} from "@cimpress/react-components";
import WifiBars from "./WifiBars";
import useSubmitFeedback from "../../../../hooks/useSubmitFeedback";
import useSegment from "../../../../hooks/useSegment";

const usefulnessValues = {
    1: "Not useful - The information is simply not useful.",
    2: "Slightly useful - The information is useful, but not enough to be a big help.",
    3: "Neutral - I guess it is OK.",
    4: "Useful - The information is useful, and could be a big help.",
    5: "Very useful - All information provided is essential to make decisions in the process.",
} as const;

const uniquenessValues = {
    1: "Not unique - The information is simply not unique.",
    2: "Slightly unique - The information is unique, but not enough to be a big help.",
    3: "Neutral - I guess it is OK.",
    4: "Unique - The information is unique, and could be a big help.",
    5: "One of a kind - All information provided is essential to make decisions in the process.",
} as const;

type FeedbackState = {
    isAnonymous: boolean;
    domain: string | null;
    usefulness: number;
    uniqueness: number;
    additionalFeedback: string | null;
};

const initialState: FeedbackState = {
    isAnonymous: false,
    domain: null,
    usefulness: 3,
    uniqueness: 3,
    additionalFeedback: null,
};

type Props = {
    dataProductId: string;
    account?: string;
    accessToken?: string;
    productTeamLoading: boolean;
};

const Feedback = (props: Props) => {
    const { status, domains, error } = useDomains({
        accessToken: props.accessToken,
        account: props.account,
    });

    const [feedbackState, setFeedbackState] =
        useState<FeedbackState>(initialState);

    const { trackEvent } = useSegment();

    const useSubmitFeedbackHook = useSubmitFeedback({
        accessToken: props.accessToken,
        dataProductId: props.dataProductId,
        feedbackPayload: {
            isAnonymous: feedbackState.isAnonymous,
            domain: feedbackState.domain || "",
            uniquenessRating: feedbackState.uniqueness,
            usefulnessRating: feedbackState.usefulness,
            additionalFeedback:
                feedbackState.additionalFeedback == null
                    ? undefined
                    : feedbackState.additionalFeedback,
        },
    });

    const reset = () => {
        setFeedbackState(initialState);
        setSelectedDomain(null);
    };

    const setStayAnon = (value: boolean) => {
        setFeedbackState({
            ...feedbackState,
            isAnonymous: value,
        });
    };

    const setUsefulness = (value: number) => {
        setFeedbackState({
            ...feedbackState,
            usefulness: value,
        });
    };

    const setUniqueness = (value: number) => {
        setFeedbackState({
            ...feedbackState,
            uniqueness: value,
        });
    };

    const setAdditionalFeedback = (value: string | null) => {
        setFeedbackState({
            ...feedbackState,
            additionalFeedback: value,
        });
    };

    const [selectedDomain, setSelectedDomain] = useState<
        { value: string; label: string } | undefined | null
    >(undefined);

    const setDomain = (
        option: { value: string; label: string } | undefined | null
    ) => {
        setFeedbackState({
            ...feedbackState,
            domain: option?.value == null ? null : option.value,
        });
        setSelectedDomain(option);
    };

    if (props.productTeamLoading) {
        return (
            <div className="dp-card summary__feedback">
                <div className="summary__card__header">
                    <span className="summary__card__header__icon">
                        <IconMultipleUsers />
                    </span>
                    <span className="summary__card__header__text">
                        Tell us what you think!
                    </span>
                </div>
                <div className="summary__feedback__content">
                    <Spinner size="small" />
                </div>
            </div>
        );
    }

    return (
        <div className="dp-card summary__feedback">
            {useSubmitFeedbackHook.status === "error" && (
                <Snackbar
                    show={true}
                    status="danger"
                    delay={2500}
                    onHideSnackbar={() => useSubmitFeedbackHook.reset()}
                >
                    {useSubmitFeedbackHook.error.message}
                </Snackbar>
            )}
            {useSubmitFeedbackHook.status === "success" && (
                <Snackbar
                    show={true}
                    status="success"
                    delay={3000}
                    onHideSnackbar={() => {
                        useSubmitFeedbackHook.reset();
                        reset();
                    }}
                >
                    Feedback Received!
                </Snackbar>
            )}
            <div className="summary__card__header">
                <span className="summary__card__header__icon">
                    <IconMultipleUsers />
                </span>
                <span className="summary__card__header__text">
                    Tell us what you think!
                </span>
            </div>
            <div className="summary__feedback__content">
                <p>
                    Help us improve by providing feedback. It will not be
                    published and only available to the authors of this product.
                </p>
                <div className="summary__feedback__anonymous">
                    <div className="dp-checkbox">
                        <input
                            id="stay-anon"
                            type="checkbox"
                            checked={feedbackState.isAnonymous}
                            onChange={() =>
                                setStayAnon(!feedbackState.isAnonymous)
                            }
                        />
                        <label htmlFor="stay-anon">Stay anonymous</label>
                    </div>
                    <Tooltip
                        direction="top"
                        style={{ display: "flex", alignItems: "center" }}
                        contents="Anonymous feedback is also useful for improving the product."
                    >
                        <IconInformationCircle weight="fill" />
                    </Tooltip>
                </div>
                {status === "loading" && <Spinner size="small" />}
                {status === "success" && (
                    <div className="summary__feedback__domain">
                        <Select
                            isClearable
                            label="Select Domain"
                            value={selectedDomain}
                            options={domains
                                .map((domain) => ({
                                    value: domain.name,
                                    label: domain.name,
                                }))
                                .concat({ value: "", label: "Other" })}
                            onChange={(option) => setDomain(option)}
                        />
                    </div>
                )}
                {status === "error" && <div>{error.message}</div>}
                {selectedDomain?.value === "" && (
                    <div>
                        <TextField
                            name="other-domain"
                            label="Other domain"
                            value={feedbackState.domain || ""}
                            onChange={(e) =>
                                setFeedbackState({
                                    ...feedbackState,
                                    domain: e.target.value,
                                })
                            }
                        />
                    </div>
                )}
                <div className="summary__feedback__usefulness">
                    <div>
                        <b>USEFULNESS</b>
                    </div>
                    <WifiBars
                        controlValue={feedbackState.usefulness}
                        controlFunction={setUsefulness}
                    />
                    <div>{usefulnessValues[feedbackState.usefulness]}</div>
                </div>
                <div className="summary__feedback__uniqueness">
                    <div>
                        <b>UNIQUENESS</b>
                    </div>
                    <WifiBars
                        controlValue={feedbackState.uniqueness}
                        controlFunction={setUniqueness}
                    />
                    <div>{uniquenessValues[feedbackState.uniqueness]}</div>
                </div>
                <div className="summary__feedback__additional">
                    <textarea
                        placeholder="Additional feedback"
                        rows={6}
                        onChange={(e) => setAdditionalFeedback(e.target.value)}
                        value={feedbackState.additionalFeedback || ""}
                    />
                </div>
                <div className="summary__feedback__submit">
                    <button
                        className="dp-btn"
                        disabled={useSubmitFeedbackHook.status !== "idle"}
                        onClick={reset}
                    >
                        Clear
                    </button>
                    <button
                        className="dp-btn dp-btn-outline"
                        disabled={useSubmitFeedbackHook.status !== "idle"}
                        onClick={() => {
                            trackEvent('Data Product page: submitted feedback', {
                                dataProductId: props.dataProductId,
                            });
                            useSubmitFeedbackHook.submitFeedback();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Feedback;
