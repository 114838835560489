import React, { useRef, useEffect, useCallback } from 'react';
import { DatabricksDatasetsProps } from '../../hooks/useDatabricksDatasets';


export const DatabricksDatasets: React.FC<Partial<DatabricksDatasetsProps> | undefined> = (databricksDatasetsModuleProps) => {

    const databricksDatasets = useRef<HTMLInputElement>(null);

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const renderFunction = (window as any)?.dataportalresources["databricks-datasets"]?.render;

    const renderFunctionCallback = useCallback( async (domElement) => {
        if(renderFunction != undefined && databricksDatasetsModuleProps != undefined && databricksDatasetsModuleProps?.accessToken)
        {
            await renderFunction(domElement, { ...databricksDatasetsModuleProps });
        }

    }, [databricksDatasetsModuleProps, renderFunction]);

    useEffect(() => {
        if (databricksDatasets?.current != null &&
            databricksDatasets?.current?.childElementCount == 0) {
                renderFunctionCallback(databricksDatasets.current);
        }
    }
        , [databricksDatasets, renderFunctionCallback]);

    return (
        <div ref={databricksDatasets}></div>
    );
};