import React from "react";

type Props = {
  label?: string;
  value: string | null;
  isDisabled?: boolean;
  selfAdjust?: boolean;
  options: { label: string; value: string }[];
  onChange: (event: React.FormEvent<HTMLSelectElement>) => void;
  onClick: () => void;
  onBlur: () => void;
};

const calculateSelfAdjustStyle = (
  options: { label: string; value: string }[],
  currentSelected: string | null,
  selfAdjust?: boolean,
) => {
  const minWidth = 20;
  const style: React.CSSProperties = {};
  if (!selfAdjust) {
    return style;
  }
  const selectedIndex = options.findIndex(
    (option) => option.value === currentSelected
  );
  if (selectedIndex === -1) {
    style.width = `${minWidth}rem`;
    return style;
  }
  style.width = `${options[selectedIndex].label.length <= minWidth ? minWidth : options[selectedIndex].label.length * 0.9}rem`;
  return style;
};

const DpSelect = (props: Props): JSX.Element => {
  return (
    <label>
      {props.label}
      <select
        value={props.value || "all"}
        onChange={props.onChange}
        onClick={props.onClick}
        onBlur={props.onBlur}
        disabled={props.isDisabled ? true : false}
        style={calculateSelfAdjustStyle(
          props.options,
          props.value,
          props.selfAdjust,
        )}
      >
        {props.options.length === 0 && <option value={"all"}>Loading</option>}
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default DpSelect;
