import { Interfaces } from '@cimpress-technology/data-portal-core';
import { colors, Select, Snackbar } from '@cimpress/react-components';
import React, { useState } from 'react';
import { renderError, renderLoading } from '../../../../shared/Render';
import { IconPencilAlt } from '@cimpress-technology/react-streamline-icons/lib';
import { patchDataProductAttribute } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalApi';
import { AuthContext } from '../../../../../context/authContext';
import axios from 'axios';

export const DataProductDomain: React.FC<{ dataPrfoductId: string, domainHook: Interfaces.DataPortalAPI.DomainsHook, currentDoamin: Interfaces.DataPortalAPI.Domain, isDataProductAdmin: boolean, selectedDomainId:string | undefined,setSelectedDomainId:React.Dispatch<React.SetStateAction<string|undefined>>}> = ({ dataPrfoductId, domainHook, currentDoamin, isDataProductAdmin ,selectedDomainId,setSelectedDomainId}) => {

    const { accessToken } = React.useContext(AuthContext);
    const [showDomainDropDown, setShowDomainDropDown] = useState(false);
    const { domains, loadingDomains, domainsError, loadDomains } = domainHook;
    const [errorUpdating, setErrorUpdating] = useState(undefined as string | undefined);
    const [changingDomain, setChangingDomain] = useState(false);
    const domainOptions = domains?.map(domain => { return { label: domain.name, value: domain.domainId }; }) || [];

    const changeDomain = async (dataPrfoductId: string, domainId: string) => {
        setChangingDomain(true);
        setErrorUpdating(undefined);
        try {
            accessToken ? await patchDataProductAttribute(accessToken, dataPrfoductId, 'domainId', domainId) : null;
            setSelectedDomainId(domainId);
            setShowDomainDropDown(false);
        } catch (error) {
            axios.isAxiosError(error) ? setErrorUpdating(error.response?.data?.message || error.response?.statusText || error.message) : setErrorUpdating((error as Error).message);
        }
        setChangingDomain(false);
    };

    return <>
        <div>
            {isDataProductAdmin && showDomainDropDown ?
                (loadingDomains || changingDomain) ? renderLoading(loadingDomains ? 'Loading domains...' : 'Updating domain', false, "small") :
                    domainsError ? renderError('Error loading domains', domainsError, loadDomains) :
                        <table>
                            <tr>
                                <td colSpan={8} style={{ width: '100%' }}>
                                    <Select
                                        label='Data Product Domain'
                                        options={domainOptions}
                                        isClearable={false}
                                        value={domainOptions?.find(domain => domain.value === selectedDomainId)}
                                        onChange={(c) => c ? changeDomain(dataPrfoductId, c.value) : null}
                                        isMulti={false}
                                    />
                                </td>
                                <td colSpan={1} className="inline-edit-group" style={{ minWidth: '50px' }}>
                                    <div className='right-addon'>
                                        <button onClick={() => setShowDomainDropDown(false)}><i className="fa fa-times" style={{ margin: "0px" }}></i></button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                :
                <div className="stat-value">
                    <span style={{ color: colors.shale }}>
                        {currentDoamin.domainId === selectedDomainId ? currentDoamin.name : domains?.find(domain => domain.domainId === selectedDomainId)?.name} &nbsp; {isDataProductAdmin ?
                            <IconPencilAlt weight="fill" style={{ cursor: 'pointer', height: 18 }} color={colors.info.base} onClick={() => setShowDomainDropDown(true)} />
                            : undefined
                        }

                    </span>
                </div>
            }
        </div>
        <Snackbar status='danger' show={!!errorUpdating} delay={4000} onHideSnackbar={() => setErrorUpdating(undefined)} >
            <div>{errorUpdating ? errorUpdating : 'here'}</div>
        </Snackbar>
    </>;
};