import { Button, colors, Modal, Tooltip } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { IconAlertTriangle, IconBin, IconCheck, IconTaskListWarning } from '@cimpress-technology/react-streamline-icons/lib';
import React, { useEffect, useState } from 'react';
import { removeOutputPort } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalApi';
import { AuthContext } from '../../../context/authContext';
import { OutputPortComponent, OutputPortType, OutputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import axios from 'axios';
import { SliceState } from '@cimpress-technology/data-portal-core/lib/features/common';

export enum DeleteOutputPortProcessStatus {
    IDLE = 'idle',
    PROCESSING = 'processing',
    FAILED = 'failed',
    SUCCESS = 'success'
}

export type mapSliceStateToDeletePortState = {
    [Property in SliceState] : DeleteOutputPortProcessStatus
}

export const mappedSliceStateToDeletePortState: mapSliceStateToDeletePortState = {
    idle: DeleteOutputPortProcessStatus.IDLE,
    loading: DeleteOutputPortProcessStatus.PROCESSING,
    succeeded: DeleteOutputPortProcessStatus.SUCCESS,
    failed: DeleteOutputPortProcessStatus.FAILED
};

export const DeleteOutputPortButton: React.FC<{
    dataProductId: string,
    outputPortType: OutputPortTypePath,
    useInDataProduct: string,
    outputPortBody: OutputPortComponent<OutputPortType>
    isPublishedDataProduct: boolean,
    onProcessingStatusChange: (status: DeleteOutputPortProcessStatus) => void,
    style?: React.CSSProperties;
    customDeleteFunction?: () => void,
    customProcessingStatus?: DeleteOutputPortProcessStatus,
    customErrorMessage?: string,
}> = ({ dataProductId, outputPortType, useInDataProduct, outputPortBody, onProcessingStatusChange, customDeleteFunction, customProcessingStatus, customErrorMessage, style }) => {

    const [ processingStatus, setProcessingStatus ] = useState(customProcessingStatus ? customProcessingStatus : DeleteOutputPortProcessStatus.IDLE);
    const [ errorDeleting, setErrorDeleting ] = useState(customErrorMessage ? customErrorMessage : '');
    const { accessToken } = React.useContext(AuthContext);
    const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);

    useEffect(() => {
        if (customProcessingStatus) setProcessingStatus(customProcessingStatus);
        if (customErrorMessage) setErrorDeleting(customErrorMessage);
    }, [customProcessingStatus, customErrorMessage]);

    const deleteOutputPortOnClick = async (outputPortType: OutputPortTypePath, outputPortBody: OutputPortComponent<OutputPortType>, dataProductId: string) => {
        setErrorDeleting('');
        setProcessingStatus(DeleteOutputPortProcessStatus.PROCESSING);
        setShowConfirmationModal(false);
        onProcessingStatusChange(DeleteOutputPortProcessStatus.PROCESSING);
        try {
            if (accessToken) {
                await removeOutputPort(accessToken, dataProductId, outputPortType, outputPortBody);
                setProcessingStatus(DeleteOutputPortProcessStatus.SUCCESS);
                onProcessingStatusChange(DeleteOutputPortProcessStatus.SUCCESS);
            } else {
                setProcessingStatus(DeleteOutputPortProcessStatus.IDLE);
                setErrorDeleting('Authorization token not found. Try again after refreshing the page');
            }
        } catch(error) {
            let errorMessage = 'There was an Error removing this output Port. Output port might have already been removed. Refresh and Try again or contact #data-domain-support';
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data.message || error.response?.statusText || error.message;
            }
            setErrorDeleting(errorMessage);
            setProcessingStatus(DeleteOutputPortProcessStatus.FAILED);
            onProcessingStatusChange(DeleteOutputPortProcessStatus.FAILED);
        }
    };

    return <div>
            <div style={{...style}}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
                {
                    processingStatus !== DeleteOutputPortProcessStatus.PROCESSING ?
                        processingStatus !== DeleteOutputPortProcessStatus.SUCCESS ?
                            <>
                                <a style={{ cursor: "pointer" }}  onClick={() => setShowConfirmationModal(true) }>
                                        <IconBin
                                            style={{color: colors.danger.base, height: '17px'}}
                                            weight='fill'
                                        />
                                        { processingStatus === DeleteOutputPortProcessStatus.FAILED  ?
                                                <Tooltip show={!errorDeleting ? false : undefined} contents={errorDeleting}>
                                                    <IconAlertTriangle weight="fill" style={{color: colors.warning.base}} />
                                                </Tooltip>
                                            : null
                                        }
                                </a>
                                {useInDataProduct && useInDataProduct !== dataProductId ?
                                        <Tooltip
                                            contents={
                                                <>
                                                    The dataset is used in published data product <a href={`/dataProducts/${useInDataProduct}`} target="_blank" rel="noreferrer">{useInDataProduct}</a> You need to remove this before publishing data product.
                                                </>
                                            }>
                                            <span style={{color: colors.warning.base}}><IconTaskListWarning /></span>
                                        </Tooltip>
                                    : null
                                }
                            </>
                        :
                            <IconCheck weight="fill" style={{color: colors.danger.base}} />

                    :
                        <Spinner size='medium' />
                }
                </div>
            </div>

            <Modal closeButton={true} title="CONFIRMATION" closeOnOutsideClick={true} show={showConfirmationModal} onRequestHide={() => setShowConfirmationModal(false)} status='danger'>
                <div>

                    <p>Are you sure you want to delete <b>{outputPortBody.resourceId}</b> from the Data Product ?</p>
                </div>
                <br />
                <div className='pull-right'>
                    <Button onClick={() => setShowConfirmationModal(false)}>Cancel</Button>
                    <Button
                        variant='primary'
                        style={{marginLeft: '5px'}}
                        onClick={() => {
                            customDeleteFunction ? (() => { setShowConfirmationModal(false); customDeleteFunction(); })() : deleteOutputPortOnClick(outputPortType, outputPortBody, dataProductId);
                            }
                        }
                    >
                        Confirm
                    </Button>
                </div>
            </Modal>
        </div>;
};
