import { useCallback } from "react";
import useSegmentContext from "../context/segment/useSegmentContext";

export type EventProperties = { [key: string]: string | undefined };

const useSegment = () => {
  const { segment } = useSegmentContext();

  const pageViewed = useCallback(
    (name: string, category = "App") => {
        segment.page(category, name);
    },
    [segment]
  );

  const trackEvent = useCallback(
    (eventName: string, properties: EventProperties = {}) => {
        segment.track(eventName, properties);
    },
    [segment]
  );

  return {
    pageViewed,
    trackEvent,
  };
};

export default useSegment;