import React from "react";

export const UserProfile: React.FC<{
    coamUser: {
        canonicalPrincipalId: string;
        fullName: string;
        isClient: boolean;
        picture: string;
    };
}> = ({ coamUser }) => {
    const imgSrc =
        coamUser.picture;

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
                <img
                    src={imgSrc}
                    height={32}
                    width={32}
                    style={{ borderRadius: "50%", marginRight: 12 }}
                />
            </div>
            <div>
                {!coamUser.isClient && coamUser.fullName ? (
                    <>
                        {coamUser.fullName}
                        <br />
                    </>
                ) : null}
                {coamUser.canonicalPrincipalId}
            </div>
        </div>
    );
};
