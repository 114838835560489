import axios from "axios";
import { CanonicalPrincipalSearchResult, CoamGroupDetail } from "@cimpress-technology/data-portal-core/lib/interfaces/coam";
import { UserInformation } from "@cimpress-technology/data-portal-core/lib/features/coamUserInfoCache/coamUserInfoCacheSlice";
import { getDefaultAvatar } from "../common";

/**
 * If picture is needed, use this interface.
 */
export interface ExtendedCoamGroupDetail extends Omit<CoamGroupDetail, 'members'> {
    members: {
        principal: string;
        is_admin: boolean;
        is_client: boolean;
        profile?: {
            given_name: string;
            family_name: string;
            name: string;
            email: string;
            picture: string;
            nickname: string;
            job_title: string;
        };
        roles: Array<string>;
    }[];
}

export const getCOAMUserData = async (
    canonicalPrincipalId: string,
    accessToken: string
): Promise<UserInformation> => {
    const { data } = await axios.get<CanonicalPrincipalSearchResult>(
        `https://api.cimpress.io/auth/access-management/v1/canonicalPrincipals/${canonicalPrincipalId}`,
        {
            headers: { authorization: `Bearer ${accessToken}` },
            params: {
                responseFilter: "groups,permissions",
                include: false,
            },
        }
    );
    const usefulProfiles = data.profiles.filter(
        (o) => o.given_name || o.family_name
    );
    const response: UserInformation = {
        canonicalPrincipalId: canonicalPrincipalId,
        is_client: data.is_client,
        is_pending: data.is_pending,
        account_id: data.account_id,
        email: usefulProfiles.length > 0 ? usefulProfiles[0].email : undefined,
        fullName:
            usefulProfiles.length > 0
                ? `${usefulProfiles[0].given_name ?? ""} ${
                      usefulProfiles[0].family_name ?? ""
                  }`
                : data.is_client
                ? "Client"
                : "No Profile",
        picture:
            usefulProfiles.length > 0
                ? usefulProfiles[0].picture
                : getDefaultAvatar(canonicalPrincipalId),
    };
    return response;
};
