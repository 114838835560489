import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../store/storeHooks";
import { fetchDataProductMembers } from "@cimpress-technology/data-portal-core/lib/features/members/common";
import useUserCoamInfoCache from "./useUserCoamInfoCache";
import {
    ErrorUserInformation,
    LoadingUserInformation,
    ValidUserInformation,
    isErrorUserInformation,
    isLoadingUserInformation,
} from "@cimpress-technology/data-portal-core/lib/features/coamUserInfoCache/coamUserInfoCacheSlice";
import { DataProductMember } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { SliceState } from "../features/common";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";

const getCoamUserName = (
    userInfo:
        | ValidUserInformation
        | ErrorUserInformation
        | LoadingUserInformation
) => {
    if (isLoadingUserInformation(userInfo)) {
        return "Loading..."; // Null/Undefined is supposed to be loading.
    }
    if (isErrorUserInformation(userInfo)) {
        return `Error for ${userInfo.canonicalPrincipalId}: ${userInfo.errorMessage}`;
    }
    return !userInfo.data.is_client
        ? userInfo.data.fullName
        : userInfo.canonicalPrincipalId;
};

export type UserInformationComposition = {
    canonicalId: string;
    memberInfo: DataProductMember;
    coamInfo:
        | ValidUserInformation
        | ErrorUserInformation
        | LoadingUserInformation;
};

type GenericState = {
    status: SliceState;
};

type IdleState = {
    status: "idle";
    productTeam: [];
    error: null;
};

type LoadingState = {
    status: "loading";
    productTeam: [];
    error: null;
};

type SuccessState = {
    status: "succeeded";
    productTeam: UserInformationComposition[];
    error: null;
};

type ErroState = {
    status: "failed";
    productTeam: [];
    error: { message: string };
};

type ReturnType = (IdleState | LoadingState | SuccessState | ErroState) &
    GenericState;

type Props = {
    accessToken?: string;
    dataProductId?: string;
};

export const useProductTeam = (props: Props): ReturnType => {
    const {
        members,
        status: membersStatus,
        error: membersError,
    } = useAppSelector((state) => state.members);
    const dispatch = useAppDispatch();
    const needDataForTheseCanonicalIds = useMemo(() => {
        return members ? Object.keys(members) : null;
    }, [members]);

    const { usersCoamInfo, status: usersCoamInfoStatus } = useUserCoamInfoCache(
        {
            accessToken: props.accessToken,
            needDataForTheseCanonicalIds,
        }
    );

    const status: SliceState = membersStatus === "loading" || usersCoamInfoStatus === "loading" ? "loading" : membersStatus;

    useEffect(() => {
        const accessToken = props.accessToken;
        const dataProductId = props.dataProductId;
        if (!accessToken || !dataProductId) {
            return;
        }
        dispatch(
            fetchDataProductMembers({
                accessToken,
                dataProductId,
            })
        );
    }, [props.accessToken, props.dataProductId, dispatch]);

    const usersMemberAndCoamInfo = useMemo(() => {
        if (members == null) {
            return [];
        }
        const result: UserInformationComposition[] = Object.keys(members).map(
            (key) => ({
                canonicalId: key,
                memberInfo: members[key],
                coamInfo: usersCoamInfo[key] || {
                    status: "loading",
                    canonicalPrincipalId: key,
                    data: null,
                },
            })
        );
        return result;
    }, [members, usersCoamInfo]);

    const sortedMembers = useMemo(() => {
        if (usersMemberAndCoamInfo.length === 0) {
            return [];
        }
        const alreadyIncluded = new Set<string>();
        const firstProductOwner = usersMemberAndCoamInfo.find((m) =>
            m.memberInfo.labels
                .map((o) => o.toLowerCase())
                .includes("product manager")
        );
        if (firstProductOwner) {
            alreadyIncluded.add(firstProductOwner.canonicalId);
        }
        const sortedMembers = usersMemberAndCoamInfo
            .filter((m) => !alreadyIncluded.has(m.canonicalId))
            .sort((a, b) =>
                getCoamUserName(a.coamInfo).localeCompare(
                    getCoamUserName(b.coamInfo)
                )
            );
        return (firstProductOwner ? [firstProductOwner] : []).concat(
            sortedMembers
        );
    }, [usersMemberAndCoamInfo]);

    switch (status) {
        case "idle":
            return {
                status: "idle",
                productTeam: [],
                error: null,
            };
        case "loading":
            return {
                status: "loading",
                productTeam: [],
                error: null,
            };
        case "succeeded":
            return {
                status: "succeeded",
                productTeam: sortedMembers,
                error: null,
            };
        case "failed":
            return {
                status: "failed",
                productTeam: [],
                error: {
                    message: parseUnknownErrorTypeToErrorMessage(membersError),
                },
            };
    }
};

export default useProductTeam;
