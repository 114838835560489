import React, { ReactNode, useEffect, useState } from 'react';
import { TextField, Table, Drawer, Button } from '@cimpress/react-components';
import { AuthContext } from '../../../../context/authContext';
import { MetadataProvider, InputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { DataPortalAPI } from '@cimpress-technology/data-portal-core';
import { AddInputPortButton } from '../../../shared/inputPorts/AddInputPortButton';
import { DeleteInputPortButton } from '../../../shared/inputPorts/DeleteInputPortButton';
import { useRiverStreams } from '@cimpress-technology/data-portal-core/lib/hooks';
import { RiverStreamDetails } from '@cimpress-technology/data-portal-core/lib/interfaces/river';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import { setEditMode } from '@cimpress-technology/data-portal-core/lib/features/inputPorts/riverStreamsSlice';
import { SliceState } from '@cimpress-technology/data-portal-core/lib/features/common';


function filterRiverStreams(riverStreams: RiverStreamDetails[] | null, searchText: string): RiverStreamDetails[] {
    let filteredRiverStreams = riverStreams || [];
    if (searchText) {
        const lowercaseSearch = searchText.toLowerCase();
        filteredRiverStreams = filteredRiverStreams.filter(filteredRiverStream => filteredRiverStream.streamName.toLowerCase().includes(lowercaseSearch));
    }
    filteredRiverStreams.sort((a, b) => a.streamName.localeCompare(b.streamName));
    return filteredRiverStreams;
}

export const ManageRiverStream: React.FC<{
    dataProduct: DataPortalAPI.DataProduct,
}> = ({ dataProduct }) => {

    const dispatch = useAppDispatch();
    const { editMode, riverStreams } = useAppSelector((state) => state.riverStreams);

    const setManageRiverStreams = (input: boolean) => {
        dispatch(setEditMode(input));
    };

    const { accessToken, profile } = React.useContext(AuthContext);
    const { streams, streamsError, loadingStreams } = useRiverStreams(accessToken, profile?.["https://claims.cimpress.io/canonical_id"]);

    const drawerSize = 0.55;    // Drawer size to display
    let runningProcesses = 0;
    const [searchText, setSearchText] = useState('');
    const [filteredRiverStreams, setFilteredRiverStreams] = useState(filterRiverStreams(streams, searchText));

    useEffect(() => {
        // If user provides any search text, filter the river streams
        setFilteredRiverStreams(filterRiverStreams(streams, searchText));
    }, [streams, searchText, dataProduct]);

    const databaseColumns = [
        {
            Header: 'Add', accessor: '.',
            Cell: (row) => {
                return <div style={{ marginLeft: "-15px" }}>
                    {
                        riverStreams?.some(stream => stream.resourceId === row.original.id)
                            ?   <DeleteInputPortButton
                                    dataProductId={dataProduct.dataProductId}
                                    inputPortType={InputPortTypePath.RiverStreams}
                                    isPublishedDataProduct={dataProduct.published}
                                    useInDataProduct={row.original.useInDataProduct && row.original.useInDataProduct.dataProductId || undefined}
                                    inputPortBody= {{
                                        resourceId: row.original.id,
                                        properties: {
                                            metadataProviderId: MetadataProvider.DataDiscovery
                                        }
                                    }
                                    }
                                    onProcessingStatusChange = {(status: SliceState) => {
                                        if (status === "loading") {
                                            runningProcesses = runningProcesses + 1;
                                        } else {
                                            runningProcesses = runningProcesses - 1;
                                        }
                                    }}
                                    style={{ height: 38, width: 65 }}
                                />
                            :   <AddInputPortButton
                                    dataProductId={dataProduct.dataProductId}
                                    inputPortType={InputPortTypePath.RiverStreams}
                                    useInDataProduct={row.original.useInDataProduct && row.original.useInDataProduct.dataProductId || undefined}
                                    inputPortBody= {{
                                        resourceId: row.original.id,
                                        properties: {}
                                    }}
                                    onProcessingStatusChange = {() => {
                                        if (status === "loading") {
                                            runningProcesses = runningProcesses + 1;
                                        } else {
                                            runningProcesses = runningProcesses - 1;
                                        }
                                    }}
                                    style={{ height: 38, width: 65 }}
                                />
                        }
                    </div> as ReactNode;
            }
        },
        {
            Header: 'Stream Id', accessor: 'id', Cell: (row) => {
                return <a href={`https://console.river.cimpress.io/#/streams/${row.original.id}`} target='__blank' rel="noopener noreferrer">{row.original.id}</a> as ReactNode;
            }
        },
        { Header: 'Stream Name', accessor: 'streamName' },
        { Header: 'Namespace Id', accessor: 'namespaceId' },
        { Header: 'Stream Status', accessor: 'streamStatus' }
    ];
    
    return <>
        <Button className='pull-right' disabled={editMode} variant='primary' onClick={() => setManageRiverStreams(true)}>+/- Manage River Streams</Button>
        <Drawer
            show={editMode}
            onRequestHide={() => setManageRiverStreams(false)}
            size={drawerSize}
            closeOnClickOutside={true}
            footer={(
                <div className="col-xs-12">
                    <Button className='pull-right' onClick={() => setManageRiverStreams(false)}>Close</Button>
                </div>
            )}
        >
            { loadingStreams ?
                <>
                    <Spinner />
                    <p>Loading river streams you own</p>
                </>
            :
                <>
                    <div>
                        <div>
                            <div className='col-xs-7 row'>
                                <h2 style={{margin: 0, paddingLeft: 0}}>Manage <b>River Streams</b></h2>
                                <small style={{color: 'grey'}}>sourced from <a href="https://console.river.cimpress.io" target="_blank" rel="noreferrer">River</a></small>
                            </div>
                            <TextField
                                className='col-xs-5 pull-right'
                                value={searchText}
                                placeholder={'Search for river streams'}
                                helpText={`${filteredRiverStreams.length} streams found`}
                                onChange={(e) => {
                                    if (runningProcesses <= 0) {
                                        setSearchText(e.target.value || '');
                                    }
                                }}
                                style={{margin: 0, padding: 0}}
                                disabled={loadingStreams || !!streamsError}
                            />
                        </div>
                        <Table 
                            hasHover={true}
                            data={filteredRiverStreams}
                            columns={databaseColumns}
                            noDataText={searchText != '' ? 'No river streams available matching the search' : 'No river streams available for this domain.'}
                        />
                    </div>
                </ >
            }
        </Drawer>
    </ >;
};
