import React, { useState } from 'react';
import { Alert, colors, Drawer } from '@cimpress/react-components';
import { IconCheck } from '@cimpress-technology/react-streamline-icons/lib';
import { Button, TextField } from '@cimpress/react-components';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import { renderError, renderLoading } from '../../../shared/Render';
import { setEditMode } from '@cimpress-technology/data-portal-core/lib/features/outputPorts/lookerDashboardsSlice';
import { addSingleLookerOutputPort, removeSingleLookerOuputPort, LookerPatchParams, fetchLookerOutputPorts } from "@cimpress-technology/data-portal-core/lib/features/outputPorts/common";
import { AuthContext } from '../../../../context/authContext';
import { OutputPortComponentEmbedded, OutputPortType, OutputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { AddOutputPortButton, mappedSliceStateToAddPortState } from '../../../shared/outputPorts/AddOutputPortButton';
import { DeleteOutputPortButton, mappedSliceStateToDeletePortState } from '../../../shared/outputPorts/DeleteOutputPortButton';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { SliceState } from '@cimpress-technology/data-portal-core/lib/features/common';

const DRAWER_SIZE = 0.55;
type Props = { dataProductId: string, isPublished: boolean };

export const ManageLookerDashboards = (props: Props): JSX.Element => {
    const { accessToken } = React.useContext(AuthContext);
    const dispatch = useAppDispatch();
    const [dashboardError, setDashboardError] = useState('');
    const [userInput, setUserInput] = useState('');
    const { lookerDashboards, error, status, portDeleteStatus, portDeleteErrors, addError, addStatus, portAddStatus, editMode } = useAppSelector((state) => state.lookerDashboards);


    if (!accessToken) return renderError('Error loading looker information.', new Error('User is not authenticated.'));
    const loadingAnimation = status === "loading" ? renderLoading("Loading Looker HomePages...", true) : null;
    if (error) return renderError('Error loading looker information.', error as Error || new Error('Looker information not found.'));

    const isDashboardIncluded = (dashboardId: string): boolean => {
        return lookerDashboards ? lookerDashboards.map(a => a.resourceId).includes(dashboardId) : true;
    };

    const getParams = (resourceId: string): LookerPatchParams => {
        return {
            accessToken: accessToken,
            dataProductId: props.dataProductId,
            outputPortType: OutputPortTypePath.LookerDashboards,
            outputPortComponent: { resourceId: resourceId, properties: { homePageTitle: "Looker HomePage", sectionTitle: "Data Portal Section" } },
        };
    };

    const setManageLookerDashboards = (input: boolean) => dispatch(setEditMode(input));
    const onDeleteDashboard = (resourceId: string) => dispatch(removeSingleLookerOuputPort(getParams(resourceId)));
    const onAddDashboard = (resourceId: string) => {
        dispatch(addSingleLookerOutputPort(getParams(resourceId))).then((response) => {
            if (response.meta.requestStatus === "fulfilled") {
                dispatch(fetchLookerOutputPorts({
                    accessToken: accessToken,
                    dataProductId: props.dataProductId,
                    outputPortType: OutputPortTypePath.LookerDashboards,
                    isBackgroundGet: true,
                    newResourceId: resourceId
                }));
            }
        });
    };

    const dashboardsBeingAdded = Object.keys(portAddStatus).reduce((accumulator: OutputPortComponentEmbedded<OutputPortType.LookerDashboards>[], current) => {
        if (portAddStatus[current] === "loading")
            accumulator?.push({
                resourceId: current, properties: { homePageTitle: "HomePage Title", sectionTitle: "Section Title" },
                _embedded: { title: "" }
            });
        return accumulator;
    }, []);

    const lookerDashboardsAsElements =
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", gap: 5}}>
            {(lookerDashboards || []).concat(dashboardsBeingAdded).map((dashboard) => (
                <div key={dashboard.resourceId} data-testid="looker-card">
                    <div className='card-block card' style={{ padding: "8px 16px" }} >
                        <header style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <b className="text-primary">Dashboard</b>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {portAddStatus[dashboard.resourceId] && portAddStatus[dashboard.resourceId] === "loading"
                                    ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 60 }}><Spinner size='medium' /></div>
                                    : <DeleteOutputPortButton
                                        dataProductId={props.dataProductId}
                                        outputPortType={OutputPortTypePath.LookerDashboards}
                                        useInDataProduct={''}
                                        outputPortBody={{
                                            resourceId: userInput,
                                            properties: {
                                                sectionTitle: "Section Title",
                                                homePageTitle: "HomePage Title"
                                            }
                                        }}
                                        isPublishedDataProduct={props.isPublished}
                                        onProcessingStatusChange={() => null}
                                        customDeleteFunction={() => onDeleteDashboard(dashboard.resourceId)}
                                        customProcessingStatus={mappedSliceStateToDeletePortState[portDeleteStatus[dashboard.resourceId] || "idle"]}
                                        customErrorMessage={portDeleteErrors[dashboard.resourceId] ? portDeleteErrors[dashboard.resourceId]?.message || "" : ""}
                                        style={{ height: 38, width: 60 }}
                                    />
                                }
                            </div>
                        </header>
                        <div>
                            <div>
                                <b className='text-primary'>URL:</b>
                            </div>
                            <div style={{ overflowWrap: "break-word" }}>
                                {dashboard.resourceId} {portAddStatus[dashboard.resourceId] && portAddStatus[dashboard.resourceId] === "succeeded" &&
                                (<IconCheck weight="fill" style={{ color: colors.success.base }} />)
                            }
                            </div>

                        </div>
                    </div>
                </div>
            ))}
        </div>;

    const alertInformation =
        <Alert
            status='info'
            dismissible={false}
            message={
                <span>
                    Dashboards added here:
                    <ul>
                        <li>Should be from Looker folder where you have manage access.</li>
                        <li>Should use Looker Models that you can explore in Looker.</li>
                        <li>Should not be used in any other published Data product.</li>
                    </ul>
                </span>
            }
        />;


    const onAddFunction = () => {
        if (!userInput) {
            setDashboardError('You cannot have empty Dashboard URL');
            return;
        }

        if (isDashboardIncluded(userInput)) {
            portDeleteErrors[userInput] ? setDashboardError('Dashboard couldn\'t be added before.') : setDashboardError('Dashboard already exists in Homepage');
            return;
        }

        setUserInput('');
        setDashboardError('');
        onAddDashboard(userInput);
    };


    const closeButton = (
        <div className='row'>
            <div className='col-md-10 pull-right'>
                <div style={{ float: 'right' }}>
                    <Button
                        style={{ marginLeft: 5 }}
                        onClick={() => setManageLookerDashboards(false)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </div>);

    const addLookerDashboardBlock = (
        <div className='col-md-12' style={{ display: "flex", paddingBottom: 10 }}>
            <div className='col-md-6 col-sm-10' style={{ paddingRight: 5, paddingLeft: 0 }}>
                <TextField
                    label='Looker Dashboard URL'
                    helpText={dashboardError}
                    value={userInput}
                    status={dashboardError ? 'warning' : undefined}
                    onChange={(e) => setUserInput(e.target.value)}
                />
            </div>
            <div className='col-md-6 col-sm-2' style={{ paddingLeft: 0 }}>
                <AddOutputPortButton
                    dataProductId={props.dataProductId}
                    outputPortType={OutputPortTypePath.LookerDashboards}
                    outputPortBody={{
                        resourceId: userInput,
                        properties: {
                            sectionTitle: "Section Title",
                            homePageTitle: "HomePage Title"
                        }
                    }}
                    useInDataProduct={""}
                    onProcessingStatusChange={() => null}
                    customAddFunction={onAddFunction}
                    customErrorMessage={addError ? addError.message || "" : ""}
                    customProcessingStatus={mappedSliceStateToAddPortState[((input: SliceState) => input === "failed" ? input : "idle")(addStatus)]}
                    style={{ height: 48, width: 70 }}
                />
            </div>
        </div>);

    return (
        <Drawer
            show={editMode}
            footer={closeButton}
            size={DRAWER_SIZE}
            closeOnClickOutside={true}
            onRequestHide={() => setManageLookerDashboards(false)}
        >
            <div>
                <div>
                    <div className='row' style={{ paddingBottom: 30 }}>
                        <div className="col-md-12">
                            <h2 style={{ margin: 0 }}>Manage your <b>Looker</b> Dashboards</h2>
                        </div>
                    </div>
                    <div className="row" style={{ paddingBottom: 20 }}>
                        <div className='col-md-12' style={{ paddingBottom: 5 }}>
                            <span style={{ paddingRight: 10 }}>Add a Dashboard</span>
                        </div>

                        {loadingAnimation ? loadingAnimation : addLookerDashboardBlock}
                        <div className='col-md-12'>
                            {lookerDashboardsAsElements}
                        </div>
                    </div>
                </div>
                {alertInformation}
            </div>
        </Drawer>
    );
};
