import React from "react";
import DOMPurify from "dompurify";

type Props = { value: string };

const CustomFieldValueView = (props: Props) => {
    const valueToRender = (props.value || "").length > 0 ? props.value : "-- --";
    return (
        <div
            style={{ wordWrap: "break-word" }}
            id="custom-field-value-container"
            className="summary__custom-fields__value"
            role="presentation"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(valueToRender) }}
        ></div>
    );
};

export default CustomFieldValueView;
