/** Main components */
import React, { useEffect, useState, useMemo } from "react";
import IconArrowDownAlt from "@cimpress-technology/react-streamline-icons/lib/IconArrowDownAlt";
import { Button, Toggle } from "@cimpress/react-components";
import Welcome from "./components/Welcome";
import DpDropDown from "./components/dropdown/DropDown";
import DpSelect from "./components/select/Select";
import { useAppSelector } from "../../../store/storeHooks";
import {
    DataProductListItem,
    Domain,
} from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { Interfaces, Hooks } from "@cimpress-technology/data-portal-core";

import { DataProductCard } from "./components/DataProductCard";
import RecentlyViewed from "./components/RecentlyViewed";
import SearchLeft from "../../../assets/SearchLeft.svg";
import SearchRight from "../../../assets/SearchRight.svg";
import Loading from "./components/Loading";
import useSegment from "../../../hooks/useSegment";
import {
    AccountCollection,
    AccountIdToDomains,
} from "./components/utils/customTypes";
import useAccountDetails from "../../../hooks/useAccountDetails";
import useUserCoamInfoCache from "../../../hooks/useUserCoamInfoCache";
import { buildAuthorInfo, determineBackground } from "./components/utils/utils";
import useFirstProductTeamMember from "../../../hooks/useFirstProductTeamMember";
import useCalculateSortedFiltered from "./hooks/useCalculateSortedFiltered";

const analyticalFilters = {
    looker: "discovery__analytical-looker",
};

const getAnalyticalLookerFilterSetting = () => {
    const onlyPublished = localStorage.getItem(analyticalFilters.looker);
    if (onlyPublished) {
        return onlyPublished === "true";
    }
    return null;
};

const setAnalyticalLookerFilterSetting = (value: boolean) => {
    localStorage.setItem(analyticalFilters.looker, value.toString());
};

const buildAccountIdToDomainsFrom = (
    domains: Interfaces.DataPortalAPI.Domain[] | null,
    dataProductsList: Interfaces.DataPortalAPI.DataProductListItem[] | null
): AccountIdToDomains | null => {
    if (!domains || domains.length === 0) return null;
    const accountIdToDomains: AccountIdToDomains = {};
    const dataProductsCount = {};
    (dataProductsList || []).forEach(
        (d) =>
            (dataProductsCount[d.domainId] =
                (dataProductsCount[d.domainId] || 0) + 1)
    );

    (domains || []).forEach((d) => {
        if (!accountIdToDomains[d.accountId]) {
            accountIdToDomains[d.accountId] = [];
        }
        accountIdToDomains[d.accountId].push({
            domain: d,
            dataProductsCount: dataProductsCount[d.domainId] || 0,
        });
    });
    return accountIdToDomains;
};

const calculateAccountNamesSorted = (
    accounts: AccountCollection,
    userAccountId: string | undefined
) => {
    if (!userAccountId) return [];
    const currentAccounts = Object.keys(accounts);
    const cimpressAccountId = "g2Ez5VaoZWoqU22XqPjTLU";
    const startSequence: string[] = [];
    if (userAccountId && currentAccounts.includes(userAccountId))
        startSequence.push(userAccountId);
    if (
        userAccountId &&
        currentAccounts.includes(userAccountId) &&
        userAccountId !== cimpressAccountId
    )
        startSequence.push(cimpressAccountId);
    return startSequence.concat(
        currentAccounts
            .filter((biz) => !startSequence.includes(biz))
            .sort((a, b) => {
                if (accounts[a].name < accounts[b].name) return -1;
                if (accounts[a].name > accounts[b].name) return 1;
                return 0;
            })
    );
};

const buildDomainOptionListForAccountId = (
    domains: Domain[] | null,
    selectedAccountId: string | null,
    accountIdToDomains: AccountIdToDomains | null
) => {
    if (
        !selectedAccountId ||
        !domains ||
        !accountIdToDomains ||
        !Object.keys(accountIdToDomains).includes(selectedAccountId)
    )
        return [];
    const domainDpCount = new Map<string, number>();
    accountIdToDomains[selectedAccountId].forEach((o) =>
        domainDpCount.set(o.domain.domainId, o.dataProductsCount)
    );
    return [{ value: "all", label: "All" }].concat(
        (domains || [])
            .filter((o) => o.accountId === selectedAccountId && domainDpCount.get(o.domainId) !== 0)
            .map((o) => ({
                value: o.domainId,
                label: `${o.name} (${domainDpCount.get(o.domainId)})`,
            }))
    );
};

type Props = {
    accessToken?: string;
    userAccountId?: string;
    userName?: string;
    recentlyViewed: DataProductListItem[] | null;
    openThisPath: string;
    storeVisitIn: string;
    TopLeftAddon: JSX.Element | null;
    backgroundSettings: {
        storageName: string;
        options: string[];
    };
    showPublishedMark: boolean;
};
const Discovery = (props: Props): JSX.Element => {
    const { dataProductList, status: dataProductListStatus } = useAppSelector(
        (state) => state.dataProducts
    );
    const { loadingDomains, domains } = Hooks.useDomains(props.accessToken);

    const { trackEvent } = useSegment();
    const [searchInput, setSearchInput] = useState<string>("");
    const [selectedDomainId, setSelectedDomainId] = useState<string | null>(
        null
    );
    const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
        null
    );
    const [analyticalLookerFilter, setAnalyticalLookerFilter] = useState<
        boolean | null
    >(getAnalyticalLookerFilterSetting());

    const [resultsNumber, setResultsNumber] = useState<number>(12);

    const [appended, setAppended] = useState(false);

    const accountIdToDomains = useMemo(
        () => buildAccountIdToDomainsFrom(domains, dataProductList),
        [domains, dataProductList]
    );

    const dropDownDomainItems = useMemo(
        () =>
            buildDomainOptionListForAccountId(
                domains,
                selectedAccountId,
                accountIdToDomains
            ),
        [domains, selectedAccountId, accountIdToDomains]
    );

    const { accountsInfo, status: accountsStatus } = useAccountDetails({
        accessToken: props.accessToken,
        needTheseAccountIds:
            accountIdToDomains != null ? Object.keys(accountIdToDomains) : [],
    });

    const orderedAccounts = useMemo(
        () =>
            accountsStatus === "succeeded"
                ? calculateAccountNamesSorted(accountsInfo, props.userAccountId)
                : [],
        [accountsInfo, accountsStatus, props.userAccountId]
    );

    useEffect(() => {
        if (orderedAccounts.length > 0)
            setSelectedAccountId(orderedAccounts[0]);
    }, [orderedAccounts]);

    const { dataProductsResultSet } = useCalculateSortedFiltered({
        searchInput,
        accountFilters: { selectedAccountId, accountIdToDomains },
        domainFilters: { selectedDomainId },
        onlyPublished: null,
        authorCanonicalId: null,
        analyticalFilters: { looker: analyticalLookerFilter },
    });

    const { firstMemberInfo, needCoamDataForTheseCanonicalIds } =
        useFirstProductTeamMember({
            accessToken: props.accessToken,
            dataProductIds: (dataProductsResultSet || [])
                .map((o) => o.dataProductId)
                .slice(
                    0,
                    Math.min(
                        resultsNumber,
                        (dataProductsResultSet || []).length
                    )
                )
                .concat(
                    (props.recentlyViewed || []).map((o) => o.dataProductId)
                ),
        });

    const { usersCoamInfo } = useUserCoamInfoCache({
        accessToken: props.accessToken,
        needDataForTheseCanonicalIds: needCoamDataForTheseCanonicalIds,
    });

    const onChangeDomain = (e: React.FormEvent<HTMLSelectElement>) => {
        const domainId = e.currentTarget.value;

        setSelectedDomainId(domainId);
        trackEvent("Discovery page: filtered by domain", {
            accountId: selectedAccountId ?? undefined,
            accountName:
                selectedAccountId != null && accountsStatus === "succeeded"
                    ? accountsInfo[selectedAccountId].name
                    : undefined,
            domainId: domainId,
            domainName:
                (domains &&
                    domains.length &&
                    domains.find((o) => o.domainId === domainId)?.name) ||
                undefined,
        });
    };

    const onClickDomain = () => {
        if(!appended){
            dropDownDomainItems[0].label = dropDownDomainItems[0].label + ' (excluding domains without products)';
            setAppended(true);
        }
    };

    const onBlurDomain = () => {
        if (appended) {
            dropDownDomainItems[0].label = 'All';
            setAppended(false);
        }
      };

    const onChangeAccountId = (accountId: string) => {
        setSelectedAccountId(accountId);
        setSelectedDomainId(null);
        trackEvent("Discovery page: filtered by accountId", {
            accountId: accountId,
            accountName:
                accountsStatus === "succeeded"
                    ? accountsInfo[accountId].name
                    : undefined,
        });
    };

    // we track an event of using search, but after a bit of delay to avoid too many events
    useEffect(() => {
        if (searchInput) {
            const timeout = setTimeout(
                () =>
                    trackEvent("Discovery page: searched data product", {
                        search: searchInput,
                    }),
                1000
            );
            return () => clearTimeout(timeout);
        }
    }, [searchInput, trackEvent]);

    const getProductCountPerAccount = (accountId: string) => {
        if (
            accountIdToDomains &&
            Object.keys(accountIdToDomains).includes(accountId)
        )
            return accountIdToDomains[accountId]
                .map((o) => o.dataProductsCount)
                .reduce((acc, current) => acc + current, 0);
        return 0;
    };

    const dataProductsToShow = dataProductsResultSet || [];
    return (
        <>
            <div className="section-jumbo__container">
                <section
                    className="section-jumbo"
                    style={{
                        background: `url(${determineBackground(
                            props.backgroundSettings.storageName,
                            props.backgroundSettings.options
                        )}) no-repeat`,
                        backgroundPosition: "100% 20%",
                        backgroundSize: "cover",
                    }}
                >
                    {props.TopLeftAddon}
                    <img className="section-jumbo--left" src={SearchLeft} />
                    <img className="section-jumbo--right" src={SearchRight} />
                    <div className="section-jumbo__bottom">
                        <span>Results sorted scientifically</span>
                    </div>
                    <div className="section-jumbo__content">
                        <Welcome user={props.userName} />
                        <div className="search-bar">
                            <DpDropDown
                                trigger={
                                    <button
                                        className="search-bar__account-button"
                                        disabled={
                                            orderedAccounts.length > 0
                                                ? false
                                                : true
                                        }
                                    >
                                        {selectedAccountId &&
                                        accountsStatus === "succeeded"
                                            ? accountsInfo[selectedAccountId]
                                                  .name
                                            : "Account"}{" "}
                                        <span>
                                            <IconArrowDownAlt weight="fill" />
                                        </span>
                                    </button>
                                }
                                menu={
                                    (orderedAccounts &&
                                        orderedAccounts.map((accountId) => (
                                            <button
                                                key={accountId}
                                                onClick={() =>
                                                    onChangeAccountId(accountId)
                                                }
                                            >
                                                {accountsStatus === "succeeded"
                                                    ? accountsInfo[accountId]
                                                          .name
                                                    : "Error"}{" "}
                                                <small>
                                                    {getProductCountPerAccount(
                                                        accountId
                                                    )}
                                                </small>
                                            </button>
                                        ))) ||
                                    []
                                }
                            />
                            <input
                                type="text"
                                className="search-bar__search-input"
                                placeholder="What would you like to know?"
                                onChange={(e) => {
                                    setSearchInput(e.currentTarget.value);
                                }}
                                value={searchInput}
                            />
                        </div>
                        <div className="filters-bar">
                            <DpSelect
                                value={selectedDomainId}
                                isDisabled={
                                    dropDownDomainItems.length > 0
                                        ? false
                                        : true
                                }
                                options={dropDownDomainItems}
                                onChange={(e) => onChangeDomain(e)}
                                selfAdjust={true}
                                onClick={() => onClickDomain()}
                                onBlur={() => onBlurDomain()}
                            />
                            <div className="filters-bar__analytical">
                                <DpDropDown
                                    trigger={
                                        <button
                                            className="filters-bar__analytical-btn"
                                            disabled={false}
                                        >
                                            Analytical Filters {analyticalLookerFilter === true ? <>&#10003;</> : ''}

                                            <span>
                                                <IconArrowDownAlt weight="fill" />
                                            </span>
                                        </button>
                                    }
                                    menu={[
                                        <div
                                            className="filters-bar__analytical-btn__option"
                                            key="looker-filter"
                                            onClick={() => {
                                                return;
                                            }}
                                        >
                                            <Toggle
                                                on={
                                                    analyticalLookerFilter ===
                                                    true
                                                }
                                                size="sm"
                                                onClick={() => {
                                                    setAnalyticalLookerFilter(
                                                        !analyticalLookerFilter
                                                    );
                                                    setAnalyticalLookerFilterSetting(
                                                        !analyticalLookerFilter
                                                    );
                                                }}
                                            />{" "}
                                            Has Dashboards
                                        </div>,
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="discovery__results">
                {loadingDomains ||
                dataProductListStatus === "loading" ||
                accountsStatus === "loading" ? (
                    <Loading />
                ) : (
                    <div className="discovery__results__items">
                        {dataProductsToShow.length === 0 && (
                            <div>No results</div>
                        )}
                        {dataProductsToShow
                            .slice(
                                0,
                                Math.min(
                                    resultsNumber,
                                    dataProductsToShow.length
                                )
                            )
                            .map((o) => {
                                return (
                                    <DataProductCard
                                        key={o.dataProductId}
                                        dataProductId={o.dataProductId}
                                        name={o.dataProductName}
                                        description={o.summary}
                                        domainId={o.domainId}
                                        published={
                                            props.showPublishedMark === true
                                                ? o.published
                                                : undefined
                                        }
                                        authorInfo={buildAuthorInfo(
                                            firstMemberInfo[o.dataProductId],
                                            usersCoamInfo
                                        )}
                                        lastModified={o.updatedAt}
                                        outputPorts={o.outputPorts}
                                        openThisPath={props.openThisPath}
                                        storeVisitIn={props.storeVisitIn}
                                        onClickTracking={(
                                            dataProductId: string,
                                            dataProductName: string
                                        ) => {
                                            trackEvent(
                                                "Discovery page: click on Data Product tile",
                                                {
                                                    dataProductId:
                                                        dataProductId,
                                                    dataProductName:
                                                        dataProductName,
                                                }
                                            );
                                        }}
                                    />
                                );
                            })}
                    </div>
                )}
            </section>
            <div className="center">
                {dataProductsToShow.length > 0 &&
                    resultsNumber < dataProductsToShow.length && (
                        <Button
                            style={{ marginBottom: 10 }}
                            onClick={() => {
                                setResultsNumber(
                                    Math.min(
                                        resultsNumber + 12,
                                        dataProductsToShow.length
                                    )
                                );
                            }}
                        >
                            View More (Showing{" "}
                            {Math.min(resultsNumber, dataProductsToShow.length)}{" "}
                            of {dataProductsToShow.length})
                        </Button>
                    )}
            </div>
            {loadingDomains === false && (
                <RecentlyViewed
                    recentlyViewed={props.recentlyViewed}
                    firstMemberInfo={firstMemberInfo}
                    usersCoamInfo={usersCoamInfo}
                    openThisPath={props.openThisPath}
                    storeVisitIn={props.storeVisitIn}
                    showPublishedMark={props.showPublishedMark}
                />
            )}
            <div className="center"></div>
        </>
    );
};

export default Discovery;
