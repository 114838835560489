import { useCallback } from "react";
import { useAppDispatch } from "./storeHooks";
import { fetchDataProductCustomFields } from "@cimpress-technology/data-portal-core/lib/features/customFields/common";

type callbackType = () => void;
export const useMemoizedLoaderForCustomFields = (
    accessToken: string | undefined,
    dataProductId: string | undefined
): callbackType => {
    const dispatch = useAppDispatch();
    const fetchCustomFields = useCallback(() => {
        if (accessToken && dataProductId) {
            dispatch(
                fetchDataProductCustomFields({
                    accessToken: accessToken,
                    dataProductId: dataProductId,
                })
            );
        }
    }, [accessToken, dataProductId, dispatch]);
    return fetchCustomFields;
};
