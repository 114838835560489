import React, {useCallback, useContext, useEffect, useRef} from "react";
import {Interfaces} from "@cimpress-technology/data-portal-core";
import {useIssueTrackerModule} from "./useIssueTrackerModule";
import {AuthContext} from "../../context/authContext";
import useSegment from "../../hooks/useSegment";

// Globally declare 'dataportalresources' object to access modules later
declare global {
    interface Window {
        // eslint-disable-next-line
        dataportalresources?: any;
    }
}

// Prop types for Issue Tracker Modules. Includes optional data product ID, dataset metadata, history object, access token & router props.
interface IssueTrackerModuleProps {
    dataProductId?: string;
    dataset?: Interfaces.DataDiscovery.DataDiscoveryDatasetMetadata;
    history?;
    routerProps?;
    authContext?;
}

// Function to retrieve a specific module given its name and properties. This function handles the tasks necessary for the module to render properly.
const GetModule = (name: string, props: IssueTrackerModuleProps) => {
    const authContext = useContext(AuthContext);
    const accessToken = authContext.accessToken;
    const { dataProductId, dataset, history } = props;
    const routerProps = props;
    const { trackEvent } = useSegment();

    // If the component we are trying to render is not already loaded, we ask to load it!
    const shouldForceLoadBundleNow = !window.dataportalresources || !window.dataportalresources["IssueTracker"];
    // Inject the module, but using a hook to avoid duplicating injection and to have the chance to optimize by preloading.
    const {loadingBundle} = useIssueTrackerModule(shouldForceLoadBundleNow);

    // Create a callback function to render a given module under a certain DOM element, only if both render function and DOM element are defined.
    const renderUnderParentElement = useCallback(async (domElement) => {
        if (loadingBundle) {
            console.debug(`Still loading...[${loadingBundle}]`);
            console.debug(window.dataportalresources);
            return;
        }

        if (!window.dataportalresources?.["IssueTracker"]) {
            console.error("We are no longer loading the bundle for IssueTracker and yet it is not defined on the DOM! Did the request fail?");
            return;
        }

        console.debug("Bundle loading complete");
        const renderFunction = window.dataportalresources["IssueTracker"][name]?.render;
        if (renderFunction && domElement) {
            await renderFunction(domElement, {dataProductId, dataset, history, routerProps, accessToken, trackEvent, authContext}); //Props passed to the module when loading
        }
    }, [dataProductId, dataset, history, routerProps, name, loadingBundle, accessToken, trackEvent, authContext]);

    // Inside rendering function, wraps the parent DOM element that the selected module will be rendered under.
    const RenderedModule = () => {
        const domElement = useRef(null);
        useEffect(() => {
            domElement.current && renderUnderParentElement(domElement.current);
        }, []);

        return <div ref={domElement}/>;
    };
    // Set the display name of RenderedModule based on the name argument
    RenderedModule.displayName = name;

    return <RenderedModule/>;
};

// Function to create an Issue Tracker Module
const createIssueTrackerModuleComponent = (name: string): React.FC<IssueTrackerModuleProps> => {
    const IssueTrackerModule: React.FC<IssueTrackerModuleProps> = React.memo((props) => {
        return GetModule(name, props);
    });
    IssueTrackerModule.displayName = name;
    return IssueTrackerModule;
};

// These are the specific modules that are exported for use. They all utilize GetModule to render properly.
export const DataIssueCounterModule = createIssueTrackerModuleComponent("DataIssueCounterModule");
export const DataIssueTrackerDetailsSectionModule = createIssueTrackerModuleComponent("DataIssueTrackerDetailsSectionModule");
export const ReportAnIssueModule = createIssueTrackerModuleComponent("ReportAnIssueModule");
export const IssueManagementModule = createIssueTrackerModuleComponent("IssueManagementModule");