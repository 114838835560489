import { Button, colors, Tooltip } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { IconAlertTriangle, IconAdd } from '@cimpress-technology/react-streamline-icons/lib';
import React, { useEffect, useState } from 'react';
import { AuthContext } from '../../../context/authContext';
import { InputPortComponent, InputPortType, InputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import axios from 'axios';
import { SliceState } from '@cimpress-technology/data-portal-core/lib/features/common';
import { useAppSelector, useAppDispatch } from '../../../store/storeHooks';
import { addSingleInputPort } from '@cimpress-technology/data-portal-core/lib/features/inputPorts/common';


export const AddInputPortButton: React.FC<{
    dataProductId: string;
    inputPortType: InputPortTypePath;
    inputPortBody: InputPortComponent<InputPortType>;
    useInDataProduct: string;
    onProcessingStatusChange: (status: SliceState) => void;    
    style?: React.CSSProperties;
    customAddFunction?: () => void;
    customProcessingStatus?: SliceState;
    customErrorMessage?: string;
}> = ({ dataProductId, inputPortType, inputPortBody, useInDataProduct, onProcessingStatusChange, customAddFunction, customErrorMessage, style }) => {
    
    const dispatch = useAppDispatch();
    const { portAddStatus, portAddErrors, riverStreams } = useAppSelector(state => state.riverStreams);
    const defaultErrorMessage = 'There was an Error adding this input Port. Input port might have already been added. Refresh and Try again or contact #data-domain-support';
    const { accessToken } = React.useContext(AuthContext);
    const [ errorAdding, setErrorAdding ] = useState(customErrorMessage ? customErrorMessage : "");

    useEffect(() => {
        if (customErrorMessage) setErrorAdding(customErrorMessage);
    }, [customErrorMessage]);

    const addInputPortOnClick = async (inputPortType: InputPortTypePath, inputPortBody: InputPortComponent<InputPortType>, dataProductId: string) => {
        setErrorAdding('');
        onProcessingStatusChange("loading");
        try {
            if (accessToken) {
                dispatch(addSingleInputPort({ accessToken: accessToken, dataProductId: dataProductId, inputPortType: inputPortType, inputPortComponent: inputPortBody }));
                onProcessingStatusChange("succeeded");
            } else {
                setErrorAdding('Authorization token not found. Try again after refreshing the page');
            }
        } catch(error) {
            let errorMessage = defaultErrorMessage;
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data.message || error.response?.statusText || error.message;
            }
            onProcessingStatusChange("failed");
            setErrorAdding(errorMessage);
        }
    };

    const mainPaddingBottom = portAddStatus[inputPortBody.resourceId] === "failed" ? 5 : 0; // A little bit more of space for the triangle

    return <div style={{...style}}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" , height: "100%", width: "100%", paddingBottom: mainPaddingBottom }}>
                { 
                    portAddStatus[inputPortBody.resourceId] === "loading"
                        ?   <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}><Spinner size='medium' /></div>
                        : riverStreams?.every(stream => stream.resourceId !== inputPortBody.resourceId)
                            ?   <>
                                    <Tooltip direction='left' style={{ height: "100%", width: "100%" }} show={useInDataProduct ? undefined : false} contents={
                                        <span>The input port is already used in a Published Data product<a href={`/dataProducts/${useInDataProduct}`} target="_blank" rel="noreferrer">{useInDataProduct}</a>. You cannot add this as input port in your Data Product</span>
                                    }>

                                        <Button
                                            variant='default'
                                            style={{ height: "100%", width: "100%" }}
                                            disabled={useInDataProduct ? true : false}
                                            onClick={() => customAddFunction ? customAddFunction() : addInputPortOnClick(inputPortType, inputPortBody, dataProductId)}
                                        >
                                            <IconAdd weight="fill" />
                                        </Button>
                                        {
                                            portAddStatus[inputPortBody.resourceId] === "failed" ?
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <Tooltip contents={portAddErrors[inputPortBody.resourceId] ? portAddErrors[inputPortBody.resourceId]?.message :  errorAdding}>
                                                        <IconAlertTriangle weight="fill" style={{color: colors.warning.base}} />
                                                    </Tooltip>
                                                </div>
                                            : null
                                        }
                                    </Tooltip>
                                </>
                        :   null
                }
                </div>
            </div>;
};
