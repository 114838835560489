import React from 'react';
import { Card, colors, Button } from '@cimpress/react-components';

export interface DomainService {
    name: string;
    shortName?: string;
    link: string;
    description: string;
}

export const DomainServiceItem: React.FC<{
    service: DomainService | undefined
}> = ({ service }) => {

    if (!service) {
        return null;
    }

    return <Card
        style={{ marginTop: 20, minHeight: 220 }}>
        <h3 style={{ marginTop: 6 }}>{service.name}</h3>
        <hr />
        <p style={{ color: colors.shale }}>{service.description}</p>
        <Button target="_blank" href={service.link} >Go to {service.shortName || service.name}</Button>
    </Card>;
};