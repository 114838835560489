import React from 'react';
import { Button, Card, colors } from '@cimpress/react-components';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";

export const DataProductItem: React.FC<{ dataProduct: DataPortalAPI.DataProductListItem; isAdmin?: boolean }> = ({ dataProduct, isAdmin }) => {
    if (!dataProduct) {
        return null;
    }

    const summary = dataProduct.summary.length > 250
        ? `${dataProduct.summary.substring(0, 250)}...`
        : dataProduct.summary;

    const publishedLabel = isAdmin
        ? dataProduct.published
            ? <small style={{ float: 'right', fontWeight: 'bold', color: colors.success.base }}>Published</small>
            : <small style={{ float: 'right', fontWeight: 'bold', color: colors.danger.base }}>Unpublished</small>
        : null;


    return <div>
        <Card className={`data-product-item`} style={{ wordBreak: 'break-all' }}>
            {publishedLabel}

            <h4 style={{ marginTop: 6, marginBottom: 6 }}>{dataProduct.dataProductName}</h4>
            <hr />
            <p style={{ color: colors.shale }}>{summary}</p>

            <Button size='sm' onClick={() => {
                window.open(`/dataproducts/${dataProduct.dataProductId}`, '_blank', 'noreferrer');
            }}>See product</Button>
        </Card>
    </div>;
};
