import { Hooks } from "@cimpress-technology/data-portal-core";
import { SliceState } from "../features/common";

import { useAppDispatch, useAppSelector } from "../store/storeHooks";
import { useEffect, useMemo, useState } from "react";
import { fetchDataProductMembers } from "@cimpress-technology/data-portal-core/lib/features/members/common";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import {
    IdleState,
    LoadingState,
    ErrorState,
    SuccessState,
    DataProductCoamUser,
} from "./dataProductMemberHook/interfaces";
import {
    CoamMembersMapBuilder,
    CoamMembersMapWithProductTeamComposer,
    MembersMapWithPreviousStateComposer,
    getMemberListWithoutClients,
} from "./dataProductMemberHook/logic";

type ReturnType = IdleState | LoadingState | SuccessState | ErrorState;

type Props = {
    accessToken?: string;
    dataProductInfo: {
        dataProductId: string;
        adminCoamGroupId?: string;
        readCoamGroupId?: string;
    };
};

export const useDataProductMembers = (props: Props): ReturnType => {
    const {
        members,
        status: membersStatus,
        error: membersError,
    } = useAppSelector((state) => state.members);
    const { dataProductId } = props.dataProductInfo;
    const { accessToken } = props;
    const dispatch = useAppDispatch();
    const [extendedMembersMap, setExtendedMembersMap] = useState<Record<
        string,
        DataProductCoamUser
    > | null>(null);
    /**
     *
     * @param key - will be transformed to lowercase
     * @param value
     */
    const addItemToMap = (key: string, value: DataProductCoamUser) => {
        setExtendedMembersMap((prevState) => ({
            ...prevState,
            [key.toLowerCase()]: value,
        }));
    };
    const removeItemFromMap = (key: string) => {
        setExtendedMembersMap((prevState) => {
            if (!prevState) {
                return prevState;
            }
            if (!prevState[key.toLowerCase()]) {
                return prevState;
            }
            const newState = { ...prevState };
            delete newState[key.toLowerCase()];
            return newState;
        });
    };
    const adminCoamGroupHook = Hooks.useCoamGroup(
        accessToken,
        props.dataProductInfo.adminCoamGroupId
    );
    const readCoamGroupHook = Hooks.useCoamGroup(
        accessToken,
        props.dataProductInfo.readCoamGroupId
    );

    useEffect(() => {
        if (!accessToken || !dataProductId) {
            return;
        }
        dispatch(
            fetchDataProductMembers({
                accessToken,
                dataProductId: dataProductId,
            })
        );
    }, [accessToken, dataProductId, dispatch]);

    const coamMembersMap = useMemo(() => {
        if (adminCoamGroupHook.coamGroup && readCoamGroupHook.coamGroup) {
            return new CoamMembersMapBuilder().build(
                adminCoamGroupHook.coamGroup,
                readCoamGroupHook.coamGroup
            );
        } else {
            return null;
        }
    }, [adminCoamGroupHook.coamGroup, readCoamGroupHook.coamGroup]);

    useEffect(() => {
        if (coamMembersMap) {
            setExtendedMembersMap((prevState) => {
                return new MembersMapWithPreviousStateComposer(new CoamMembersMapWithProductTeamComposer(
                    coamMembersMap
                ).compose(members), prevState, members).compose();
            });
        }
    }, [members, coamMembersMap]);

    if (!accessToken) {
        return {
            status: "failed",
            members: null,
            membersMap: null,
            error: new Error("No access token provided"),
            removeItemFromMap: null,
            addItemToMap: null,
            refresh: () => null,
        };
    }

    const status = ((): SliceState => {
        if (
            membersStatus === "loading" ||
            adminCoamGroupHook.loadingCoamGroup === true ||
            readCoamGroupHook.loadingCoamGroup === true
        ) {
            return "loading";
        }
        if (
            membersError ||
            adminCoamGroupHook.coamGroupError ||
            readCoamGroupHook.coamGroupError
        ) {
            return "failed";
        }
        return membersStatus;
    })();

    const refresh = () => {
        adminCoamGroupHook.loadCoamGroup(true);
        readCoamGroupHook.loadCoamGroup(true);
        dispatch(
            fetchDataProductMembers({
                accessToken,
                dataProductId: dataProductId,
            })
        );
    };

    switch (status) {
        case "idle":
            return {
                status: "idle",
                members: getMemberListWithoutClients(extendedMembersMap || {}),
                membersMap: extendedMembersMap || {},
                error: null,
                removeItemFromMap,
                addItemToMap,
                refresh,
            };
        case "loading":
            return {
                status: "loading",
                members: null,
                membersMap: null,
                error: null,
                removeItemFromMap: null,
                addItemToMap: null,
                refresh: null,
            };
        case "succeeded":
            return {
                status: "succeeded",
                members: getMemberListWithoutClients(extendedMembersMap || {}),
                membersMap: extendedMembersMap || {},
                error: null,
                removeItemFromMap,
                addItemToMap,
                refresh,
            };
        case "failed":
            return {
                status: "failed",
                members: null,
                membersMap: null,
                error:
                    adminCoamGroupHook.coamGroupError ||
                    readCoamGroupHook.coamGroupError ||
                    new Error(
                        parseUnknownErrorTypeToErrorMessage(membersError)
                    ),
                removeItemFromMap: null,
                addItemToMap: null,
                refresh,
            };
    }
};
