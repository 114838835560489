import { OutputPortTypePath } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { fetchLookerOutputPorts } from "@cimpress-technology/data-portal-core/lib/features/outputPorts/common";
import { cleanDashboards, cleanOperationStatusAndErrors } from "@cimpress-technology/data-portal-core/lib/features/outputPorts/lookerDashboardsSlice";

// Callbacks memoize a function. Then, when we use this function inside `useEffect`, it won't be triggered on each re-render because the memoized function will persist.
// It is also a good way to abstract functionality

type callbackType = () => void;
type memoizedFn = (token: string | undefined, dpId: string) => callbackType;
export const useMemoizedLoaderForLookerDashboard: memoizedFn = (accessToken, dataProductId) => {
    const dispatch = useAppDispatch();
    const lookerDashboardsState = useAppSelector(state => state.lookerDashboards);
    const fetchLookerDashboards = useCallback(() => {
        if (accessToken && dataProductId && (lookerDashboardsState.lookerDashboards === null || lookerDashboardsState.dataProductId === null || lookerDashboardsState.dataProductId !== dataProductId)) {
            // Only fetch when the dashboards value is null (not previously rendered) OR when entering a into a Data Product for the first time OR when we have changed Data Product
            dispatch(fetchLookerOutputPorts({ accessToken: accessToken, dataProductId: dataProductId, outputPortType: OutputPortTypePath.LookerDashboards }));
        }
    }, [dispatch, accessToken, dataProductId, lookerDashboardsState.dataProductId, lookerDashboardsState.lookerDashboards]);
    return fetchLookerDashboards;
};

type memoizedClean = (currentDpId: string) => callbackType;
export const useMemoizedCleanErrorsForLookerDashboard: memoizedClean = (dataProductId) => {
    const dispatch = useAppDispatch();
    const lookerDashboardsState = useAppSelector(state => state.lookerDashboards);
    const clean = useCallback(() => {
        if (lookerDashboardsState.dataProductId !== dataProductId)
            dispatch(cleanOperationStatusAndErrors()); // Clean Errors and Status for Looker Dashboards when we enter a different Data Product
    }, [dispatch, lookerDashboardsState.dataProductId, dataProductId]);
    return clean;
};

// The user might switch quickly between components, while an async is still running.
// This function makes sure that the result from the async is cleaned in the component where we use this function.
export const useMemoizedKeepItCleanForLookerDashboard = ():callbackType => {
    const dispatch = useAppDispatch();
    const lookerDashboardsState = useAppSelector(state => state.lookerDashboards);
    const clean = useCallback(() => {
        if (lookerDashboardsState.lookerDashboards !== null) dispatch(cleanDashboards());
    }, [dispatch, lookerDashboardsState.lookerDashboards]);
    return clean;
};