import React from 'react';

type Props = { user: string | undefined };

const Welcome = (props: Props): JSX.Element => {
  return (
    <div className='section-jumbo__welcome'>
      <h2>
        Hello {props.user || "..."}!
      </h2>
      <p>
        Data Portal provides a platform for creating, publishing and accessing data products for Cimpress teams and businesses.
      </p>
    </div>
  );
};

export default Welcome;