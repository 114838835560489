import React from "react";
import { colors } from "@cimpress/react-components";
import { useAppSelector } from "../../store/storeHooks";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { AuthContext } from "../../context/authContext";
import DataProductCustomFieldEditable from "./customFields/edit/DataProductCustomFieldEditable";
import DataProductCustomFieldView from "./customFields/view/DataProductCustomFieldView";

const NonEditableHeader = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <h3 style={{ color: colors.shale }}>Additional Information</h3>
        </div>
    );
};

type Props = { hasDataProductPermission: boolean; dataProductId: string };
const DataProductCustomFields = (props: Props) => {
    const { status, customFields } = useAppSelector(
        (state) => state.customFields
    );
    const { accessToken } = React.useContext(AuthContext);

    const canEdit = props.hasDataProductPermission && accessToken !== undefined;

    if (!customFields || (customFields && Object.keys(customFields).length === 0))
        return null;

    return (
        <div style={{ paddingBottom: 40 }}>
            <NonEditableHeader />
            <hr></hr>
            {status === "loading" && <Spinner size="small" />}
            {status === "succeeded" &&
                customFields &&
                Object.keys(customFields).map((key) =>
                    canEdit ? (
                        <DataProductCustomFieldEditable
                            key={key}
                            customField={customFields[key]}
                            accessToken={accessToken}
                            dataProductId={props.dataProductId}
                        />
                    ) : (
                        <DataProductCustomFieldView
                            key={key}
                            customFieldId={key}
                            customField={customFields[key]}
                        />
                    )
                )}
        </div>
    );
};

export default DataProductCustomFields;
