import React from 'react';
import { RiverStreamDetails } from './sections/RiverStreamDetails';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { InputportPDWDatasetDetails } from './sections/InputPortPDWDatasetDetails';

export const InputPortsDetailsSection: React.FC<{
    selectedSubTab: DataPortalAPI.InputPortType,
    dataProduct: DataPortalAPI.DataProduct,
    hasDataProductManagePermission: boolean
}> = ({ selectedSubTab, dataProduct, hasDataProductManagePermission }) => {

    return <div>
        {selectedSubTab === DataPortalAPI.InputPortType.RiverStream ? <RiverStreamDetails dataProduct={dataProduct} hasDataProductManagePermission={hasDataProductManagePermission} /> : undefined }
        {selectedSubTab === DataPortalAPI.InputPortType.PDWDataset ? <InputportPDWDatasetDetails dataProductId={dataProduct.dataProductId} hasDataProductManagePermission={hasDataProductManagePermission} /> : undefined }
    </div>;

};