import Button from '@cimpress/react-components/lib/Button';
import React from 'react';
import { LookerHomePageDetailsContent } from './LookerHomePageDetailsContent';
import { LookerDashboardsSlice } from '@cimpress-technology/data-portal-core';
import { useAppDispatch, useAppSelector } from '../../../store/storeHooks';
import { ManageLookerDashboards } from '../../screens/dataProductManagement/manageDataProduct/ManageLookerDashboards';

type Props = { allowEditSwitch: boolean, dataProductId: string, isPublished: boolean };
export const LookerHomePageDetails = (props: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { editMode } = useAppSelector((state) => state.lookerDashboards);

    const setManageLookerDashboards = (input: boolean) => {
        dispatch(LookerDashboardsSlice.setEditMode(input));
    };

    return <div>
        <div className='row'>
            <div className='col-md-7'>
                <h3 style={{margin: "0px"}}>Looker Homepage<small className="text-primary"> Beta</small></h3>            
                <small>Looker HomePage is a collection of Looker dashboards.</small>
            </div>
            <div className='col-md-5'>
                {props.allowEditSwitch && 
                <Button className='pull-right' disabled={editMode} variant='primary' onClick={() => { setManageLookerDashboards(true); }}>+/- Manage Looker Dashboards</Button>}
            </div>
        </div>
        <hr />
        <LookerHomePageDetailsContent />
        <ManageLookerDashboards dataProductId={props.dataProductId} isPublished={props.isPublished} />
    </div>;
};
