import { useState, useEffect } from 'react';
import { WritableDraft } from 'immer/dist/types/types-external';
import { useAppDispatch, } from "../store/storeHooks";
import { UserDetails } from '@cimpress-technology/data-portal-core/lib/common/interfaces';
import { ExtendedDataProduct } from '../clients/dataPortalApi';
import { ReactAppEnvironment } from '../common';
import useSegment, { EventProperties } from './useSegment';
import {  getDeltaTableResourceCollection, AddDeltaTableResource, removeDeltaTableResource } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalAPI/deltaTableResources';
import { DeltaTable } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalAPI/deltaTableResources';
import { DataProductCoamRolesState } from '@cimpress-technology/data-portal-core/lib/features/dataProductCoam/dataProductCoamRolesSlice';

export interface DatabricksDatasetsProps {
    dataProductId: string;
    accessToken: string;
    hasDataProductAccess: boolean;//=hasAdminAccess
    getDatasets: (dataProductId: string) => Promise<DatasetModel[]>;
    addDataset: (dataProductId: string, dataset: DatasetModel) => Promise<void>;
    removeDataset: (dataProductId: string, dataset: DatasetModel) => Promise<void>;
    trackEvent?: (eventName: string, eventProperties: EventProperties ) => void;
}

export interface DatasetModel {
    id: string;
}

const getDatabricksManagementApiUrl = () => {
    const baseUrl = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'prd.api.databricks-management.cimpress.io' : 'dev.api.databricks-management.cimpress.io';
    return `https://${baseUrl}`;
};

export const useDatabricksDatasets = (userDetails: UserDetails, dataProduct: ExtendedDataProduct | null, dataProductCoamRolesState:  WritableDraft<DataProductCoamRolesState>): {
    databricksDatasetsModuleProps: Partial<DatabricksDatasetsProps> | undefined;
    databricksDatasetsCount: number;
} => {

    const dispatch = useAppDispatch();
    const [databricksDatasetsModuleProps, setDatabricksDatasetsModuleProps] = useState<Partial<DatabricksDatasetsProps>>();
    const [databricksDatasetsCount, setDatabricksDatasetsCount] = useState<number>(0);
    const segment = useSegment();

    useEffect(() => {
       if(dataProduct == null || userDetails.accessToken == null)
           return;

        const dataProductId = dataProduct.dataProductId;
        const accessToken = userDetails.accessToken || '';
        const apiUrl = getDatabricksManagementApiUrl();

        const getDatasets = async (dataProductId: string): Promise<DatasetModel[]> => {
            const res = await getDeltaTableResourceCollection(accessToken, dataProductId, DeltaTable);
            const ans = res._embedded.items.map((item) => ({
                id: item.resourceId
            }));
            return ans;
        };
        
        const addDataset = async (dataProductId: string, dataset: DatasetModel): Promise<void> => {       
            await AddDeltaTableResource(accessToken, dataProductId, { resourceId: dataset.id, resourceType: DeltaTable });
            setDatabricksDatasetsCount(databricksDatasetsCount => databricksDatasetsCount + 1);
        };

        const removeDataset = async (dataProductId: string, dataset: DatasetModel): Promise<void> => {
            await removeDeltaTableResource(accessToken, dataProductId, { resourceId: dataset.id, resourceType: DeltaTable });
            setDatabricksDatasetsCount(databricksDatasetsCount => databricksDatasetsCount - 1);
        };

        const initializeDatasetsCount = async () => {
            setDatabricksDatasetsCount(await getDatasets(dataProductId).then((datasets) => datasets.length));
        };
        initializeDatasetsCount();

        /* eslint-disable  @typescript-eslint/no-unused-vars  */
        const props = {
            dataProductId,
            accessToken,
            databricksManagementApiUrl: apiUrl,
            getDatasets,
            addDataset,
            removeDataset,
            trackEvent: segment.trackEvent
          };
          setDatabricksDatasetsModuleProps(props);
    }
    , [userDetails.accessToken, dataProduct, dispatch, segment.trackEvent, dataProductCoamRolesState.coamRoles?.admin]);
    /* eslint-enable   @typescript-eslint/no-unused-vars  */

    return { databricksDatasetsModuleProps, databricksDatasetsCount };
};
