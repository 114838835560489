import React, { useState, useEffect } from 'react';
import { appendScript } from "../../utils/common";
import { AuthContext } from "../../context/authContext";

const loadBundleJs = async (accessToken, bundleUrl, id, setLoading, setError) => {
    setLoading(true);
    setError(null);

    if (accessToken) {
        try {
            await appendScript(bundleUrl, accessToken, id);
            const maxRetry = 5;
            let retries = 0;
            while (window.dataportalresources?.[id] == undefined && retries++ < maxRetry) {
                console.log(`Waiting for ${id} to load... [${retries}/${maxRetry}]`);
                await new Promise(r => setTimeout(r, 100));
            }
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }
    setLoading(false);
};

export const useMicroFrontend = (id: string, bundleUrl: string, forceLoadNow = false): {
    loadingBundle: boolean;
    loadBundleErrors: Error | null;
    loadBundleJs(): ReturnType<typeof loadBundleJs>;
} => {
    const shouldLoadNow = typeof window.dataportalresources == typeof undefined || typeof window.dataportalresources[id] == typeof undefined || forceLoadNow;
    const { accessToken, isAuthenticating } = React.useContext(AuthContext);
    const [loading, setLoading] = useState(shouldLoadNow);
    const [error, setError] = useState(null as Error | null);

    useEffect(() => {
        if (isAuthenticating === undefined || isAuthenticating)
            return;

        if (!shouldLoadNow)
            return;

        
        loadBundleJs(accessToken, bundleUrl, id, setLoading, setError).then();
    }, [isAuthenticating, bundleUrl, id, shouldLoadNow, accessToken]);

    return {
        loadingBundle: loading,
        loadBundleErrors: error,
        loadBundleJs: () => loadBundleJs(accessToken, bundleUrl, id, setLoading, setError)
    };
};