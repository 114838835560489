
import { useState, useEffect } from 'react';
import { getDataProduct, ExtendedDataProduct } from '../clients/dataPortalApi';

const loadDataProduct = async (accessToken, productId, setLoading, setError, setDataProduct) => {
    setLoading(true);
    setError(null);
    setDataProduct(null);

    if (accessToken) {
        try {
            const result = await getDataProduct(accessToken, productId);
            setDataProduct(result);
        } catch (error) {
            console.error(error);
            setError(error);
        }
        setLoading(false);
    }

    setLoading(false);
};

export const useDataProduct = (accessToken: string | undefined, productId: string): {
    loadingDataProduct: boolean;
    dataProduct: ExtendedDataProduct | null;
    dataProductError: Error | null;
    setDataProduct: React.Dispatch<React.SetStateAction<ExtendedDataProduct | null>>;
    loadDataProduct(): void;
} => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null as Error | null);
    const [dataProduct, setDataProduct] = useState(null as ExtendedDataProduct | null);


    useEffect(() => {
        loadDataProduct(accessToken, productId, setLoading, setError, setDataProduct);
    }, [accessToken, productId]);

    return {
        loadingDataProduct: loading,
        dataProductError: error,
        dataProduct,
        setDataProduct,
        loadDataProduct: () => loadDataProduct(accessToken, productId, setLoading, setError, setDataProduct)
    };
};
