import { useState, useEffect } from 'react';
import { getDomain } from "@cimpress-technology/data-portal-core/lib/clients/dataPortalApi";
import { Domain } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";

const loadDomain = async (accessToken, domainId, setLoading, setError, setDomain) => {
    setLoading(true);
    setError(null);
    setDomain(null);

    if (accessToken && domainId) {
        try {
            const result = await getDomain(accessToken, domainId);

            setDomain(result);
        } catch (error) {
            console.error(error);
            setError(error);
        }
        setLoading(false);
    }

    setLoading(false);
};

export const useDomain = (accessToken: string | undefined,domainId: string | undefined): {
    loadingDomain: boolean;
    domain?: Domain;
    domainError: Error | null;
    selectedDomainId: string | undefined;
    setSelectedDomainId: React.Dispatch<React.SetStateAction<string | undefined>>;
    loadDomain(): void;
} => {
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null as Error | null);
    const [domain, setDomain] = useState(undefined as Domain | undefined);
    const [selectedDomainId, setSelectedDomainId] = useState<string | undefined>(domainId);
    
    useEffect(() => {
        setSelectedDomainId(domainId);
    }, [domainId]);

    useEffect(() => {
        loadDomain(accessToken, selectedDomainId, setLoading, setError, setDomain);
    }, [accessToken, selectedDomainId]);
    
    return {
        loadingDomain: loading,
        domainError: error,
        domain,
        selectedDomainId,
        setSelectedDomainId,
        loadDomain: () => loadDomain(accessToken, domainId, setLoading, setError, setDomain)
    };
};


