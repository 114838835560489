import { fetchResourcesPdwRoles, fetchConductors } from "@cimpress-technology/data-portal-core/lib/features/resources/common";
import { cleanPdwRoles } from "@cimpress-technology/data-portal-core/lib/features/resources/pdwRolesSlice";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { cleanConductors } from "@cimpress-technology/data-portal-core/lib/features/resources/minConductorSlice";


type callbackType = () => void;
type loaderFn = (token: string | undefined, dataProductId: string | undefined ) => callbackType;
export const usePdwRolesLoader: loaderFn = (accessToken, dataProductId) => {
    const dispatch = useAppDispatch();
    const pdwRolesState = useAppSelector((state) => state.resourcePdwRoles);
    const fecthPdwRoles = useCallback(() => {
        if (accessToken && dataProductId && (pdwRolesState.pdwRoles === undefined || pdwRolesState.dataProductId === undefined || pdwRolesState.dataProductId !== dataProductId))
            dispatch(fetchResourcesPdwRoles({ accessToken: accessToken, dataProductId: dataProductId }));
    }, [dispatch, accessToken, dataProductId, pdwRolesState.pdwRoles, pdwRolesState.dataProductId]);
    return fecthPdwRoles;
};

type memoizedClean = (currentDpId: string) => callbackType;

type conductorEnvLoadFn = (token?: string, dataProductId?: string) => callbackType;
export const useConductorDataProductLoader: conductorEnvLoadFn = (accessToken, dataProductId) => {
    const dispatch = useAppDispatch();
    const conductorState = useAppSelector((state) => state.minimalConductors);
    const fetch = useCallback(() => {
        if (accessToken && dataProductId && (conductorState.conductors === undefined || conductorState.dataProductId === undefined || conductorState.dataProductId !== dataProductId)) {
            dispatch(fetchConductors({ accessToken: accessToken, dataProductId: dataProductId }));
        }
    }, [dispatch, conductorState.conductors, conductorState.dataProductId, dataProductId, accessToken]);
    return fetch;
};

export const useMinimalConductorClean: memoizedClean = (dataProductId) => {
    const dispatch = useAppDispatch();
    const minimalState = useAppSelector(state => state.minimalConductors);
    const clean = useCallback(() => {
        if (minimalState.dataProductId !== dataProductId) dispatch(cleanConductors());
    }, [dispatch, minimalState.dataProductId, dataProductId]);
    return clean;
};

/** Cleans all the state of PdwRoles Slice */
export const usePdwRolesKeepItClean = ():callbackType => {
    const dispatch = useAppDispatch();
    const clean = useCallback(() => {
        dispatch(cleanPdwRoles());
    }, [dispatch]);
    return clean;
};
