import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import { DataProductsSlice } from '@cimpress-technology/data-portal-core';
import { Pagination } from '@cimpress/react-components';
import { AuthContext } from '../../context/authContext';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { DataProductItem } from './DataProductItem';
import { renderLoading, renderError } from './Render';
import { RootState } from '@cimpress-technology/data-portal-core/lib/store';
import { fetchDataProducts } from '@cimpress-technology/data-portal-core/lib/features/dataProducts/dataProductsSlice';

const renderDataProducts = (dataProducts: DataPortalAPI.DataProductListItem[], dataProductsPerRow, isAdmin?: boolean,) => {

    const renderDataProducts = [] as React.ReactElement[];

    for (let i = 0; i < dataProducts.length; i += dataProductsPerRow) {

        const dataProductColumns = [] as React.ReactElement[];
        const colSize = 12 / dataProductsPerRow;

        for (let j = 0; j < dataProductsPerRow; j += 1) {
            dataProductColumns.push(<div className={`col-md-${colSize}`} key={i + j}>
                <DataProductItem dataProduct={dataProducts[i + j]} isAdmin={isAdmin} />
            </div>);
        }

        renderDataProducts.push(<div className='row' key={i}>{dataProductColumns}</div>);
    }

    return renderDataProducts;
};


export const DataProducts: React.FC<{
    isAdmin?: boolean;
    domainId?: string;
    dataProductsPerRow?: number;
}> = ({ isAdmin, domainId, dataProductsPerRow }) => {    
    const { accessToken } = React.useContext(AuthContext);    
    const dispatch = useAppDispatch();
    const { status, dataProductList, error, page } = useAppSelector((state: RootState) => state.dataProducts);
    const setPaginationPage = (selected: number) => dispatch(DataProductsSlice.setPage(selected));
    const [ internalPage, setInternalPage ] = useState(0);
    const pageSize = 8;
    const actualSetPage = domainId ? setInternalPage : setPaginationPage;
    const actualPage = domainId ? internalPage : page;

    useEffect(() => {
        const fetchPromise = (!domainId && accessToken) ? dispatch(fetchDataProducts({ accessToken: accessToken, onlyOwned: isAdmin || false })) : undefined;
        return () => { if (domainId) setInternalPage(0); if (fetchPromise) fetchPromise.abort(); };
    }, [accessToken, dispatch, domainId, isAdmin]);
    
    if (!accessToken) return null;
    if (status === "loading") return renderLoading('Loading data products', true);
    if (status === "failed") return renderError('Error loading datasets.', error as Error, () => {
        dispatch(DataProductsSlice.fetchDataProducts({ accessToken: accessToken, onlyOwned: isAdmin || false })); });

    let filteredDataProducts = dataProductList ? [...dataProductList] : [];
    if (domainId) filteredDataProducts = filteredDataProducts.filter(d => d.domainId === domainId && d.published === true);
    filteredDataProducts.sort((a, b) => a.dataProductName.localeCompare(b.dataProductName));
    const rows = filteredDataProducts.slice(actualPage * pageSize, (actualPage * pageSize) + pageSize);

    return <div>
        <small>{filteredDataProducts.length} Data Products</small>
        {renderDataProducts(rows, dataProductsPerRow || 4, isAdmin)}
        <br />
        <div style={{ float: 'right' }}>
            {(filteredDataProducts?.length || 0) > pageSize
                ? <Pagination
                    initialPage={actualPage}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={Math.ceil(filteredDataProducts.length / pageSize)}
                    onPageChange={(p) => actualSetPage(p.selected)}
                    containerClassName={'pagination'}
                />
                : null}
        </div>
    </div>;
};
