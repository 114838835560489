import { Button, colors, Tooltip } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { IconAlertTriangle, IconAdd, IconCheck } from '@cimpress-technology/react-streamline-icons/lib';
import React, { useEffect, useState } from 'react';
import { addOutputPort } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalApi';
import { AuthContext } from '../../../context/authContext';
import { OutputPortComponent, OutputPortType, OutputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import axios from 'axios';
import { SliceState } from '@cimpress-technology/data-portal-core/lib/features/common';

export enum AddOutputPortProcessStatus {
    IDLE = 'idle',
    PROCESSING = 'processing',
    FAILED = 'failed',
    SUCCESS = 'success'
}

export type mapSliceStateToAddPortState = {
    [Property in SliceState] : AddOutputPortProcessStatus
}

export const mappedSliceStateToAddPortState: mapSliceStateToAddPortState = {
    idle: AddOutputPortProcessStatus.IDLE,
    loading: AddOutputPortProcessStatus.PROCESSING,
    succeeded: AddOutputPortProcessStatus.SUCCESS,
    failed: AddOutputPortProcessStatus.FAILED
};

export const AddOutputPortButton: React.FC<{
    dataProductId: string;
    outputPortType: OutputPortTypePath;
    outputPortBody: OutputPortComponent<OutputPortType>;
    useInDataProduct: string;
    onProcessingStatusChange: (status: AddOutputPortProcessStatus) => void;
    style?: React.CSSProperties;
    customAddFunction?: () => void;
    customProcessingStatus?: AddOutputPortProcessStatus;
    customErrorMessage?: string;
}> = ({ dataProductId, outputPortType, outputPortBody, useInDataProduct, onProcessingStatusChange, customAddFunction, customProcessingStatus, customErrorMessage, style }) => {

    const defaultErrorMessage = 'There was an Error adding this output Port. Output port might have already been added. Refresh and Try again or contact #data-domain-support';
    const { accessToken } = React.useContext(AuthContext);
    const [ processingStatus, setProcessingStatus ] = useState(customProcessingStatus ? customProcessingStatus : AddOutputPortProcessStatus.IDLE);
    const [ errorAdding, setErrorAdding ] = useState(customErrorMessage ? customErrorMessage : "");

    useEffect(() => {
        if (customProcessingStatus) setProcessingStatus(customProcessingStatus);
        if (customErrorMessage) setErrorAdding(customErrorMessage);
    }, [customProcessingStatus, customErrorMessage]);

    const addOutputPortOnClick = async (outputPortType: OutputPortTypePath, outputPortBody: OutputPortComponent<OutputPortType>, dataProductId: string) => {
        setErrorAdding('');
        setProcessingStatus(AddOutputPortProcessStatus.PROCESSING);
        onProcessingStatusChange(AddOutputPortProcessStatus.PROCESSING);
        try {
            if (accessToken) {
                await addOutputPort(accessToken, dataProductId, outputPortType, outputPortBody);
                onProcessingStatusChange(AddOutputPortProcessStatus.SUCCESS);
                setProcessingStatus(AddOutputPortProcessStatus.SUCCESS);
            } else {
                setErrorAdding('Authorization token not found. Try again after refreshing the page');
            }
        } catch(error) {
            let errorMessage = defaultErrorMessage;
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data.message || error.response?.statusText || error.message;
            }
            onProcessingStatusChange(AddOutputPortProcessStatus.FAILED);
            setErrorAdding(errorMessage);
            setProcessingStatus(AddOutputPortProcessStatus.FAILED);
        }
    };

    const mainPaddingBottom = processingStatus === AddOutputPortProcessStatus.FAILED ? 5 : 0; // A little bit more of space for the triangle

    return <div style={{...style}}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" , height: "100%", width: "100%", paddingBottom: mainPaddingBottom }}>
                {
                    processingStatus !== AddOutputPortProcessStatus.PROCESSING ?
                        processingStatus !== AddOutputPortProcessStatus.SUCCESS ?
                            <>
                                <Tooltip direction='left' style={{ height: "100%", width: "100%" }} show={useInDataProduct ? undefined : false} contents={
                                    <span>The Dataset is already used in the published Data Product <a href={`/dataProducts/${useInDataProduct}`} target="_blank" rel="noreferrer">{useInDataProduct}</a>. It cannot be added as an Output Port of this Data Product.</span>
                                }>

                                    <Button
                                        variant='default'
                                        style={{ height: "100%", width: "100%" }}
                                        disabled={useInDataProduct ? true : false}
                                        onClick={() => customAddFunction ? customAddFunction() : addOutputPortOnClick(outputPortType, outputPortBody, dataProductId)}
                                    >
                                        <IconAdd weight="fill" />
                                    </Button>
                                    {
                                        processingStatus === AddOutputPortProcessStatus.FAILED ?
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Tooltip contents={errorAdding}>
                                                    <IconAlertTriangle weight="fill" style={{color: colors.warning.base}} />
                                                </Tooltip>
                                            </div>
                                        : null
                                    }
                                </Tooltip>
                            </>
                        :
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}><IconCheck weight="fill" style={{color: colors.success.base}} /></div>
                    :
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}><Spinner size='medium' /></div>
                }
                </div>
            </div>;
};
