import React from 'react';
import { colors } from '@cimpress/react-components';
import { Interfaces, Hooks } from "@cimpress-technology/data-portal-core";
import { AuthContext } from '../../../../context/authContext';
import { renderError, renderLoading } from '../../../shared/Render';

export const DataProductDomainDetails: React.FC<{
    domain: Interfaces.DataPortalAPI.Domain;
}> = ({ domain }) => {

    const { accessToken } = React.useContext(AuthContext);
    const domainCoamGroupId = domain._links.coam.href.split('/')[7];
    const domainCoamGroup = Hooks.useCoamGroup(accessToken, domainCoamGroupId);
    const pandaSquadMembers = ['arjita.burjwal@cimpress.com', 'lgilvillalonga@cimpress.com', 'maryia.borukhava@cimpress.com', 'nishant.sharma@cimpress.com', 'wilmer.uruchi@cimpres.com', 'shashank.singh@cimpress.com'];

    if (!accessToken || !domain) {
        return null;
    }

    const filteredDomainGovernorsList = domainCoamGroup.coamGroup?.members
        .filter(member => !pandaSquadMembers.includes(member.principal.toLowerCase()) && !member.is_client && member.roles.includes('Data Portal Domain Governor'));

    return <div>

        <h3>Details</h3><hr />
        <div className="stat stat-default">
            <h6>Domain Coam Group</h6>
            <span className="stat-value" style={{ color: colors.shale }}>
                {
                    domainCoamGroupId
                        ? <a key={domainCoamGroupId}
                            href={`https://users.cimpress.io/groups/${domainCoamGroupId}`} target='__blank'
                            rel="noopener noreferrer">
                            Data Portal Domain [{domain.name}]
                            <br />
                        </a>
                        : null
                }
            </span>
        </div>
        <br /><br />

        <div className="stat stat-default">
            <h6>Domain Governors</h6>
            <span className="stat-value" style={{ color: colors.shale }}>
                <div>
                    {
                        domainCoamGroup.loadingCoamGroup ?
                            renderLoading('Loading Domain Governors...', false, 'small')
                            : domainCoamGroup.coamGroupError
                                ? renderError('Error Loading Domain Governors', domainCoamGroup.coamGroupError, domainCoamGroup.loadCoamGroup)
                                : filteredDomainGovernorsList?.length
                                    ? <>
                                        {
                                            filteredDomainGovernorsList.map(m => <div key={m.principal} style={{ marginRight: 12, marginBottom: 6, minWidth: 152 }}>

                                                <img src={m.profile?.picture} height={32} width={32} style={{ borderRadius: '50%' }} />
                                                &nbsp;&nbsp;
                                                {m.profile?.given_name ? <> {m.profile?.given_name} {m.profile?.family_name} </> : <> {m.profile?.nickname} </>}

                                            </div>)
                                        } </> : 'No Domain Governor found.'
                    }
                </div>
            </span>
        </div>
    </div>;
};

