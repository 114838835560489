import React from "react";
import { Button } from "@cimpress/react-components";
import { useState } from "react";
import { CreateNewDataProductModal } from "./CreateNewDataProductModal";

export const CreateNewDataProductButton: React.FC<{
    variant?: "default" | "primary";
    size?: 'sm' | 'lg' | 'default';
}> = ({ variant, size }) => {
    const [shoModal, setShowModal] = useState(false);

    return <>
        <Button size={ size } variant={variant} onClick={() => setShowModal(true)}>+ New Data Product</Button>
        <CreateNewDataProductModal showModal={shoModal} onHideModal={() => setShowModal(false)} />
    </>;
};
