import React from 'react';
import IconImageFileSettings from '@cimpress-technology/react-streamline-icons/lib/IconImageFileSettings';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { OutputPort, OutputPortType } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { CopyText } from '@cimpress/react-components';
import { DocumentationURL } from '../../../../clients/dataPortalApi';

const outputPortMap = new Map<OutputPortType, string>([[OutputPortType.LookerDashboards, 'Dashboard'], [OutputPortType.PDWdataset, 'Dataset']]);

const determineResourceType = (outputPorts?: OutputPort<OutputPortType>[]): string => {
    if (!outputPorts || outputPorts.length === 0) return "--";
    const included = new Set((outputPorts).map(o => outputPortMap.get(o.portType)));
    return Array.from(included).join('; ');
};

type Props = {
    dataProductId?: string,
    domain: {
        loading: boolean,
        name: string | null,
    },
    account: {
        loading: boolean,
        name: string | null,
    }
    createdAt?: number,
    version?: number,
    accessToken?: string,
    outputPorts?: OutputPort<OutputPortType>[],
    additionalDocumentation?: DocumentationURL[]
};

const Details = (props: Props): JSX.Element => {

    const loading = !props.dataProductId || props.domain.loading || !props.createdAt;

    if (loading) {
        return (<div className="dp-card summary__details">
            <div className="summary__card__header">
                <span className='summary__card__header__icon'><IconImageFileSettings /></span>
                <span className='summary__card__header__text'>Details</span>
            </div>
            <div className="summary__card__body">
                <div className='summary__details__content'>
                    <Spinner size='small' />
                </div>
            </div>
        </div>);
    }

    return (
        <div className="dp-card summary__details">
            <div className="summary__card__header">
                <span className='summary__card__header__icon'><IconImageFileSettings /></span>
                <span className='summary__card__header__text'>Details</span>
            </div>
            <div className="summary__card__body">
                <div className='summary__details__content'>
                    <div><span>Resource Type:</span><span>{determineResourceType(props.outputPorts)}</span></div>
                    <div><span>Created Date:</span><span>{props.createdAt ? new Date(props.createdAt).toLocaleDateString() : ''}</span></div>
                    <div><span>Publisher:</span><span>{props.account.loading ? <Spinner size='small' /> : props.account.name}</span></div>
                    <div><span>Unique Identifier:</span><span><CopyText value={props.dataProductId}>{props.dataProductId?.substring(0, 8).concat('...')}</CopyText></span></div>
                    <div><span>Domain:</span><span>{props.domain.loading ? <Spinner size='small' /> : props.domain.name}</span></div>
                    <div><span>Version:</span><span>{props.version}</span></div>
                    {props.additionalDocumentation && props.additionalDocumentation.length > 0 && (
                        <div className='summary__details__documentation'>
                            <span>Additional Documentation:</span>
                            <span className='summary__details__documentation-list'>
                                <ul>{props.additionalDocumentation.map((doc, index) =>
                                    <li key={index}><a href={doc.url} target="_blank" rel='noreferrer'>{doc.title ? doc.title : doc.url}</a></li>)}
                                </ul>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Details;