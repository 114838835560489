import React, { useState } from 'react';
import { Snackbar, Toggle , Modal, Button} from '@cimpress/react-components';
import { patchDataProductAttribute } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalApi';
import { ResourceControlModalMessage } from '../../../../../interfaces/resourceControl';
import { OperationStatus } from '../../../../../interfaces/common';
import { parseUnknownErrorTypeToErrorMessage } from '@cimpress-technology/data-portal-core/lib/features/utils';


const modalContentOnEnable: ResourceControlModalMessage = {
    description: "Enabling this flag converts your Data Product to a Resource Controlled Data Product. \
        You authorize the CT Data Platform to manage and operate on your Data Product resources. \
        Once accepted, the CT Data Platform may automatically initiate and execute operations without further user or client interaction, \
        as directed by the data product admin(s), for processing, transformation, and management.",
    callToAction: "CT Data Platform will ensure secure, compliant, and optimized handling of your resources throughout these processes.",
    cancelButtonText: "Decline",
    acceptButtonText: "Accept"
};

const modalContentOnDisable: ResourceControlModalMessage = {
    description: "Disabling this flag in your Data Product may cause a failure in the Resource Controlled approach. \
        This may lead to unexpected behavior in the Data Product operations.",
    callToAction: "Do you want to continue?",
    cancelButtonText: "Cancel",
    acceptButtonText: "Continue"
};

type Props = {
    accessToken: string;
    dataProductId: string;
    isResourceControlled: boolean;
    isDataProductAdmin: boolean;
    updateDataProductState: (isResourceControlled: boolean) => void;
}

export const ManageResourceControlled = (props:Props)=> {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [status, setStatus] = useState<OperationStatus>('idle');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState<ResourceControlModalMessage | null>(null);
    const changeResourceControl = async (dataProductId: string, newIsResourceControlled: boolean) => {
        setStatus('loading');
        try {
            await patchDataProductAttribute(props.accessToken, dataProductId, 'isResourceControlled', newIsResourceControlled);
            props.updateDataProductState(newIsResourceControlled);
            setStatus('success');
        } catch (error) {
            setErrorMessage(parseUnknownErrorTypeToErrorMessage(error));
            setStatus('error');
        }
        setShowModal(false);
    };

    const onToggle = () => {
        setModalMessage(props.isResourceControlled ? modalContentOnDisable : modalContentOnEnable);
        setShowModal(true);
    };

    return <>
        <div>
                <Modal
                    show={showModal}
                    title={"Confirmation Required: Resource-Controlled Data Product Operations"}
                    onRequestHide={() => setShowModal(false)}
                    closeButton={status !== 'loading'}
                    footer={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                size="sm"
                                disabled={status === 'loading'}
                                onClick={()=> setShowModal(false)}
                            >

                                {modalMessage && (
                                    modalMessage.cancelButtonText
                                )}

                            </Button>
                            <Button
                                size="sm"
                                variant='primary'
                                disabled={status === 'loading'}
                                onClick= {()=> changeResourceControl(props.dataProductId, !props.isResourceControlled)}
                            >
                                {modalMessage && (
                                    modalMessage.acceptButtonText
                                )}
                            </Button>
                        </div>
                    }
                >
                    <div>
                        {modalMessage && (
                            <p>
                            {modalMessage.description}
                         <br /><br />
                            <b>{modalMessage.callToAction}</b>
                        </p>
                        )}
                    </div>
                </Modal>
                <Toggle
                    on={props.isResourceControlled}
                    size="sm"
                    disabled={(!props.isDataProductAdmin) || status === 'loading'}
                    onClick={onToggle}
                />
        </div>
        <Snackbar status='danger' show={status === 'error'} delay={4000} onHideSnackbar={() => { setErrorMessage(null); setStatus('idle');}} >
            <div>{errorMessage}</div>
        </Snackbar>
    </>;
};
