import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssLoader } from '@cimpress/react-components';
import { PlatformHeader } from './components/header';
import { Routes } from './routes';
import "./sass/main.scss";

export const App: React.FC = () => {
  return (
    <CssLoader>
      <BrowserRouter>
        <PlatformHeader />
        <Routes />
      </BrowserRouter>
    </CssLoader>
  );
};