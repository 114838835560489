import React, { PropsWithChildren, useCallback, useContext, useEffect, useRef } from "react";
import { Interfaces } from "@cimpress-technology/data-portal-core";
import { useDataContractsModule } from "./useDataContractsModule";
import { AuthContext } from "../../context/authContext";
import useSegment from "../../hooks/useSegment";

// Globally declare 'dataportalresources' object to access modules later
declare global {
    interface Window {
        // eslint-disable-next-line
        dataportalresources?: any;
    }
}

// Prop types for Data Contracts Modules. Includes optional data product ID, dataset metadata, history object, access token & router props.
export interface DataContractsModuleProps {
    dataProductId?: string;
    dataset?: Interfaces.DataDiscovery.DataDiscoveryDatasetMetadata;
    history?;
    authContext?;
    routerProps?: PropsWithChildren<unknown>;
}

// Function to retrieve a specific module given its name and properties. This function handles the tasks necessary for the module to render properly.
const GetModule = (name: string, props: DataContractsModuleProps) => {
    const authContext = useContext(AuthContext);
    const { dataProductId, dataset, history } = props;
    const routerProps = props;
    const { trackEvent } = useSegment();
    
    // If the component we are trying to render is not already loaded, we ask to load it!
    const shouldForceLoadBundleNow = !window.dataportalresources || !window.dataportalresources["DataContracts"];
    // Inject the module, but using a hook to avoid duplicating injection and to have the chance to optimize by preloading.
    const { loadingBundle } = useDataContractsModule(shouldForceLoadBundleNow);

    // Create a callback function to render a given module under a certain DOM element, only if both render function and DOM element are defined.
    const renderUnderParentElement = useCallback(async (domElement) => {
        if (loadingBundle) {
            console.debug(`Still loading...[${loadingBundle}]`);
            console.debug(window.dataportalresources);
            return;
        }

        if (!window.dataportalresources?.["DataContracts"]) {
            console.error("We are no longer loading the bundle for DataContracts and yet it is not defined on the DOM! Did the request fail?");
            return;
        }

        console.debug("Bundle loading complete");
        const renderFunction = window.dataportalresources["DataContracts"][name]?.render;
        if (renderFunction && domElement) {
            await renderFunction(domElement, { dataProductId, dataset, history, routerProps, authContext, trackEvent}); //Props passed to the module when loading
        }
    }, [dataProductId, dataset, history, routerProps, name, loadingBundle, authContext, trackEvent]);

    // Inside rendering function, wraps the parent DOM element that the selected module will be rendered under.
    const RenderedModule = () => {
        const domElement = useRef(null);
        useEffect(() => {
            domElement.current && renderUnderParentElement(domElement.current);
        }, []);

        return <div ref={domElement} />;
    };
    // Set the display name of RenderedModule based on the name argument
    RenderedModule.displayName = name;

    return <RenderedModule />;
};

// Function to create an Data Contracts Module
const createDataContractsModuleComponent = (name: string): React.FC<DataContractsModuleProps> => {
    const DataContractsModule: React.FC<DataContractsModuleProps> = React.memo((props) => {
        return GetModule(name, props);
    });
    DataContractsModule.displayName = name;
    return DataContractsModule;
};

// These are the specific modules that are exported for use. They all utilize GetModule to render properly.
export const DataContractsManagementModule = createDataContractsModuleComponent("DataContractsManagementModule");
export const DataContractDetailsModule = createDataContractsModuleComponent("DataContractDetailsModule");
export const DataContractModule = createDataContractsModuleComponent("DataContractModule");