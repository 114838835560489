import {
    Button,
    Checkbox,
    colors,
    Select,
    Radio,
    RadioGroup,
} from "@cimpress/react-components";
import React, { useEffect, useState } from "react";
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { UserProfile } from "./UserProfile";
import {
    ValidAccountId,
    getTitlesAsSelectOptions,
} from "../../../../../../interfaces/productTeamTitles";
import { UserInformation } from "@cimpress-technology/data-portal-core/lib/features/coamUserInfoCache/coamUserInfoCacheSlice";
import { getDefaultAvatar } from "../../../../../../common";

const roleOptions: Record<
    DataPortalAPI.DataProductMemberRole,
    { roleName: "Administrator" | "Readers"; description: string }
> = {
    [DataPortalAPI.DataProductMemberRole.Readers]: {
        roleName: "Readers",
        description:
            "Grants read access to all the Data Product Output Ports (Datasets).",
    },
    [DataPortalAPI.DataProductMemberRole.Admins]: {
        roleName: "Administrator",
        description:
            "Grants admin access to the Data Product Resources, Input and Output Ports and allows adding and removing users.",
    },
};

interface BaseFormState {
    status: "Reader" | "Admin";
    enableTitleSelect: boolean;
    checkedAddProductTeam: boolean;
    titlesOptions: { label: string; value: string }[] | null;
}

interface ReaderFormState extends BaseFormState {
    status: "Reader";
    enableTitleSelect: false;
    checkedAddProductTeam: false;
    titlesOptions: [];
    selectedTitle: null;
}

interface AdminFormState extends BaseFormState {
    status: "Admin";
    enableTitleSelect: true;
    titlesOptions: { label: string; value: string }[];
    selectedTitle: string | null;
}

const getValidFormState = (
    role: DataPortalAPI.DataProductMemberRole,
    title: string | null,
    coamUser: UserInformation,
    checkedAdd: boolean
): ReaderFormState | AdminFormState => {
    switch (role) {
        default:
        case DataPortalAPI.DataProductMemberRole.Readers:
            return {
                status: "Reader",
                enableTitleSelect: false,
                checkedAddProductTeam: false,
                titlesOptions: [],
                selectedTitle: null,
            };
        case DataPortalAPI.DataProductMemberRole.Admins: {
            return {
                status: "Admin",
                enableTitleSelect: true,
                checkedAddProductTeam: checkedAdd,
                titlesOptions: getTitlesAsSelectOptions(
                    coamUser.account_id as ValidAccountId
                ),
                selectedTitle: title,
            };
        }
    }
};

export const UserRoleSelection: React.FC<{
    dataProduct: { dataProductId: string; dataProductName: string };
    user: {
        coamUser: UserInformation;
        selectedRole: DataPortalAPI.DataProductMemberRole;
        selectedTitle: string | null;
        isProductTeamAdd: boolean;
    };
    onCancel();
    onRoleChange(role: DataPortalAPI.DataProductMemberRole);
    onTitleChange(title: string | null): void;
    onProductTeamAddChange(isProductTeamAdd: boolean);
}> = ({
    dataProduct,
    user,
    onCancel,
    onRoleChange,
    onTitleChange,
    onProductTeamAddChange,
}) => {
    const [formState, setFormState] = useState<
        ReaderFormState | AdminFormState
    >(
        getValidFormState(
            user.selectedRole,
            user.selectedTitle,
            user.coamUser,
            user.isProductTeamAdd
        )
    );

    useEffect(() => {
        setFormState(
            getValidFormState(
                user.selectedRole,
                user.selectedTitle,
                user.coamUser,
                user.isProductTeamAdd
            )
        );
    }, [
        user.selectedRole,
        user.selectedTitle,
        user.coamUser,
        user.isProductTeamAdd,
    ]);

    const getCurrentSelected = () => {
        const current = formState.titlesOptions.find(
            (o) => o.value === user.selectedTitle
        );
        if (!current && user.selectedTitle !== null) {
            console.warn(`Title ${user.selectedTitle} is not applicable.`);
            onTitleChange(null);
        }
        return current;
    };

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 24 }}>
                    <UserProfile
                        coamUser={{
                            canonicalPrincipalId:
                                user.coamUser.canonicalPrincipalId,
                            picture:
                                user.coamUser.picture ||
                                getDefaultAvatar(
                                    user.coamUser.canonicalPrincipalId
                                ),
                            isClient: user.coamUser.is_client,
                            fullName: user.coamUser.fullName,
                        }}
                    />
                </div>
                <div style={{ marginRight: 32 }}>
                    <Button onClick={onCancel}>Change</Button>
                </div>
                <div>
                    <b>Data Product:</b>{" "}
                    <a
                        href={`https://data.cimpress.io/dataproducts/${dataProduct.dataProductId}`}
                        target="__blank"
                    >
                        {dataProduct.dataProductName}
                    </a>
                </div>
            </div>

            <hr />

            <div>
                <h5>Select role</h5>
                <RadioGroup
                    name="roles"
                    defaultSelected={user.selectedRole}
                    onChange={(e, v: DataPortalAPI.DataProductMemberRole) => {
                        if (v === DataPortalAPI.DataProductMemberRole.Readers) {
                            onProductTeamAddChange(false);
                        }
                        onRoleChange(v);
                    }}
                >
                    {Object.keys(roleOptions).map((opt) => (
                        <Radio
                            key={opt}
                            value={opt}
                            className="card card-block"
                            label={
                                <>
                                    <strong>{roleOptions[opt].roleName}</strong>
                                    <div
                                        style={{
                                            marginLeft: 24,
                                            marginTop: 4,
                                            color: colors.shale,
                                        }}
                                    >
                                        <p>{roleOptions[opt].description}</p>
                                    </div>
                                </>
                            }
                        />
                    ))}
                </RadioGroup>
                <Checkbox
                    label="Add to Product Team"
                    disabled={formState.status === "Reader"}
                    checked={formState.checkedAddProductTeam}
                    onChange={(e) => {
                        onProductTeamAddChange(e.target.checked);
                    }}
                />
                {formState.status === "Admin" &&
                    formState.checkedAddProductTeam === true && (
                        <div
                            className="row"
                            style={{ zIndex: 100, position: "relative" }}
                        >
                            <div className="col-md-6">
                                <Select
                                    isClearable
                                    isDisabled={
                                        !formState.checkedAddProductTeam
                                    }
                                    label="Select a Title"
                                    onChange={(e) => {
                                        onTitleChange(e?.value ?? null);
                                    }}
                                    value={getCurrentSelected()}
                                    options={formState.titlesOptions}
                                />
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};
