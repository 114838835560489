import React from "react";
import Button from "@cimpress/react-components/lib/Button";
import Tooltip from "@cimpress/react-components/lib/Tooltip";

type Props = {
    isSameBusiness: boolean;
    datasetAccount: string;
    database: string;
    schema: string;
    name: string;
};

const OpenInDataDiscoveryButton = (props: Props) => {
    return (
        <Tooltip
            show={!props.isSameBusiness ? undefined : false}
            contents={"You don't have access to this dataset in Data discovery"}
        >
            <Button
                size="sm"
                disabled={!props.isSameBusiness}
                onClick={() =>
                    window.open(
                        `https://data-discovery.cimpress.io/#/?selectedTreeItem=${props.datasetAccount}.eu-west-1.${props.database}.${props.schema}.${props.name}`,
                        "_blank",
                        "noopener,noreferrer"
                    )
                }
            >
                Open in Data Discovery
            </Button>
        </Tooltip>
    );
};

export default OpenInDataDiscoveryButton;
