import React from 'react';
import { patchDataProductAttribute } from "@cimpress-technology/data-portal-core/lib/clients/dataPortalApi";
import { Button, colors, Modal } from "@cimpress/react-components";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { renderError } from './Render';

export const PublishUnpublishDataProductButton: React.FC<{
    dataProductId: string,
    accessToken: string,
    isPublishing: boolean,
    onSuccess?: () => void
    style?: React.CSSProperties
}> = ({ dataProductId, accessToken, isPublishing, onSuccess, style }) => {
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [error, setError] = useState(null as AxiosError | null);
    const [showModal, setShowModal] = useState(false);

    const publishUnpublishDataProduct = async (accessToken, dataProductId) => {
        setIsUpdatingStatus(true);
        setError(null);
        try {
            await patchDataProductAttribute(accessToken, dataProductId, 'published', isPublishing);
            onSuccess ? onSuccess() : null;
        } catch(error) {
            if (axios.isAxiosError(error)) {
                setError(error);
            }
            setIsUpdatingStatus(false);
        }
    };


    return <>
        <Button variant='primary' className={isPublishing ? '' : 'btn-warning'} style={style ? style : undefined} disabled={isUpdatingStatus} onClick={() => { setShowModal(true); setError(null);} }>
            { !isUpdatingStatus ? isPublishing? 'Publish' : 'Unpublish' : <Spinner size='small' /> }
        </Button>

        <Modal
            show={showModal}
            closeOnOutsideClick={true}
            status='warning'
            closeButton={true}
            onRequestHide={() => setShowModal(false)}
            footer={
                <div className="pull-right">
                    <Button className={isPublishing ? 'pull-right' : 'pull-right btn-warning'} variant="primary" disabled={isUpdatingStatus} onClick={() => publishUnpublishDataProduct(accessToken, dataProductId)}>
                        { !isUpdatingStatus ? isPublishing? 'Publish' : 'Unpublish' : <Spinner size='small' /> }
                    </Button>
                    <Button className="pull-right" variant="default" onClick={() => setShowModal(false)} style={{marginRight: '5px'}}>
                        Cancel
                    </Button>
                </div>
            }
            title= {isPublishing ? 'Publish the Data product?' : 'Unpublish the Data product?'}
        >

            <div style={{color: colors.cobalt.base}}>{ isPublishing ? 'The Data Product should include valid data contracts for its output ports (currently applicable for only datasets).' : 'There should not be data contract on consumable output-ports.' }</div>
            { error ? <div><br />{renderError(error.message, error)}</div> : null}
        </Modal>
    </>;
};