import { Card, colors, Button, Alert } from '@cimpress/react-components';
import React from 'react';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { renderLoading, renderError } from '../../../shared/Render';
import { ManageRiverStream } from '../../dataProductManagement/manageDataProduct/ManageRiverStreamDrawer';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { AuthContext } from '../../../../context/authContext';
import { useAppSelector, useAppDispatch } from '../../../../store/storeHooks';
import { getRiverStreams } from '@cimpress-technology/data-portal-core/lib/features/inputPorts/common';


export const RiverStreamDetails: React.FC<{ dataProduct: DataPortalAPI.DataProduct, hasDataProductManagePermission: boolean }> = ({ dataProduct, hasDataProductManagePermission }) => {

    const riverStreamsState = useAppSelector(state => state.riverStreams);
    const { status, riverStreams } = riverStreamsState;

    return <div>
        <div className='row'>
            <div className='col-md-7'>
                <h3 style={{ margin: 0 }}>River streams</h3>
                <small>These are the river streams that feed the data product.</small>
            </div>
            <div className='col-md-5'>
                {
                    hasDataProductManagePermission === true && status !== 'loading' && riverStreams ?
                        <ManageRiverStream
                            dataProduct={dataProduct}
                        /> : hasDataProductManagePermission ? <Button className='pull-right' disabled={true}>Loading details...</Button> : null
                }
            </div>
        </div>
        <hr />
        <br />

        <RiverStreamDetailsSection dataProductId={dataProduct.dataProductId} />
    </div>;
};

const RiverStreamDetailsSection: React.FC<{ dataProductId: string }> = ({ dataProductId }) => {

    const dispatch = useAppDispatch();
    const riverStreamsState = useAppSelector(state => state.riverStreams);
    const { accessToken } = React.useContext(AuthContext);
    const { status, riverStreams, error, portDeleteStatus, portAddStatus } = riverStreamsState;

    if (status === 'loading' && !riverStreams?.length) {
        return renderLoading('Loading river streams...', true);
    }
    if (error && !riverStreams?.length && accessToken) {
        return renderError('Error loading river streams.', error as Error || new Error('River Streams not found'), () => dispatch(getRiverStreams({ accessToken: accessToken, dataProductId: dataProductId })));
    }
    if (!riverStreams?.length) {
        return <span>No river streams</span>;
    }

    return <div>
        {
            error
                ? <Alert status="danger" message="There was an error fetching latest details. Try refreshing the page."></Alert>
                : null
        }
        {
            status === 'loading'
                ? <Alert status="info" message={<div style={{ display: 'flex' }}>Fetching Latest details...<Spinner size='small' /></div>}></Alert>
                : null
        }
        <div className='row'>
            <div className='col-md-4'>
                {
                    riverStreams?.length
                        ? riverStreams.map(riverStream =>
                            portAddStatus[riverStream.resourceId] === 'loading' || portDeleteStatus[riverStream.resourceId] === 'loading'
                                ? <div key={riverStream.resourceId} style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}><Spinner size='medium' /></div>
                                : <>
                                    <Card key={riverStream.resourceId} style={{ marginBottom: 16 }}>

                                        <h5><a href={`https://console.river.cimpress.io/#/streams/${riverStream.resourceId}`} target='__blank' rel="noopener noreferrer">{riverStream._embedded.streamName}</a></h5>
                                        <span style={{ color: colors.shale }}>{riverStream.resourceId}</span> <br /> <br />
                                        <span style={{
                                            color:
                                                riverStream._embedded.streamStatus === 'Available'
                                                    ? colors.success.base
                                                    : colors.danger.base
                                        }}>{riverStream._embedded.streamStatus}</span>

                                    </Card>
                                </>)
                        : <span>No river streams</span>
                }
            </div>
        </div>
    </div>;
};