import React, { useMemo, useEffect } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { Common } from "@cimpress-technology/data-portal-core";
import { SegmentContext, SegmentContextProps } from "./context";
import { AuthContext } from '../authContext';


interface SegmentProviderProps {
    children: React.ReactNode
}

export const SegmentProvider: React.FC<SegmentProviderProps> = ({
    children,
}) => {
    const writeKey = process.env.REACT_APP_ENVIRONMENT === Common.ReactAppEnvironment.Production
        ? 'XlSgbE6Wg7We7YQCZqE2JK98wZu1Fw1B' // DataPortal UI PROD source
        : 'AlLnni6ud0TZVSDWNGCsT60EyO2yASeV'; // DataPortal UI DEV (staging, MR, local) source

    const value: SegmentContextProps = useMemo(
        () => ({ segment: AnalyticsBrowser.load({ writeKey }) }),
        [writeKey]
    );

    const { accessToken, profile } = React.useContext(AuthContext);
    useEffect(() => {
        const identifyUserIfNeeded = async () => {
            const profileEmail = profile?.email.toLowerCase();
            const segmentUser = await value.segment.user();
            if (!accessToken || !profile || segmentUser.id() === profileEmail) {
                return;
            }

            value.segment.identify(profileEmail, {
                name: profile.name,
                user_id: profile.sub,
                account_id: profile['https://claims.cimpress.io/account'],
                email: profileEmail
            });
        };

        identifyUserIfNeeded();
    }, [accessToken, profile, value.segment]);

    return (
        <SegmentContext.Provider value={value}>
            {children}
        </SegmentContext.Provider>
    );
};