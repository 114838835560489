import { Button } from '@cimpress/react-components';
import React from 'react';
import { renderError, renderLoading } from '../Render';
import IconExternalLink from '@cimpress-technology/react-streamline-icons/lib/IconExternalLink';
import { useAppSelector } from '../../../store/storeHooks';
import { lookerEmbedIsNotError } from '../../../utils/embed';

export const LookerHomePageDetailsContent = (): JSX.Element => {
    const lookerDashboardsState = useAppSelector(state => state.lookerDashboards);
    const { status, lookerDashboards, error } = lookerDashboardsState;

    if (status === "loading") return renderLoading('Loading Looker HomePages...', true);
    if (error) return renderError('Error loading looker information.', error as Error || new Error('Looker information not found.'));

    if (!lookerDashboards || lookerDashboards.length === 0) return <span> No Homepages added</span>;

    return  <div style={{ display: "grid", gridTemplateColumns: "repeat(3, minmax(0, 1fr))", gap: 5 }}>
                {lookerDashboards.map((dashboard) =>
                    <div className='card-block card text-left' style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}} key={dashboard.resourceId}>
                        <header title={dashboard.resourceId} style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                            <span className='text-primary'>URL:</span> {dashboard.resourceId}
                        </header>
                        <h3 style={{ marginTop: '10px', textOverflow: 'ellipsis' }}> {lookerEmbedIsNotError(dashboard._embedded) ? dashboard._embedded?.title : "Error!"} </h3>
                        <Button variant='default' href={dashboard.resourceId} target="_blank">View Dashboard <IconExternalLink /></Button>
                    </div>
                )}
            </div>;
};