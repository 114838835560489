import React, { createContext } from 'react';
import { Hooks } from "@cimpress-technology/data-portal-core";

export const AuthContext = createContext({} as Hooks.AuthenticationProps);

export const AuthProvider: React.FC<{
    children: React.ReactElement
}> = (props) => {
    const auth = Hooks.useAuthentication();

    return (
        <AuthContext.Provider value={auth}>
            {props.children}
        </AuthContext.Provider>
    );
};