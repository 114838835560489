import React from "react";
import Button from "@cimpress/react-components/lib/Button";

type Props = {
    datasetAccount: string;
    database: string;
    schema: string;
    name: string;
    isView: boolean;
};

const OpenInSnowflakeButton = (props: Props) => {
    return (
        <Button
            size="sm"
            onClick={() =>
                window.open(
                    `https://app.snowflake.com/eu-west-1/${
                        props.datasetAccount
                    }/data/databases/${(
                        props.database
                    ).toUpperCase()}/schemas/${(
                        props.schema
                    ).toUpperCase()}/${props.isView ? "view" : "table"}/${(
                        props.name
                    ).toUpperCase()}/data-preview`,
                    "_blank",
                    "noopener,noreferrer"
                )
            }
        >
            Open in Snowflake
        </Button>
    );
};

export default OpenInSnowflakeButton;
