import { Button, Card, colors, Snackbar } from '@cimpress/react-components';
import React from 'react';
import { Clients } from "@cimpress-technology/data-portal-core";
import { useAppSelectorType } from "@cimpress-technology/data-portal-core/lib/storeHooks";
import { PDWRoleType } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { addPdwRole } from '@cimpress-technology/data-portal-core/lib/features/resources/common';
import { cleanError } from '@cimpress-technology/data-portal-core/lib/features/resources/pdwRolesSlice';
import { useAppDispatch } from '../../../store/storeHooks';
import { Spinner } from '@cimpress/react-components/lib/shapes';


const AddPdwRoleButton = (props: { accessToken: string, dataProductId: string, pdwRoleType: PDWRoleType, disabled: boolean }) => {
    const dispatch = useAppDispatch();
    return <Button disabled={props.disabled} variant='primary' onClick={() => {
        dispatch(addPdwRole({
            accessToken: props.accessToken,
            dataProductId: props.dataProductId,
            resourceId: props.pdwRoleType
        }));
    }} >Create {props.pdwRoleType} Role</Button>;
};

type Props = {
    accessToken: string;
    useAppSelector: useAppSelectorType;
    dataProductAccountId: string;
    dataProductId: string;
    hasDataProductAdminAccess: boolean;
};
export const PDWRoleDetails = ( props: Props ): JSX.Element => {

    const { error, status, pdwRoles } = props.useAppSelector((state) => state.resourcePdwRoles);
    const dataProductAccountPdw = Clients.Accounts.getAccountPdw(props.dataProductAccountId).split('.')[0];
    const isThereThisPdwRole = (pdwRoleType: PDWRoleType) => pdwRoles && pdwRoles.some(p => p.properties.pdwRoleType === pdwRoleType);
    return <div>
        <Snackbar show={!!error} status="danger" delay={10000} onHideSnackbar={() => cleanError()}>
            {error?.message}
        </Snackbar>
        <h3>PDW roles</h3>
        <small>These are the PDW Roles associated to your data product in Snowflake.</small>
        <hr />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            <div style={{ display: 'flex', gap: 8 }}>
                {props.hasDataProductAdminAccess && !isThereThisPdwRole(PDWRoleType.Admin) &&
                    <AddPdwRoleButton accessToken={props.accessToken} dataProductId={props.dataProductId} pdwRoleType={PDWRoleType.Admin} disabled={status === 'loading'} />}
                {props.hasDataProductAdminAccess && !isThereThisPdwRole(PDWRoleType.ReadOnly) &&
                    <AddPdwRoleButton accessToken={props.accessToken} dataProductId={props.dataProductId} pdwRoleType={PDWRoleType.ReadOnly} disabled={status === 'loading'} />}
            </div>
            <div style={{ display: 'flex' }}>
                {status === 'loading' && (
                    <Spinner size='medium' />
                )}
                {
                    status !== 'loading' && pdwRoles && pdwRoles.length > 0 && (
                        pdwRoles.map(p => <Card key={p.resourceId} style={{ marginBottom: 16, marginRight: 16, minWidth: 450 }}>

                            <h5>{p.resourceId}</h5>

                            <small style={{ color: colors.shale }}>Role Type <br /> {p.properties.pdwRoleType}</small>

                            <div style={{ float: 'right' }}>

                                <Button
                                    size='sm'
                                    variant='primary'
                                    target='_blank'
                                    href={`https://app.snowflake.com/eu-west-1/${dataProductAccountPdw}/account/role/${p.resourceId}`}
                                >Open Snowflake Role</Button>
                            </div>
                        </Card>))

                }
            </div>
        </div>
    </div>;

};