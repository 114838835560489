import React from 'react';
import { TabMenu } from '@cimpress/react-components';
import { useLocation, useHistory } from 'react-router-dom';
import { Spinner } from "@cimpress/react-components/lib/shapes";
import './DatasetDetailsNavigation.scss';
import { isLoadingPdwDataset } from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/interfaces";
import { useAppSelector } from "../../../store/storeHooks";
import { DatasetIdFormatType, translateDatasetId, PdwDatasetIdDecoder } from "../../../utils/dataset/datasetId";
import { OutputPortTabItemContent } from './OutputPortTabItemContent';
import useDataDiscoveryMetadata from "../../../hooks/useDataDiscoveryMetadata";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

type Props = {
    accessToken: string;
    isSameBusiness: boolean;
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
}

const pdwDatasetIdDecoder = new PdwDatasetIdDecoder();

export const DatasetsDetailsNavigation = (props:Props) => {
    const { outputPortCollectionState } = useAppSelector(
        (state) => state.outputPorts
    );

    const metadataHook = useDataDiscoveryMetadata({
        accessToken: props.accessToken
    });
    const history = useHistory();
    const query = useQuery();
    const activeTabId =
        query.get("dataset_tab") ||
        (outputPortCollectionState.data != null && outputPortCollectionState.data.length > 0
            ? outputPortCollectionState.data[0].resourceId
            : undefined);

    const tabs = (outputPortCollectionState.data || []).map((resource) => {
        if (isLoadingPdwDataset(resource)) {
            return {
                id: resource.resourceId,
                title: pdwDatasetIdDecoder.decode(translateDatasetId(resource.resourceId, DatasetIdFormatType.PdwDatasetId)).object.toLowerCase(),
                body: (
                    <div>
                        <h3>Loading Dataset</h3>
                        <Spinner size="medium" />
                    </div>
                ),
            };
        }
       
        return {
            id: resource.resourceId,
            title: pdwDatasetIdDecoder.decode(translateDatasetId(resource.resourceId, DatasetIdFormatType.PdwDatasetId)).object.toLowerCase(),
            body: (
                <OutputPortTabItemContent
                    isSameBusiness={props.isSameBusiness}
                    dataset={resource}
                    hasDataProductReadAccess={props.hasDataProductReadAccess}
                    hasDataProductAdminAccess={props.hasDataProductAdminAccess}
                    getMetadata={metadataHook.getMetadata}
                    datasetIdToMetadataState={metadataHook.datasetIdToMetadataState}
                />
            ),
        };
        
    });

    return <>
        <TabMenu
            activeTabId={activeTabId}
            tabs={tabs || []}
            tabStyle={{padding: 0, display: "block"}}
            onTabClick={(event, tabId) => {
                query.set('dataset_tab', tabId);
                history.push(history.location.pathname + '?' + query.toString());
            }} />
        <br />
    </ >;
};
