import React, { useState, useRef, useEffect } from 'react';

type Props = { trigger: JSX.Element, menu: JSX.Element[] };

const DpDropDown = (props: Props): JSX.Element => {

    const dropdownRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const observer = (e: MouseEvent) => {
            if (e.target instanceof HTMLElement && dropdownRef.current && open && !dropdownRef.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", observer);

    }, [dropdownRef, open]);

    const handleOpen = () => {
        setOpen(!open);
    };

    return (
        <div className="dp-dropdown" ref={dropdownRef}>
            {React.cloneElement(props.trigger, {
                onClick: handleOpen,
            })}
            {open ? (
                <ul className='dp-dropdown__menu'>
                    {props.menu.map((menuItem, index) => (
                        <li key={index} className='dp-dropdown__menu__item'>
                            {React.cloneElement(menuItem, {
                                onClick: () => {
                                    menuItem.props.onClick();
                                    setOpen(false);
                                }
                            })}
                        </li>
                    ))}
                </ul>
            )
                : null}
        </div>
    );
};

export default DpDropDown;