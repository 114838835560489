import React from "react";
import { TabMenu } from "@cimpress/react-components";
import { useLocation, useHistory } from "react-router-dom";
import { MainTabItemContent } from "./MainTabItemContent";
import "../../datasets/DatasetDetailsNavigation.scss";
import { useAppSelector } from "../../../../store/storeHooks";
import { isLoadingPdwDataset } from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/interfaces";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { DatasetIdFormatType, translateDatasetId, PdwDatasetIdDecoder } from "../../../../utils/dataset/datasetId";
import useDataDiscoveryMetadata from "../../../../hooks/useDataDiscoveryMetadata";
import { OutputPortType } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { OutputPortHook } from "../../../../hooks/useOutputPorts";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


type Props = {
    accessToken: string;
    isSameBusiness: boolean;
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
    pdwDatasetsHook: OutputPortHook<OutputPortType.PDWdataset>;
}

const pdwDatasetIdDecoder = new PdwDatasetIdDecoder();

export const MainTabMenu = (props:Props) => {
    const { pdwDatasetCollectionState } = useAppSelector(
        (state) => state.resourcesPdwDatasets
    );
    const metadataHook = useDataDiscoveryMetadata({
        accessToken: props.accessToken
    });
    const history = useHistory();
    const query = useQuery();
    const activeTabId =
        query.get("dataset_tab") ||
        (pdwDatasetCollectionState.data != null && pdwDatasetCollectionState.data.length > 0
            ? pdwDatasetCollectionState.data[0].resourceId
            : undefined);


    const tabs = (pdwDatasetCollectionState.data || []).map((resource) => {
        if (isLoadingPdwDataset(resource)) {
            return {
                id: resource.resourceId,
                title: pdwDatasetIdDecoder.decode(translateDatasetId(resource.resourceId, DatasetIdFormatType.PdwDatasetId)).object.toLowerCase(),
                body: (
                    <div>
                        <h3>Loading Dataset</h3>
                        <Spinner size="medium" />
                    </div>
                ),
            };
        }
        return {
            id: resource.resourceId,
            title: pdwDatasetIdDecoder.decode(translateDatasetId(resource.resourceId, DatasetIdFormatType.PdwDatasetId)).object.toLowerCase(),
            body: (
                <MainTabItemContent
                    isSameBusiness={props.isSameBusiness}
                    dataset={resource}
                    hasDataProductReadAccess={props.hasDataProductReadAccess}
                    hasDataProductAdminAccess={props.hasDataProductAdminAccess}
                    getMetadata={metadataHook.getMetadata}
                    datasetIdToMetadataState={metadataHook.datasetIdToMetadataState}
                    pdwDatasetsHook={props.pdwDatasetsHook}
                />
            ),
        };

    });

    return (
        <div className='pdw-dataset-resources__tab-menu-container'>
            <TabMenu
                activeTabId={activeTabId}
                tabs={tabs || []}
                tabStyle={{ padding: 0, display: "block" }}
                onTabClick={(event, tabId) => {
                    query.set("dataset_tab", tabId);
                    history.push(
                        history.location.pathname + "?" + query.toString()
                    );
                }}
            />
            <br />
        </div>
    );
};
