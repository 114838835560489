import React, { useEffect, useState } from "react";
import { AuthContext } from "../../../context/authContext";
import { useAppSelector, useAppDispatch } from "../../../store/storeHooks";
import { DataProductListItem } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { fetchDataProducts } from "@cimpress-technology/data-portal-core/lib/features/dataProducts/dataProductsSlice";
import Loading from "../discovery/components/Loading";
import useCalculateSortedFiltered from "../discovery/hooks/useCalculateSortedFiltered";
import { DataProductCard } from "../discovery/components/DataProductCard";
import { buildAuthorInfo } from "../discovery/components/utils/utils";
import useUserCoamInfoCache from "../../../hooks/useUserCoamInfoCache";
import useFirstProductTeamMember from "../../../hooks/useFirstProductTeamMember";
import { Button, TextField, Toggle } from "@cimpress/react-components";
import RecentlyViewed from "../discovery/components/RecentlyViewed";
import { CreateNewDataProductButton } from "../dataProductManagement/createDataProduct/CreateNewDataProductButton";

const RECENTS_STORAGE = "user-dataproducts__my-recents";
const OPEN_THIS_PATH = "/dataproducts/";
const ONLY_PUBLISHED = "user-dataproducts__filter-published";
const ONLY_AUTHORED_BY_ME = "user-dataproducts__filter-authored-by-me";

const getOnlyPublishedSetting = () => {
    const onlyPublished = localStorage.getItem(ONLY_PUBLISHED);
    if (onlyPublished) {
        return onlyPublished === "true";
    }
    return null;
};

const getOnlyAuthoredByMeSetting = () => {
    const onlyAuthoredByMe = localStorage.getItem(ONLY_AUTHORED_BY_ME);
    if (onlyAuthoredByMe) {
        return onlyAuthoredByMe === "true";
    }
    return null;
};

const setOnlyPublishedSetting = (value: boolean) => {
    localStorage.setItem(ONLY_PUBLISHED, value.toString());
};

const setOnlyAuthoredByMeSetting = (value: boolean) => {
    localStorage.setItem(ONLY_AUTHORED_BY_ME, value.toString());
};

const UserDataProducts = (): JSX.Element => {
    const { accessToken, profile } = React.useContext(AuthContext);
    const dispatch = useAppDispatch();
    const { dataProductList, status: dataProductListStatus } = useAppSelector(
        (state) => state.dataProducts
    );
    const [recentlyViewed, setRecentlyViewed] = useState<DataProductListItem[]>(
        []
    );
    const [searchInput, setSearchInput] = useState<string>("");
    const [onlyPublished, setOnlyPublished] = useState<boolean | null>(
        getOnlyPublishedSetting()
    );
    const [onlyAuthoredByMe, setOnlyAuthoredByMe] = useState<boolean | null>(
        getOnlyAuthoredByMeSetting()
    );
    const [resultsNumber, setResultsNumber] = useState<number>(12);
    const { dataProductsResultSet } = useCalculateSortedFiltered({
        searchInput,
        accountFilters: { selectedAccountId: null, accountIdToDomains: null },
        domainFilters: { selectedDomainId: null },
        onlyPublished: onlyPublished,
        authorCanonicalId: onlyAuthoredByMe
            ? profile?.["https://claims.cimpress.io/canonical_id"] || ""
            : null,
        analyticalFilters: { looker: null },
    });
    const { firstMemberInfo, needCoamDataForTheseCanonicalIds } =
        useFirstProductTeamMember({
            accessToken,
            dataProductIds: (dataProductsResultSet || [])
                .map((o) => o.dataProductId)
                .slice(
                    0,
                    Math.min(
                        resultsNumber,
                        (dataProductsResultSet || []).length
                    )
                )
                .concat((recentlyViewed || []).map((o) => o.dataProductId)),
        });
    const { usersCoamInfo } = useUserCoamInfoCache({
        accessToken,
        needDataForTheseCanonicalIds: needCoamDataForTheseCanonicalIds,
    });
    const fetchMyDataProducts = () =>
        accessToken
            ? dispatch(fetchDataProducts({ accessToken, onlyOwned: true }))
            : null;

    useEffect(() => {
        if (dataProductList && dataProductList.length > 0) {
            const recents = localStorage.getItem(RECENTS_STORAGE)?.split(",");
            if (recents) {
                const orderedResults: DataProductListItem[] = [];
                Array.from(new Set(recents))
                    .splice(0, 6)
                    .forEach((dpId) => {
                        const found = dataProductList.find(
                            (item) => item.dataProductId === dpId
                        );
                        if (found) orderedResults.push(found);
                    });
                setRecentlyViewed(orderedResults);
            }
        }
    }, [dataProductList]);

    useEffect(() => {
        const fetchPromise = accessToken
            ? dispatch(
                  fetchDataProducts({
                      accessToken: accessToken,
                      onlyOwned: true,
                  })
              )
            : undefined;
        return () => {
            fetchPromise && fetchPromise.abort();
        };
    }, [accessToken, dispatch]);

    const dataProductsToShow = dataProductsResultSet || [];

    return (
        <div className="discovery-container">
            <header className="user-dataproducts__header">
                <div className="user-dataproducts__header__left">
                    <CreateNewDataProductButton />
                    <TextField
                        autoFocus
                        style={{ width: 240 }}
                        onChange={(e) => setSearchInput(e.currentTarget.value)}
                        value={searchInput}
                        placeholder="Search"
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            alignItems: "center",
                        }}
                    >
                        <Toggle
                            on={onlyPublished === true}
                            size="sm"
                            onClick={() => {
                                setOnlyPublished(!onlyPublished);
                                setOnlyPublishedSetting(!onlyPublished);
                            }}
                        />
                        Published
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            alignItems: "center",
                        }}
                    >
                        <Toggle
                            on={onlyAuthoredByMe === true}
                            size="sm"
                            onClick={() => {
                                setOnlyAuthoredByMe(!onlyAuthoredByMe);
                                setOnlyAuthoredByMeSetting(!onlyAuthoredByMe);
                            }}
                        />
                        Authored by me
                    </div>
                </div>
                <div className="user-dataproducts__header__right">
                    <Button
                        onClick={fetchMyDataProducts}
                        disabled={dataProductListStatus === "loading"}
                    >
                        {dataProductListStatus === "loading"
                            ? "Loading..."
                            : "Refresh"}
                    </Button>
                </div>
            </header>
            <section className="user-dataproducts__results">
                {dataProductListStatus === "loading" && <Loading />}
                {dataProductListStatus === "succeeded" &&
                    dataProductsToShow.length === 0 && <div>No results</div>}
                {dataProductListStatus === "succeeded" && (
                    <div className="discovery__results__items">
                        {dataProductsToShow
                            .slice(
                                0,
                                Math.min(
                                    resultsNumber,
                                    dataProductsToShow.length
                                )
                            )
                            .map((o) => {
                                return (
                                    <DataProductCard
                                        key={o.dataProductId}
                                        dataProductId={o.dataProductId}
                                        name={o.dataProductName}
                                        description={o.summary}
                                        domainId={o.domainId}
                                        published={o.published}
                                        authorInfo={buildAuthorInfo(
                                            firstMemberInfo[o.dataProductId],
                                            usersCoamInfo
                                        )}
                                        lastModified={o.updatedAt}
                                        outputPorts={o.outputPorts}
                                        openThisPath={OPEN_THIS_PATH}
                                        storeVisitIn={RECENTS_STORAGE}
                                    />
                                );
                            })}
                    </div>
                )}
            </section>
            <div className="center">
                {dataProductsToShow.length > 0 &&
                    resultsNumber < dataProductsToShow.length && (
                        <Button
                            style={{ marginBottom: 10 }}
                            onClick={() => {
                                setResultsNumber(
                                    Math.min(
                                        resultsNumber + 12,
                                        dataProductsToShow.length
                                    )
                                );
                            }}
                        >
                            View More (Showing{" "}
                            {Math.min(resultsNumber, dataProductsToShow.length)}{" "}
                            of {dataProductsToShow.length})
                        </Button>
                    )}
            </div>
            {dataProductListStatus === "succeeded" && (
                <RecentlyViewed
                    recentlyViewed={recentlyViewed}
                    firstMemberInfo={firstMemberInfo}
                    usersCoamInfo={usersCoamInfo}
                    openThisPath={OPEN_THIS_PATH}
                    storeVisitIn={RECENTS_STORAGE}
                    showPublishedMark={true}
                />
            )}
            <div className="center"></div>
        </div>
    );
};

export default UserDataProducts;
