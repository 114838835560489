import React from 'react';
import { DataPortalAPI, Interfaces } from "@cimpress-technology/data-portal-core";
import { DatasetsDetailsContent } from './DatasetsDetailsContent';

export const DatasetsDetails: React.FC<{
    dataProduct: DataPortalAPI.DataProduct,
    hasDataProductReadAccess: boolean,
    hasDataProductAdminAccess: boolean,
    adminCoamGroupHook?: Interfaces.DataPortalAPI.CoamGroupHook,
    readCoamGroupHook?: Interfaces.DataPortalAPI.CoamGroupHook,
    domain: DataPortalAPI.Domain,
}> = ({ dataProduct, domain,  hasDataProductReadAccess, hasDataProductAdminAccess }) => {

    return <div>

        <div className='row'>
            <div className='col-md-7'>
                <h3 style={{margin: 0}}>Datasets</h3>
                <small>Datasets that are included in the data product.</small>
            </div>
        </div>
        <hr />
        <DatasetsDetailsContent hasDataProductAdminAccess={hasDataProductAdminAccess} hasDataProductReadAccess={hasDataProductReadAccess} dataProduct={dataProduct} domain={domain} />
    </div>;
};
