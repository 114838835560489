import {
    DataProductListItem,
    OutputPortType,
} from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import {
    ErrorFirstMember,
    ValidFirstMember,
    LoadingFirstMember,
    isErrorFirstMember,
    isLoadingFirstMember,
} from "@cimpress-technology/data-portal-core/lib/features/firstMemberCache/firstMemberCacheSlice";
import { UserInformationCache, isErrorUserInformation, isLoadingUserInformation } from "@cimpress-technology/data-portal-core/lib/features/coamUserInfoCache/coamUserInfoCacheSlice";
import { getDefaultAvatar } from "../../../../../common";


export const determineBackground = (storageName: string, options: string[]): string => {
    const currentPattern = sessionStorage.getItem(storageName);
    if (currentPattern) return currentPattern;
    const newCurrentPattern =
        options[Math.floor(Math.random() * options.length)];
    sessionStorage.setItem(storageName, newCurrentPattern);
    return determineBackground(storageName, options);
};

export const getNumberOfOutputPortsByType = (
    outputPorts: DataProductListItem["outputPorts"],
    outputPortType: OutputPortType
) => {
    return (outputPorts || []).filter((o) => o.portType === outputPortType)
        .length;
};

export const buildAuthorInfo = (
    firstMember: ValidFirstMember | ErrorFirstMember | LoadingFirstMember | null,
    usersCoamInfo: UserInformationCache
) => {
    if (firstMember == null) return null;
    if (isErrorFirstMember(firstMember)) return { error: firstMember.errorMessage };
    if (isLoadingFirstMember(firstMember)) return null;
    const userInfo = usersCoamInfo[firstMember.data.canonicalId];
    if (userInfo == null) return null;
    if (isLoadingUserInformation(userInfo)) return null;
    if (isErrorUserInformation(userInfo)) return { error: userInfo.errorMessage };
    return {
        name: userInfo.data.fullName,
        label: firstMember.data.labels.join(','),
        picture: userInfo.data.picture || getDefaultAvatar(userInfo.canonicalPrincipalId),
    };
};
