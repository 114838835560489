import React, { useEffect } from "react";
import IconIdCard from "@cimpress-technology/react-streamline-icons/lib/IconIdCard";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import IconEmailActionUnread from "@cimpress-technology/react-streamline-icons/lib/IconEmailActionUnread";
import {
    isErrorUserInformation,
    isLoadingUserInformation,
    isValidUserInformation,
} from "@cimpress-technology/data-portal-core/lib/features/coamUserInfoCache/coamUserInfoCacheSlice";
import { buildSlackURL } from "../utils";
import useProductTeam from "../../../../hooks/useProductTeam";
import { getDefaultAvatar } from "../../../../common";

type Props = {
    dataProduct: {
        loading: boolean;
        dataProductId: string;
        supportMail?: string;
        supportSlack?: string;
    }
    account: {
        loading: boolean;
        name: string | null;
    }
    accessToken?: string;
    setProductTeamLoading: (loading: boolean) => void;
};

const ProductTeam = (props: Props): JSX.Element => {
    const { setProductTeamLoading } = props;

    const {
        status: productTeamStatus,
        productTeam,
        error: productTeamError,
    } = useProductTeam({
        accessToken: props.accessToken,
        dataProductId: props.dataProduct.dataProductId,
    });

    useEffect(() => {
        setProductTeamLoading(productTeamStatus === "loading");
    }, [productTeamStatus, setProductTeamLoading]);

    return (
        <div className="dp-card summary__product-team">
            <div className="summary__card__header">
                <span className="summary__card__header__icon">
                    <IconIdCard />
                </span>
                <span className="summary__card__header__text">
                    Product Team
                </span>
            </div>
            <div className="summary__card__body">
                <div className="summary__product-team__members">
                    {productTeamStatus === "loading" && (
                        <Spinner size="small" />
                    )}
                    {productTeamStatus === "succeeded" &&
                        productTeam.length > 0 &&
                        productTeam.map((m) => (
                            <div key={m.canonicalId}>
                                {isValidUserInformation(m.coamInfo) && (
                                    <>
                                        <img
                                            src={
                                                m.coamInfo.data.picture ||
                                                getDefaultAvatar(m.canonicalId)
                                            }
                                        />
                                        <span>
                                            <span>
                                                {m.coamInfo.data.email ? (
                                                    <a
                                                        href={`mailto:${m.coamInfo.data.email}`}
                                                    >
                                                        {
                                                            m.coamInfo.data
                                                                .fullName
                                                        }
                                                    </a>
                                                ) : (
                                                    m.coamInfo.data.fullName
                                                )}
                                            </span>
                                            <span>
                                                {m.memberInfo.labels.join(", ")}
                                            </span>
                                        </span>
                                    </>
                                )}
                                {isLoadingUserInformation(m.coamInfo) && (
                                    <Spinner size="small" />
                                )}
                                {isErrorUserInformation(m.coamInfo) && (
                                    <>{m.coamInfo.errorMessage}</>
                                )}
                            </div>
                        ))}
                    {productTeamStatus === "succeeded" &&
                        productTeam.length === 0 && (
                            <small className="empty-message text-muted">
                                There are no team members
                            </small>
                        )}
                    {productTeamStatus === "failed" && (
                        <small className="empty-message text-muted">
                            {productTeamError.message}
                        </small>
                    )}
                </div>
                <div className="summary__product-team__support">
                    <span>CONTACT</span>
                    {props.dataProduct.loading && <Spinner size="small" />}
                    {!props.dataProduct.loading && (
                        <>
                            <div>
                                <span className="support-icon">
                                    <svg
                                        viewBox="0 0 32 32"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g strokeWidth="1" fillRule="evenodd">
                                            <g transform="translate(-468.000500, -0.000190)">
                                                <path
                                                    d="M479.573348,13.9061807 L481.725379,20.3302738 L488.399476,18.0942414 L486.247444,11.6701482 L479.573348,13.9061807 Z M494.953171,21.3608888 L496.075187,24.7089373 C496.529193,26.064957 495.797183,27.5329783 494.441163,27.9869849 C494.147159,28.0849863 493.847155,28.1289869 493.55315,28.1209868 C492.495135,28.0929864 491.519121,27.4149766 491.165116,26.3529612 L490.043099,23.0049126 L483.369003,25.240945 L484.491019,28.5889936 C484.945025,29.9450133 484.215015,31.4110345 482.858995,31.8670411 C482.562991,31.9650425 482.262987,32.0070432 481.968982,31.999043 C480.912967,31.9730427 479.936953,31.2950328 479.580948,30.2330174 L478.458931,26.8849689 L475.222884,27.9689846 C474.92688,28.066986 474.626876,28.1089866 474.334872,28.1029865 C473.276856,28.0749861 472.300842,27.3969763 471.944837,26.3349609 C471.49083,24.9789412 472.222841,23.51292 473.578861,23.0589134 L476.814908,21.9748977 L474.662876,15.5508045 L471.426829,16.6348202 C471.132825,16.7328217 470.832821,16.7768223 470.538817,16.7688222 C469.480801,16.7408218 468.504787,16.0628119 468.150782,15.0007965 C467.694775,13.6447769 468.426786,12.1787556 469.782806,11.724749 L473.018852,10.6407333 L471.896836,7.29268477 C471.44283,5.93666511 472.17484,4.46864382 473.53086,4.01463724 C474.88688,3.56063066 476.352901,4.29264127 476.806907,5.6466609 L477.928924,8.99670948 L484.60302,6.76067706 L483.481004,3.41262851 C483.026998,2.05660885 483.757008,0.588587558 485.113028,0.134580974 C486.469048,-0.319425609 487.937069,0.412585006 488.391075,1.76860467 L489.513092,5.11665322 L492.749139,4.0326375 C494.105158,3.57863092 495.57118,4.31064153 496.027186,5.66466117 C496.481193,7.02068083 495.749182,8.48870212 494.393162,8.9427087 L491.157116,10.0267244 L493.309147,16.4508176 L496.545194,15.3668019 C497.901213,14.9127953 499.367235,15.6428059 499.821241,16.9988255 C500.277248,18.3548452 499.545237,19.8228665 498.189217,20.2768731 L494.953171,21.3608888 Z"
                                                    id="Fill-229"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <span>
                                    {props.dataProduct.supportSlack &&
                                    props.dataProduct.supportSlack.trim().length > 0 ? (
                                        props.account.loading ? <Spinner size="small" /> :
                                        <a
                                            href={buildSlackURL(
                                                props.dataProduct.supportSlack, props.account.name
                                            )}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {props.dataProduct.supportSlack}
                                        </a>
                                    ) : (
                                        <span className="empty-message text-muted">
                                            No Slack channel provided
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div>
                                <span className="support-icon">
                                    <IconEmailActionUnread weight="fill" />
                                </span>
                                <span>
                                    {props.dataProduct.supportMail ? (
                                        <a href={`mailto:${props.dataProduct.supportMail}`}>
                                            {props.dataProduct.supportMail}
                                        </a>
                                    ) : (
                                        <span className="empty-message text-muted">
                                            No contact email provided
                                        </span>
                                    )}
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductTeam;
