import React, { useEffect, useState, useMemo } from 'react';
import SimpleMDEReact from "react-simplemde-editor";
import SimpleMDE from "easymde";
import "easymde/dist/easymde.min.css";
import ReactMarkdown from 'react-markdown';
import { Button, shapes, colors, Tooltip, Snackbar, CodeBlock } from '@cimpress/react-components';
import { Interfaces, Clients } from "@cimpress-technology/data-portal-core";
import * as Sentry from "@sentry/react";
import { AuthContext } from '../../../../context/authContext';
import { parseErrorToAxiosErrorWrapper } from '@cimpress-technology/data-portal-core/lib/features/utils';


export const DataProductDomainHeader: React.FC<{
    domain: Interfaces.DataPortalAPI.DomainPayload;
    hasPermission: boolean;
    onEdit(domanId: string, description: string): void;
}> = ({ domain, onEdit, hasPermission }) => {

    const { accessToken } = React.useContext(AuthContext);
    const [newDescription, setNewDescription] = useState(domain.description);
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null as Error | null);

    useEffect(() => {
        setIsEditing(false);
        setNewDescription(domain.description);
    }, [domain?.description]);

    const onChange = (value: string) => setNewDescription(value);
    const editorOptions = useMemo(() => {
        return {
            spellChecker: false,
            showIcons: ["code", "horizontal-rule", "undo", "redo", "heading-1", "heading-2", "heading-3"],
            hideIcons: ["heading"]
        } as SimpleMDE.Options;
    }, []);

    if (!accessToken || !domain) {
        return null;
    }

    const updateDomain = async () => {
        setIsUpdating(true);
        setUpdateError(null);
        try {
            if (newDescription?.trim()) {
                await Clients.DataPortalAPI.updateDomain(accessToken || '', {
                    domainId: domain.domainId,
                    accountId: domain.accountId,
                    name: domain.name,
                    description: newDescription
                });
                setIsEditing(false);
                onEdit(domain.domainId, newDescription);
                domain.description = newDescription; // Set local data
            } else {
                setNewDescription(domain.description); // If empty, go back to default
                setUpdateError(new Error('You need to have a description.'));
            }
        } catch (error) {
            Sentry.captureException(error, { tags: { ...domain } });
            setUpdateError(error as Error);
        }
        setIsUpdating(false);
    };

    const axiosErrorWrapper = updateError ? parseErrorToAxiosErrorWrapper(updateError): null;
    const editControls =
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <Button
                disabled={!!updateError}
                style={{ marginRight: 5 }}
                onClick={() => { setIsEditing(false); setNewDescription(domain.description); }}
            >
                Cancel
            </Button>
            <Button
                variant='primary'
                onClick={() => {updateDomain();}}
                disabled={!!updateError}
            >
                {!isUpdating
                    ? 'Update'
                    : <span style={{ display: 'flex' }}>
                        <shapes.Spinner size='small' />&nbsp; Updating...
                    </span>}
            </Button>
        </div>;

    return (<div>
                <Snackbar show={updateError ? true : false} status="danger" delay={10000} onHideSnackbar={() => { setUpdateError(null); }}>
                    <p>{axiosErrorWrapper?.defaultTitle}</p>
                    {axiosErrorWrapper?.details && (<CodeBlock code={axiosErrorWrapper?.details}/>)}
                </Snackbar>
                <div style={{ display: "flex", flexDirection:"column" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -5 }}>
                        <div style={{ color: colors.shale, marginBottom: 6 }}><small>Description</small></div>
                        <div>
                            {hasPermission && !isEditing && (
                                <Tooltip direction='top' contents={(<div>Edit Domain Description</div>)}>
                                    <Button size='sm' variant='link' onClick={() => setIsEditing(true)}>
                                        <i className="fa fa-pencil text-primary" style={{ padding: 0, fontSize: 20 }}></i>
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    {isEditing
                        ? <div style={{ display: "flex", flexDirection: "column" }}>
                            {(!isUpdating && !updateError) &&
                                <SimpleMDEReact
                                    options={editorOptions}
                                    value={newDescription}
                                    onChange={onChange}
                                />
                            }
                            {isUpdating
                                ? <><shapes.Spinner size='medium'/><span style={{color: colors.shale}}>Updating Data Domain Description</span></>
                                : editControls
                            }
                        </div>
                        : <span style={{ color: colors.granite.base }}><ReactMarkdown>{domain.description}</ReactMarkdown></span>
                    }
                </div>
            </div>
            );
};
