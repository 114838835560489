import React, { useState } from "react";
import { CustomField } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { IconPencilAlt } from "@cimpress-technology/react-streamline-icons/lib";
import { colors } from "@cimpress/react-components";
import { updateCustomFieldValueInDataProduct } from "@cimpress-technology/data-portal-core/lib/features/customFields/common";
import { useAppDispatch } from "../../../../store/storeHooks";
import CustomFieldValueView from "../view/CustomFieldValueView";
import { CustomFieldEditorFactory } from "./CustomFieldEditorFactory";

type Props = {
    customField: CustomField;
    accessToken: string;
    dataProductId: string;
};

function DataProductCustomFieldEditable(props: Props) {
    const dispatch = useAppDispatch();
    const [edit, setEdit] = useState<boolean>(false);
    const updateValue = (value: string) => {
        dispatch(
            updateCustomFieldValueInDataProduct({
                accessToken: props.accessToken,
                dataProductId: props.dataProductId,
                customFieldId: props.customField.customFieldId,
                value: value,
            })
        ).then((response) => {
            if (response.meta.requestStatus === "fulfilled") {
                setEdit(false);
            }
        });
    };

    return (
        <div style={{ marginBottom: 40 }} key={props.customField.customFieldId}>
            <h5>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <h5>{props.customField.displayName}</h5>
                </div>
            </h5>
            {edit ? (
                <CustomFieldEditorFactory
                    customFieldId={props.customField.customFieldId}
                    displayName={props.customField.displayName}
                    value={props.customField.value}
                    fnOnSave={updateValue}
                    fnOnClose={() => setEdit(false)}
                />
            ) : (
                <div
                    style={{
                        display: "flex",
                        gap: 8,
                    }}
                >
                    <CustomFieldValueView value={props.customField.value} />
                    <div style={{ minWidth: 20 }}>
                        <IconPencilAlt
                            weight="fill"
                            style={{ cursor: "pointer", height: 18 }}
                            color={colors.info.base}
                            size="sm"
                            onClick={() => setEdit(true)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default DataProductCustomFieldEditable;
