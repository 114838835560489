import { useState, useEffect } from 'react';
import { ReactAppEnvironment } from '../common';
import { useAppDispatch, } from "../store/storeHooks";
import { UserDetails } from '@cimpress-technology/data-portal-core/lib/common/interfaces';
import { ExtendedDataProduct } from '../clients/dataPortalApi';
import { Clients } from "@cimpress-technology/data-portal-core";


export interface ComputeUIProps {
    accessToken: string;
    domainId: string;
    dataProductId: string;
    accountName: string;
}


const getComputeApiUrl = () => {
    const baseUrl = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'prd.compute-ui.pdw.cimpress.io' : 'dev.compute-ui.pdw.cimpress.io';    
    return `https://${baseUrl}/`;
};

export const useSnowflakeWarehouse = (userDetails: UserDetails, dataProduct: ExtendedDataProduct | null): {
    computeModuleProps: Partial<ComputeUIProps> | undefined
} => {

    const dispatch = useAppDispatch();

    const [computeModuleProps, setComputeModuleProps] = useState<Partial<ComputeUIProps>>();
    

    useEffect(() => {
        if(dataProduct == null)
            return;
        const accessToken = userDetails.accessToken || '';

        const dataProductId = dataProduct.dataProductId || '';
        const domainId = dataProduct.domainId;
        const apiUrl = getComputeApiUrl();
        const accountName = Clients.Accounts.getAccountPdw(userDetails.accountId || "").split('.')[0].toUpperCase();
        const computeModuleprops = {accessToken, domainId, dataProductId, accountName, apiUrl};            
        setComputeModuleProps(computeModuleprops);
    
    }
    , [userDetails.accessToken,userDetails.accountId,dataProduct, dataProduct?.dataProductId,dispatch]);

    return { computeModuleProps };
};
