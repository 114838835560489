export type UpdateCustomFieldProps = {
    customFieldId: string;
    displayName: string;
    value: string;
    fnOnClose: () => void;
    fnOnSave: (value: string) => void;
};

export const isSelectEditor = (
    editor: SelectEditor | TextEditor | undefined
): editor is SelectEditor => {
    if (!editor) {
        return false;
    }
    return editor.type === "SelectEditor";
};
type SelectEditor = {
    type: "SelectEditor";
    options: Array<{ value: string; label: string }>;
};

type TextEditor = {
    type: "TextEditor";
};

export enum ManagedCustomField {
    CurrentScale = "Current Scale",
    FinancialImpact = "Financial Impact",
    DataProductType = "Data Product Type",
    ImportantLinks = "Important Links",
    Scope = "Scope",
    Impact = "Impact",
}

export const EditableConfiguration = new Map<string, TextEditor | SelectEditor>(
    [
        [ManagedCustomField.CurrentScale, { type: "TextEditor" }],
        [ManagedCustomField.FinancialImpact, { type: "TextEditor" }],
        [ManagedCustomField.DataProductType, { type: "TextEditor" }],
        [ManagedCustomField.ImportantLinks, { type: "TextEditor" }],
        [ManagedCustomField.Scope, { type: "TextEditor" }],
        [ManagedCustomField.Impact, { type: "TextEditor" }],
    ]
);
