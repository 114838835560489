import React from "react";
import { colors } from "@cimpress/react-components";

type Props = {
    columns: { columnType: string; name: string; description: string }[];
};

function MetadataColumns(props: Props) {
    return (
        <>
            {(props.columns || []).map((c) => (
                <div key={c.name}>
                    <h6>
                        {c.name}{" "}
                        <span style={{ color: colors.shale }}>: {c.columnType}</span>
                    </h6>
                    <p
                        style={{
                            color: c.description ? colors.shale : colors.alloy,
                        }}
                    >
                        {c.description || "Description not defined"}
                    </p>
                    <br />
                </div>
            ))}
        </>
    );
}

export default MetadataColumns;
