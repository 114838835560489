import React, { useState } from 'react';
import { CodeBlock, Button, colors, Alert } from '@cimpress/react-components';
import { parseErrorToAxiosErrorWrapper } from '@cimpress-technology/data-portal-core/lib/features/utils';

export const ErrorDetails: React.FC<{
    title?: string;
    error: Error | null;
    retryFunction?(): void
}> = ({ title, error, retryFunction }) => {

    const [showDetails, setShowDetails] = useState(false);
    const axiosError = parseErrorToAxiosErrorWrapper(error);

    return (
        <Alert
            dismissible={false}
            message={<>
                <div>
                    {title ? <><span>{title}</span><br /></> : null}
                    <small style={{ color: colors.shale }}>{axiosError.defaultTitle}</small>
                </div>
                <br />
                {
                    axiosError.details ? <>
                        <Button variant="anchor" onClick={() => setShowDetails(!showDetails)}>
                            <small>{showDetails ? 'Hide details' : 'Show details'}</small>
                        </Button>
                        {showDetails ? <CodeBlock code={axiosError.details} /> : null}
                        <br /> <br />
                    </>
                        : null
                }

                {retryFunction ? <Button variant="default" size="sm" onClick={retryFunction}>Try again</Button> : null}
            </>} />
    );
};
