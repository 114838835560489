import { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext } from "react";

export interface SegmentContextProps {
    segment: AnalyticsBrowser
}

const defaultSegmentContext = {
    segment: new AnalyticsBrowser(),
};

export const SegmentContext = createContext<SegmentContextProps>(
    defaultSegmentContext
);