import { useState, useEffect } from 'react';
import { DataPortalAPI, Clients } from "@cimpress-technology/data-portal-core";
import { OutputPortComponentEmbedded, OutputPortType } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalAPI/outputPorts';

let abortController = new AbortController();
const abortCurrentRequest = () => {
    abortController.abort();
    abortController = new AbortController();
};


const loadOutputPorts = async (accessToken, dataProductId, outputPortType, setLoading, setError, setOutputPorts) => {
    setLoading(true);
    setError(null);

    if (accessToken) {
        try {
            const embeddedOutputPorts = await Clients.DataPortalAPI.getOutputPorts(accessToken, dataProductId, outputPortType, abortController);
            setOutputPorts(embeddedOutputPorts);
        } catch (error) {
            console.error(error);
            setError(error);
        }
        setLoading(false);
    }

    setLoading(false);
};



export const useOutputPorts = (accessToken: string | undefined, dataProductId: string, outputPortType: DataPortalAPI.OutputPortTypePath): {
    loadingOutputPorts: boolean;
    outputPorts: DataPortalAPI.OutputPortComponentEmbedded<DataPortalAPI.OutputPortType>[] | [];
    OutputPortError: Error | null;
    loadOutputPorts(): void;
    abortCurrentRequest(): void;
} => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null as Error | null);
    const [outputPorts, setOutputPorts] = useState([]);


    useEffect(() => {
        loadOutputPorts(accessToken, dataProductId, outputPortType, setLoading, setError, setOutputPorts);
    }, [accessToken, dataProductId, outputPortType]);

    return {
        loadingOutputPorts: loading,
        OutputPortError: error,
        outputPorts,
        loadOutputPorts: () => loadOutputPorts(accessToken, dataProductId, outputPortType, setLoading, setError, setOutputPorts),
        abortCurrentRequest
    };
};

export interface OutputPortHook<T extends OutputPortType> {
    loadingOutputPorts: boolean;
    outputPorts: OutputPortComponentEmbedded<T>[] | [];
    OutputPortError: Error | null;
    loadOutputPorts(): void;
    abortCurrentRequest(): void;
}