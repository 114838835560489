import { useEffect, useState } from "react";
import {
    AccountCollection,
} from "../components/screens/discovery/components/utils/customTypes";
import { Clients } from "@cimpress-technology/data-portal-core";
import { SliceState } from "../features/common";

interface GenericState {
    status: SliceState;
    reset: () => void;
}

interface IdleState extends GenericState {
    status: "idle";
    accountsInfo: undefined;
}

interface LoadingState extends GenericState {
    status: "loading";
    accountsInfo: undefined;
}

interface SuccessState extends GenericState {
    status: "succeeded";
    accountsInfo: AccountCollection;
}

type ReturnType = (IdleState | LoadingState | SuccessState) & GenericState;

type Props = {
    accessToken?: string;
    needTheseAccountIds: string[];
};
const useAccountDetails = (props: Props): ReturnType => {
    const [accountsInfo, setAccountsInfo] = useState<AccountCollection>();
    const [status, setStatus] = useState<SliceState>("idle");
    const reset = () => {
        setAccountsInfo({});
        setStatus("idle");
    };
    useEffect(() => {
        const getAccountsDetails = async () => {
            const accountIds = props.needTheseAccountIds;
            const accessToken = props.accessToken;
            if (
                accountIds.length > 0 &&
                !accountsInfo &&
                accessToken !== undefined
            ) {
                setStatus("loading");
                const results = await Promise.all(
                    accountIds.map(
                        async (accountId) =>
                            await Clients.Accounts.getAccount(
                                accessToken,
                                accountId
                            ).catch(() => ({
                                accountId: accountId,
                                name: "Not available",
                                status: "Active" as const,
                                description: "Error",
                                cimpressOwned: false,
                                error:
                                    "Error loading account information: " +
                                    accountId,
                            }))
                    )
                );
                const accountDetails: AccountCollection = {};
                results.forEach(
                    (accDetail) =>
                        (accountDetails[accDetail.accountId] = accDetail)
                );
                setAccountsInfo(accountDetails);
                setStatus('succeeded');
            }
        };
        getAccountsDetails();
    }, [props.needTheseAccountIds, props.accessToken, accountsInfo]);

    switch (status) {
        default:
        case "idle":
            return {
                status: "idle",
                accountsInfo: undefined,
                reset: reset,
            };
        case "loading":
            return {
                status: "loading",
                accountsInfo: undefined,
                reset: reset,
            };
        case "succeeded":
            return {
                status: "succeeded",
                accountsInfo: accountsInfo || {},
                reset: reset,
            };
    }
};

export default useAccountDetails;
