import { colors, Label, Modal, Tooltip } from '@cimpress/react-components';
import React, { useState } from 'react';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { renderLoading, renderError } from '../../../shared/Render';
import { AuthContext } from '../../../../context/authContext';
import { useDataContract } from "@cimpress-technology/data-contracts-module-package/lib/hooks/useDataContract";
import { useDataProduct } from '../../../../hooks/useDataProduct';
import RequestDatasetAccess from '../../../../assets/RequestDatasetAccess.gif';
import { IconShareAlt, IconAlertTriangle } from '@cimpress-technology/react-streamline-icons/lib';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import { getInputPortsDatasets } from '@cimpress-technology/data-portal-core/lib/features/inputPorts/common';
import _ from 'lodash';
import { useFilteredRules } from '@cimpress-technology/data-contracts-module-package/lib/hooks';



export const InputportPDWDatasetDetails: React.FC<{
    dataProductId: string;
    hasDataProductManagePermission: boolean;
}> = ({ dataProductId, hasDataProductManagePermission }) => {

    const [showHowItWorksModal, setShowHowItWorksModal] = useState(false);
    return <>
        <div>
            <h3>PDW Dataset<small className="text-primary"> Read only</small></h3>
            <a className='pull-right' onClick={() => setShowHowItWorksModal(true)} style={{ cursor: 'pointer' }}>How this works ?</a>
            <small>The PDW Dataset that are shared to this Data product from another data product</small>
            <hr />
            <br />
            { hasDataProductManagePermission ? <InputportPDWDatasetDetailsSection dataProductId={dataProductId} /> : undefined }
        </div>
        <Modal
            show={showHowItWorksModal}
            status="info"
            onRequestHide={() => setShowHowItWorksModal(false)}
            closeOnOutsideClick={true}
            title="Linking PDW Dataset to a Data Product's Input Port"
            bsStyle="info"
            closeButton={true}
            size='lg'
        >
            <div className='row card-block' style={{ textAlign: 'justify' }}>
                <div className='col-xs-12 col-md-6 card-block' style={{ height: 270 }}>
                    <h5>1. You click on request dataset access from PDW dataset page of another Data product..</h5>
                    <div className='col-xs-12' style={{ textAlign: 'center' }}><img src={RequestDatasetAccess} style={{ objectFit: 'contain', height: '200px' }} /></div>
                </div>
                <div className='col-xs-12 col-md-6 card-block' style={{ height: 270 }}>
                    <h5>2. Producer will share the dataset to consumers business account.</h5>
                    <div className='col-xs-12' style={{ textAlign: 'center' }}><IconShareAlt size="lg" className="text-primary" style={{ height: '200px', transform: "rotate(90deg)" }} weight="fill" /></div>
                </div>
                <div className='col-xs-12 col-md-6 card-block' style={{ height: 270 }}>
                    <h5>3. Producer or consumer signs the contract between producer data product and consumer data product for the requested dataset (Producer approval is needed).</h5>
                    <div className='col-xs-12' style={{ textAlign: 'center' }}><p className="fa fa-handshake-o text-primary" style={{ fontSize: '200px' }} /></div>
                </div>
                <div className='col-xs-12 col-md-6 card-block' style={{ height: 270 }}>
                    <h5>4. Consumer accessible dataset is automatically linked after signing and owners of consumer data product has access to this dataset.</h5>
                    <div className='col-xs-12' style={{ textAlign: 'center' }}><p className="fa fa-check-square-o text-success" style={{ fontSize: '200px' }} /></div>
                </div>
            </div>
        </Modal>
    </>;
};

const InputportPDWDatasetDetailsSection: React.FC<{ dataProductId: string }> = ({ dataProductId }) => {

    const dispatch = useAppDispatch();
    const inputPortDatasetState = useAppSelector(state => state.inputPortDataset);
    const { accessToken } = React.useContext(AuthContext);
    const { status, inputPortDatasets, error } = inputPortDatasetState;

    if (status === "loading") {
        return renderLoading('Loading pdw datasets...', true);
    }

    if (error && accessToken) {
        return renderError('Error loading pdw Datasets.', error as Error, () => dispatch(getInputPortsDatasets({ accessToken, dataProductId: dataProductId, isBackgroundGet: true})));
    }

    return <div>
        <div className='row' style={{ overflowWrap: 'break-word' }}>
            {
                inputPortDatasets?.length ?
                    inputPortDatasets.map(dataset =>
                        <div className='col-md-6 card-block' key={dataset.resourceId}>
                            <SingleDatasetDetailsCard pdwDatasetDetails={dataset} dataProductId={dataProductId} />
                        </div>
                    )
                    : <span className='col-xs-12'>There are no Datasets from other Data product linked to this Data product.</span>
            }

        </div>
    </div>;
};

const SingleDatasetDetailsCard: React.FC<{ pdwDatasetDetails: DataPortalAPI.InputPortComponentEmbedded<DataPortalAPI.InputPortType.PDWDataset>, dataProductId: string }> = ({ pdwDatasetDetails, dataProductId }) => {
    const { accessToken } = React.useContext(AuthContext);
    const { dataContract, dataContractError, loadingDataContract, loadDataContract } = useDataContract(accessToken, pdwDatasetDetails.properties.contractId);
    const { rules, loadFilteredRules, filteredRulesError, loadingFilteredRules } = useFilteredRules(accessToken, [dataProductId], undefined, [pdwDatasetDetails.properties.contractId]);
    // only_access is a fake rule that is creted by defualt in contracts. Having this rule doesn't count to rules.
    const valid_rule = rules?.filter(rule => rule.type !== 'only_access');
    return <div className='modal-content card-block'>
        <Label className='pull-right' style={{ position: 'absolute', right: 10 }} status={dataContract?.status ? 'success' : 'default'} text={_.capitalize(dataContract?.status || 'loading...')} ></Label>
        <small>DatasetId</small>
        <div style={{ marginTop: 0 }} ><b>{pdwDatasetDetails.resourceId}</b></div>
        <br />
        {
            loadingDataContract ?
                renderLoading('Loading details...', true, 'medium')
                :
                dataContractError ?
                    renderError('Error loading contract details...', dataContractError, loadDataContract)
                    :
                    <div>
                        <small><b>Source details</b></small><br />
                        {dataContract ?
                            <ProducerDataProductDetails dataProductId={dataContract.producerProductId} accessToken={accessToken} />
                            : null
                        }
                        <small>Via Data Contract: <a className='text-primary' href={`/data-contracts/${dataContract?.guid}`} target="_blank" rel="noreferrer">{dataContract?.guid}</a></small>
                    </div>
        }
        <hr className='row col-xs-12' />
        <div>
            {
                loadingFilteredRules ?
                    renderLoading('Loading rules...', false, "small")
                    :
                    filteredRulesError ?
                        renderError('Error loading contract rules', filteredRulesError, loadFilteredRules)
                    :
                        !valid_rule?.length ?
                            <>
                                <Tooltip contents="Rules governs the quality of the data product. Add rules to the contract for data quality assurance.">
                                    <IconAlertTriangle weight="fill" style={{ color: colors.warning.base }} /> No consumer rules defined for the contract.
                                </Tooltip>
                            </>
                        :
                            <>
                                Rules: {
                                    valid_rule.map(rule =>
                                        <Tooltip
                                            tooltipInnerStyle={{textAlign: 'left', overflowWrap: 'break-word'}}
                                            contents={
                                                <>
                                                    <a className='text-primary'>Rule Body: </a><br />
                                                    <hr style={{ marginTop: 5, marginBottom: 5, padding: 0 }} />
                                                    {JSON.stringify(rule.body, undefined, 1)}<br /><br />
                                                    <a className='text-primary'>Rule Status: </a><br />
                                                    <hr style={{ marginTop: 5, marginBottom: 5, padding: 0 }} />
                                                    {rule.status}
                                                </>
                                            }
                                            key={rule.name}
                                        >
                                            <Label status={rule.status === "Active" ? 'success' : 'warning'} text={rule.name} style={{ marginLeft: 3 }} />
                                        </Tooltip>)
                                }
                            </>
            }
        </div>
        {/* We will show warnings like no rules defined etc later in this space */}
    </div>;
};

const ProducerDataProductDetails: React.FC<{ dataProductId: string, accessToken: string | undefined }> = ({ dataProductId, accessToken }) => {
    const { dataProduct, dataProductError, loadingDataProduct, loadDataProduct } = useDataProduct(accessToken, dataProductId);

    return <>
        {
            loadingDataProduct ?
                renderLoading('Loading producer data product details', false, 'small')
                : !dataProductError ?
                    <><small> Shared from producer Data Product: <a className='text-primary' href={`/dataProducts/${dataProduct?.dataProductId}`} target="_blank" rel="noreferrer">{dataProduct?.dataProductName}</a></small><br /></>
                    : renderError('Error loading producer data product details', dataProductError, loadDataProduct)
        }
    </>;
};