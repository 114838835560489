import React from 'react';
import { colors } from '@cimpress/react-components';
import { DomainService, DomainServiceItem } from './DomainServiceItem';

export interface DataDomain {
    name: string;
    desc: string;
    documentationLink: string;
    icon: React.ReactElement;
    services: DomainService[]
}


export const DomainSection: React.FC<{
    dataDomain: DataDomain
}> = ({ dataDomain }) => {
    const { name, desc, icon, documentationLink, services } = dataDomain;
    const serviceRows = services
        .reduce((rows, service, i) => {
            if (i % 3 === 0) {
                rows.push([]);
            }
            rows[rows.length - 1].push(service);
            return rows;
        }, [] as Array<DomainService[]>);

    return <div>
        <div className='row' >
            <div className='col-md-2'>
                <div className='services-and-tools-icon' style={{ color: colors.persimmon.base, backgroundColor: colors.silver }}>
                    {icon}
                </div>
            </div>
            <div className='col-md-9' style={{ textAlign: 'left' }}>
                <h3>{name}</h3>
                <p>
                    {desc}
                    <br /><a href={documentationLink} target='__blank' rel="noopener noreferrer">Read more</a>
                </p>

                {
                    serviceRows
                        .map((row, i) => <div className='row' key={i}>
                            <div className='col-md-4'>
                                <DomainServiceItem service={row[0]} />
                            </div>
                            <div className='col-md-4'>
                                <DomainServiceItem service={row[1]} />
                            </div>
                            <div className='col-md-4'>
                                <DomainServiceItem service={row[2]} />
                            </div>
                        </div>
                        )
                }
            </div>
        </div>
        <div className='row' >
            <div className='col-md-11'>
                <hr />

            </div>
        </div>
    </div>;
};
