import { Button, Modal, shapes, Tooltip } from "@cimpress/react-components";
import { DataPortalAPI, Clients } from "@cimpress-technology/data-portal-core";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { renderError, renderLoading } from "../../../../../shared/Render";
import { UserLookup } from "./UserLookup";
import { UserRoleSelection } from "./UserRoleSelection";
import { useAppDispatch } from "../../../../../../store/storeHooks";
import { addDataProductMember } from "@cimpress-technology/data-portal-core/lib/features/members/common";
import { AppDispatch } from "@cimpress-technology/data-portal-core/lib/store";
import useUserCoamInfoCache from "../../../../../../hooks/useUserCoamInfoCache";
import {
    isLoadingUserInformation,
    isValidUserInformation,
    UserInformation,
} from "@cimpress-technology/data-portal-core/lib/features/coamUserInfoCache/coamUserInfoCacheSlice";
import { DataProductCoamUser } from "./UserTableRow";
import { DataProductMemberRole } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { CanonicalPrincipalSearchResult } from "@cimpress-technology/data-portal-core/lib/interfaces/coam";
import { getDefaultAvatar } from "../../../../../../common";

const isDataProductMemberRole = (
    input: string
): input is DataPortalAPI.DataProductMemberRole => {
    return Object.values(DataPortalAPI.DataProductMemberRole).includes(
        input as DataPortalAPI.DataProductMemberRole
    );
};

interface BaseSelected {
    user: string | null;
    role: DataPortalAPI.DataProductMemberRole;
    title: string | null;
    isProductTeamAdd: boolean;
}
interface ReadSelected extends BaseSelected {
    user: string | null;
    role: DataPortalAPI.DataProductMemberRole.Readers;
    title: null;
    isProductTeamAdd: false;
}

interface AdminSelected extends BaseSelected {
    user: string | null;
    role: DataPortalAPI.DataProductMemberRole.Admins;
    title: string | null;
    isProductTeamAdd: boolean;
}

const useSelectedUserAndRole = () => {
    const history = useHistory();
    const { search, pathname } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    const [userData, setUserData] = useState<AdminSelected | ReadSelected>({
        user: null,
        role: DataPortalAPI.DataProductMemberRole.Readers,
        title: null,
        isProductTeamAdd: false,
    });

    const setUser = (user: string | null) =>
        setUserData((prevState) => ({ ...prevState, user }));

    useEffect(() => {
        const grantUser = queryParams.get("grantUser")?.toLowerCase();
        const grantUserRole = queryParams.get("grantUserRole")?.toLowerCase();
        const grantUserTitle = queryParams.get("grantUserTitle");

        if (!grantUser || !grantUserRole) {
            return;
        }

        if (!isDataProductMemberRole(grantUserRole)) {
            console.warn(`${grantUserRole} is not a valid role`);
            queryParams.delete("grantUserRole");
            queryParams.delete("grantUserTitle");
            history.push({ pathname, search: `?${queryParams.toString()}` });

            return;
        }

        if (
            grantUserTitle &&
            grantUserRole !== DataPortalAPI.DataProductMemberRole.Admins
        ) {
            console.warn(`${grantUserTitle} is only applicable to Admins.`);
            queryParams.delete("grantUserTitle");
            history.push({ pathname, search: `?${queryParams.toString()}` });
            return;
        }

        switch (grantUserRole) {
            default:
            case DataPortalAPI.DataProductMemberRole.Readers:
                setUserData({
                    user: grantUser,
                    role: DataPortalAPI.DataProductMemberRole.Readers,
                    title: null,
                    isProductTeamAdd: false,
                });
                break;
            case DataPortalAPI.DataProductMemberRole.Admins:
                setUserData({
                    user: grantUser,
                    role: DataPortalAPI.DataProductMemberRole.Admins,
                    title: grantUserTitle,
                    isProductTeamAdd: grantUserTitle ? true : false,
                });
                break;
        }
    }, [queryParams, history, pathname]);

    const setSelectedUser = (user: string | null) => {
        if (user) {
            queryParams.set("grantUser", user);
        } else {
            queryParams.delete("grantUser");
            queryParams.delete("grantUserRole");
            queryParams.delete("grantUserTitle");
        }
        history.push({ pathname, search: `?${queryParams.toString()}` });
        setUser(user);
    };

    const setSelectedRole = (
        role: DataPortalAPI.DataProductMemberRole | null
    ) => {
        const setReaderState = () =>
            setUserData((prevState) => ({
                ...prevState,
                role: DataPortalAPI.DataProductMemberRole.Readers,
                title: null,
                isProductTeamAdd: false,
            }));
        switch (role) {
            case null:
                queryParams.set(
                    "grantUserRole",
                    DataPortalAPI.DataProductMemberRole.Readers
                );
                setReaderState();
                break;
            default:
            case DataPortalAPI.DataProductMemberRole.Readers:
                queryParams.set("grantUserRole", role);
                setReaderState();
                break;
            case DataPortalAPI.DataProductMemberRole.Admins:
                queryParams.set("grantUserRole", role);
                setUserData((prevState) => ({
                    ...prevState,
                    role: DataPortalAPI.DataProductMemberRole.Admins,
                }));
                break;
        }
        history.push({ pathname, search: `?${queryParams.toString()}` });
    };

    const setSelectedTitle = (title: string | null) => {
        title
            ? queryParams.set("grantUserTitle", title)
            : queryParams.delete("grantUserTitle");
        setUserData((prevState) => {
            switch (prevState.role) {
                default:
                case DataPortalAPI.DataProductMemberRole.Readers:
                    return {
                        ...prevState,
                        title: null,
                        isProductTeamAdd: false,
                    };
                case DataPortalAPI.DataProductMemberRole.Admins:
                    return {
                        ...prevState,
                        title,
                        isProductTeamAdd: title ? true : false,
                    };
            }
        });
        history.push({ pathname, search: `?${queryParams.toString()}` });
    };

    const setIsProductTeamAdd = (isProductTeamAdd: boolean) => {
        if (!isProductTeamAdd) {
            setSelectedTitle(null);
        }
        setUserData((prevState) => {
            switch (prevState.role) {
                default:
                case DataPortalAPI.DataProductMemberRole.Readers:
                    return {
                        ...prevState,
                        isProductTeamAdd: false,
                    };
                case DataPortalAPI.DataProductMemberRole.Admins:
                    return {
                        ...prevState,
                        isProductTeamAdd,
                    };
            }
        });
    };

    return {
        userData,
        setSelectedUser,
        setSelectedRole,
        setSelectedTitle,
        setIsProductTeamAdd,
    };
};

/**
 * Creates and returns a temporary mock of the data that otherwise would be returned by the COAM API.
 * Thus, we avoid making yet another request to the COAM API.
 * @param user
 * @returns
 */
const createCoamInfoTemporaryMock = (user: {
    canonicalId: string;
    accountId: string;
    coamInfo: CanonicalPrincipalSearchResult | null;
}) => {
    return {
        principal: user.canonicalId,
        is_admin: false,
        is_client: false,
        is_pending: false,
        account_id: user.accountId,
        roles: [],
        profile: (user.coamInfo && user.coamInfo.profiles.length > 0)
            ? {
                  given_name: user.coamInfo.profiles[0].given_name,
                  family_name: user.coamInfo.profiles[0].family_name,
                  email: user.coamInfo.profiles[0].email,
                  picture: user.coamInfo.profiles[0].picture,
                  name: "",
                  email_verified: true,
                  nickname: "",
                  user_id: "",
                  last_login: "",
              }
            : {
                given_name: user.canonicalId,
                  family_name: "",
                  email: "",
                  picture: getDefaultAvatar(user.canonicalId),
                  name: "",
                  email_verified: true,
                  nickname: "",
                  user_id: "",
                  last_login: "",
            },
    };
};

export const NewUserModalButton: React.FC<{
    accessToken: string;
    dataProduct: DataPortalAPI.DataProduct;
    accountId: string;
    disabled: boolean;
    onUserAdded(canoncalPrincipal: string, value: DataProductCoamUser): void;
    existingMembers: Set<string>;
    dataProductId: string;
}> = ({
    accessToken,
    accountId,
    disabled,
    dataProduct,
    existingMembers,
    onUserAdded,
    dataProductId,
}) => {
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);
    const {
        userData,
        setSelectedUser,
        setSelectedRole,
        setSelectedTitle,
        setIsProductTeamAdd,
    } = useSelectedUserAndRole();
    const { usersCoamInfo } = useUserCoamInfoCache({
        accessToken,
        needDataForTheseCanonicalIds: userData.user ? [userData.user] : [],
    });
    const userCOAMInfo = userData.user ? usersCoamInfo[userData.user] : null;
    const [selectedUserCoamInfo, setSelectedUserCoamInfo] =
        useState<CanonicalPrincipalSearchResult | null>(null);
    const [addingError, setAddingError] = useState<Error | null>(null);
    const [adding, setAdding] = useState(false);

    // Open the modal if coming from link.
    useEffect(() => {
        if (userData.user && !showModal) {
            setShowModal(true);
        }
    }, [userData.user, showModal]);

    const operationalError =
        userCOAMInfo && isValidUserInformation(userCOAMInfo)
            ? getUserOperationalError(
                  userCOAMInfo.canonicalPrincipalId,
                  accountId,
                  existingMembers,
                  userCOAMInfo.data
              )
            : null;

    const closeModal = () => {
        setSelectedUser(null);
        setSelectedUserCoamInfo(null);
        setAddingError(null);
        setSelectedRole(DataPortalAPI.DataProductMemberRole.Readers);
        setSelectedTitle(null);
        setIsProductTeamAdd(false);
        setShowModal(false);
    };

    const confirmAddingUser = () => {
        if (!userData.user || !userData.role || !dataProductId) {
            setAddingError(new Error("Invalid user data."));
            setAdding(false);
            return;
        }
        if (userData.isProductTeamAdd && userData.title === null) {
            setAddingError(new Error("Title is required."));
            setAdding(false);
            return;
        }
        addUserToDataProduct(
            accessToken,
            {
                canonicalId: userData.user,
                role: userData.role,
                title: userData.title,
                isProductTeamAdd: userData.isProductTeamAdd,
            },
            dataProductId,
            dispatch,
            setAdding,
            setAddingError,
            (user: ValidUserData) => {
                setSelectedUser(null);
                setSelectedTitle(null);
                onUserAdded(user.canonicalId, {
                    member: createCoamInfoTemporaryMock({
                        canonicalId: user.canonicalId,
                        accountId,
                        coamInfo: selectedUserCoamInfo,
                    }),
                    isAdmin:
                        user.role === DataProductMemberRole.Admins
                            ? true
                            : false,
                    labels: user.title ? [user.title] : [],
                    isProductTeamMember: user.isProductTeamAdd,
                });
                setSelectedUserCoamInfo(null);
                setShowModal(false);
            }
        );
    };

    return (
        <>
            <Tooltip
                show={disabled ? undefined : false} // Undefined means, show on hover, if not disabled then never show it (false)
                contents="You are not an admin of the Data Product."
            >
                <Button
                    variant="primary"
                    onClick={() => setShowModal(true)}
                    disabled={disabled}
                >
                    + Add new user
                </Button>
            </Tooltip>
            <Modal
                show={showModal}
                size="lg"
                title="Add new user"
                className="cimpress-modal-fix"
                footer={
                    <div>
                        <Button disabled={adding} onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={
                                disabled ||
                                adding ||
                                !userData.user ||
                                !!operationalError
                            }
                            onClick={confirmAddingUser}
                        >
                            {adding ? (
                                <span style={{ display: "flex" }}>
                                    <shapes.Spinner size="small" />
                                    &nbsp; Adding...
                                </span>
                            ) : (
                                "+ Add User"
                            )}
                        </Button>
                    </div>
                }
            >
                <div style={{ minHeight: 370 }}>
                    {!userData.user && (
                        <UserLookup
                            accessToken={accessToken}
                            existingMembers={existingMembers}
                            onUserSelected={(
                                user: CanonicalPrincipalSearchResult
                            ) => {
                                setSelectedUser(user.canonical_principal);
                                setSelectedUserCoamInfo(user);
                                setSelectedRole(userData.role);
                            }}
                            inAccountId={accountId}
                        />
                    )}
                    {userCOAMInfo &&
                        isLoadingUserInformation(userCOAMInfo) &&
                        renderLoading(`Loading user ${userData.user}...`)}
                    {operationalError}
                    {userCOAMInfo && isValidUserInformation(userCOAMInfo) && (
                        <UserRoleSelection
                            dataProduct={dataProduct}
                            user={{
                                coamUser: userCOAMInfo.data,
                                selectedRole:
                                    userData.role ||
                                    DataPortalAPI.DataProductMemberRole.Readers,
                                selectedTitle: userData.title,
                                isProductTeamAdd: userData.isProductTeamAdd,
                            }}
                            onRoleChange={setSelectedRole}
                            onTitleChange={setSelectedTitle}
                            onProductTeamAddChange={setIsProductTeamAdd}
                            onCancel={() => {
                                setSelectedUser(null);
                                setSelectedUserCoamInfo(null);
                                setIsProductTeamAdd(false);
                            }}
                        />
                    )}

                    {addingError
                        ? renderError(`Error adding user.`, addingError)
                        : null}
                </div>
                <div className="text-center">
                    <p>
                        <i>
                            After the user is added, it can take up to one
                            minute for the changes to be reflected in the list
                            of members.
                        </i>
                    </p>
                </div>
            </Modal>
        </>
    );
};

const getUserOperationalError = (
    canonicalPrincipal: string,
    accountId: string,
    existingMembers: Set<string>,
    coamUser: UserInformation
) => {
    if (!coamUser) {
        return renderError(`The user ${canonicalPrincipal} doesn't exist`);
    }

    if (accountId !== coamUser.account_id) {
        return renderError(
            `The user ${canonicalPrincipal} does not belong to the same data product business.`
        );
    }

    if (existingMembers.has(canonicalPrincipal.toLowerCase())) {
        return renderError(
            `The user ${canonicalPrincipal} is already part of the Data Product.`
        );
    }
};

type ValidUserData = {
    canonicalId: string;
    role: DataPortalAPI.DataProductMemberRole;
    isProductTeamAdd: boolean;
    title: string | null;
};

const addUserToDataProduct = async (
    accessToken: string,
    validUserData: ValidUserData,
    dataProductId: string,
    dispatch: AppDispatch,
    setAdding,
    setAddingError,
    onUserAdded: (user: ValidUserData) => void
) => {
    setAdding(true);
    setAddingError(undefined);
    try {
        if (validUserData.isProductTeamAdd && validUserData.title) {
            await dispatch(
                addDataProductMember({
                    accessToken,
                    dataProductId,
                    payload: {
                        canonicalId: validUserData.canonicalId,
                        labels: [validUserData.title],
                        order: 0,
                    },
                })
            );
        } else {
            await Clients.DataPortalAPI.addMembers(
                accessToken,
                dataProductId,
                [validUserData.canonicalId],
                validUserData.role
            );
        }
        onUserAdded(validUserData);
    } catch (error) {
        setAddingError(error as Error);
    }
    setAdding(false);
};
