import React from "react";
import ReactMarkdown from "react-markdown";

type Props = { description: string };

const MetadataDescription = (props: Props) => {
    return (
        <pre className="highlight-block">
            <code>
                <ReactMarkdown>{props.description || ""}</ReactMarkdown>
            </code>
        </pre>
    );
};

export default MetadataDescription;
